import React from "react";
import ApexChart from "../Bidding/ApexChart";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SearchIcon from "@mui/icons-material/Search";
import CustomModal from "../../Components/common/modal/CustomModal";
import { useState } from "react";
import AddBidding from "./AddBidding";

function UserBiddingList() {
  const [modal, setModal] = useState(false);

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: 5,
      }}
    />
  );

  return (
    <div className="container mb-50">
      <div className="d-md-flex justify-content-between align-items-center my-4">
        <h3>My Bidding List</h3>

        <button
          className="btn text-right ml-auto"
          onClick={() => setModal(true)}
        >
          + New Bidding <i></i>
        </button>
      </div>

      <div className="row mb-50">
        <div class="col-lg-8 d-flex justify-content-center align-items-center">
          <div className="w-100 h-100 border rounded-3">
            <ApexChart />
          </div>
        </div>

        <div className="col-lg-4 pl-20">
          <div className="border rounded-3 px-3 py-5 mb-30">
            <div className="d-flex align-items-center justify-content-center gap-3  mb-25">
              <ListAltIcon
                sx={{
                  fontSize: 30,
                  color: "black",
                }}
              />

              <h4>Active Bidding</h4>
            </div>

            <h3 className="text-center text-brand">4</h3>
          </div>

          <div className="border rounded-3 px-3 py-5 ">
            <div className="d-flex align-items-center justify-content-center gap-3 mb-25">
              <MonetizationOnIcon
                sx={{
                  fontSize: 30,
                  color: "black",
                }}
              />

              <h4>Total Bidding</h4>
            </div>

            <h3 className="text-center text-primary">45</h3>
          </div>
        </div>
      </div>

      <ColoredLine color="#3bb77e" />

      <span className="d-flex justify-content-between align-items-center mt-45 mb-20">
        <h3>Bidding History</h3>

        <div class="input-group mb-3 w-25">
          <input
            type="text"
            class="form-control form-control-sm h-auto  rounded-1"
            placeholder="I'm Looking For.."
            aria-label="I'm Looking For.."
            aria-describedby="basic-addon2"
          />
          <span class="input-group-text" id="basic-addon2">
            <SearchIcon />
          </span>
        </div>

        {/* <input
            type="text"
            class="form-control form-control-sm h-auto w-25 rounded-1"
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
          /> */}
      </span>

      <table class="table">
        <thead class="table-light">
          <tr>
            <th scope="col">Expiry Date/Time</th>
            <th scope="col">No. of Bidder</th>
            <th scope="col">Status</th>
            <th scope="col">Bidding For</th>
            <th scope="col" style={{ width: "10%" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">10/1/2022 | 11.00 AM</th>
            <td>4</td>
            <td>
              <span className="badge rounded-pill bg-brand">Active</span>
            </td>
            <td>Driver</td>
            <td className="d-flex gap-1">
              <button className="btn btn-primary btn-sm ms">Edit</button>
              <button className="btn btn-secondary btn-sm ms-2">View</button>
            </td>
          </tr>
          <tr>
            <th scope="row">10/1/2022 | 11.00 AM</th>
            <td>13</td>
            <td>
              <span className="badge rounded-pill bg-brand">Active</span>
            </td>
            <td>Mechanic</td>
            <td className="d-flex gap-1">
              <button className="btn btn-primary btn-sm ms">Edit</button>
              <button className="btn btn-secondary btn-sm ms-2">View</button>
            </td>
          </tr>
          <tr>
            <th scope="row">10/1/2022 | 11.00 AM</th>
            <td>20</td>
            <td>
              <span className="badge rounded-pill bg-danger">Closed</span>
            </td>
            <td>Rent a Car</td>
            <td className="d-flex gap-1">
              <button className="btn btn-primary btn-sm ms">Edit</button>
              <button className="btn btn-secondary btn-sm ms-2">View</button>
            </td>
          </tr>
        </tbody>
      </table>

      <div className="pagination-area mt-20 mb-20">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="fi-rs-arrow-small-left"></i>
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item active">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link dot" href="#">
                ...
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                6
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="fi-rs-arrow-small-right"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <CustomModal
        size="lg"
        footer={false}
        onHide={() => setModal(false)}
        show={modal}
      >
        <AddBidding />
      </CustomModal>

      {/* <ListTable /> */}
    </div>
  );
}
export default UserBiddingList;
