import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    firstName,
    lastName,
    username,
    email,
    contactNo,
    password,
    // nameOnCard,
    // cardNumber,
  }
} = checkoutFormModel;

const visaRegEx = /^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{11}\s*,?$/;
const specialCarter = /^[a-zA-Z]+$/u;
const letter = /(?=.*[a-z])/;
const letter2 = /(?=.*[A-Z])/;
const numberr = /(?=.*\d)/;
const sCarter = /(?=.*?[#?!@$%^&*-])/;
const sCarter1 = /^\S*$/;

// numberr
export default [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`).matches(specialCarter, firstName.letterErrorMsg),
    [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`).matches(specialCarter, lastName.letterErrorMsg),
    [username.name]: Yup.string().required(`${username.requiredErrorMsg}`).matches(specialCarter, username.letterErrorMsg),
    [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
    [contactNo.name]: Yup.string().required(`${contactNo.requiredErrorMsg}`).matches(visaRegEx, contactNo.massge),
    [password.name]: Yup.string().required(`${password.requiredErrorMsg}`).matches(letter, password.msg1).matches(letter2, password.msg2).matches(numberr, password.numberr).matches(sCarter, password.sCarter).matches(sCarter1, password.letterErrorMsg)
  }),
  // Yup.object().shape({
  //   [nameOnCard?.name]: Yup.string().required(`${nameOnCard?.requiredErrorMsg}`),
  //   [cardNumber?.name]: Yup.string().required(`${cardNumber?.requiredErrorMsg}`),
  // })
];
