import React from "react";
import { Form } from "react-bootstrap";

const InputWithLabel = ({
  type = "text",
  label = null,
  size = "",
  placeholder = "Your placeholder",
  classes = "mb-3",
  required,
  component = "input",
  defaultOption = "Default select example",
  disabled = false,
}) => {
  let content = null;

  component === "select"
    ? (content = (
        <Form.Select aria-label="Default select example" disabled={disabled}>
          <option>{defaultOption}</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </Form.Select>
      ))
    : (content = (
        <Form.Control
          size={size}
          type={type}
          placeholder={placeholder}
          className="rounded-1 h-auto"
          disabled={disabled}
        />
      ));
  return (
    <Form.Group class={classes} controlId="exampleForm.ControlInput1">
      {label && (
        <Form.Label>
          {label} {required ? <span className="text-danger">*</span> : ""}
        </Form.Label>
      )}

      {content}
    </Form.Group>
  );
};

export default InputWithLabel;
