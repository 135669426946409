import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from '@material-ui/core/Slider';
import { Select, Cascader, Input, Empty, Pagination, PaginationProps, Card } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Radio } from 'antd';
import axios from '../Helper/Config';
import { useSelector, useDispatch } from 'react-redux';
import { apiImgUrl } from '../Helper/BaseUrl';
import { setProducts } from '../reduxServices/actions/productsActions';
import { ADD } from '../reduxServices/actions/CartActions';
import Loader from '../Helper/Loader';
import category1 from '../assets/imgs/theme/icons/category-1.svg';
import category2 from '../assets/imgs/theme/icons/category-2.svg';
import category3 from '../assets/imgs/theme/icons/category-3.svg';
import category4 from '../assets/imgs/theme/icons/category-4.svg';
import category5 from '../assets/imgs/theme/icons/category-5.svg';
import thumbnail3 from '../assets/imgs/shop/thumbnail-3.jpg';
import thumbnail4 from '../assets/imgs/shop/thumbnail-4.jpg';
import thumbnail5 from '../assets/imgs/shop/thumbnail-5.jpg';
import banner from '..//assets/imgs/banner/banner-11.png';
import PCBreadcrambs from '../Components/ProductCategory/PCBreadcrambs';

function ProductCategories() {
    const products = useSelector((state) => state.allProducts.products);
    const dispatch = useDispatch();
    const { slug } = useParams();
    const { id } = useParams();
    const [isupdate, setisupdate] = React.useState(0);
    const [pages, setPages] = useState(1);
    const [totalPage, totalPages] = useState('');
    const [make, setMake] = useState('');
    const [model, setModel] = useState('');
    const [partstype, setPartsType] = useState('');
    const [category, setCategoryId] = useState(id);
    const [subcategory, setSubCategoryId] = useState('');
    const [location, setLocation] = useState('');
    const [search, setOnSearch] = useState('');
    const [min, setMinValue] = useState('');
    const [max, setMaxValue] = useState('');
    const [loading, setLoading] = useState(true);
    // Our States

    const [partsTypesList, setPartsTypes] = useState([]);
    const [categoryList, setCategory] = useState([]);
    const [subCategoryList, setSubCategory] = useState([]);
    const [loactionList, setLoactionList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [makeList, setMakeList] = useState([]);
    const { Option } = Select;
    const { Search } = Input;
    const [form] = Form.useForm();

    useEffect(() => {
        setLoading(true);
        // Parts Types
        partsType();
        // Location Area
        locationFunction();
        // Make
        makeFunction();
        // Model
        modelFunction();
        // category Function
        categoryFunction();
        //filter product
        productFunction();
    }, []);

    const partsType = () => {
        axios
            .get('/seller/parts/type')
            .then((res) => {
                setPartsTypes(res?.data?.data?.type);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const locationFunction = () => {
        axios
            .get('/common/division-district/list')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                    children: category?.districts.map((districts) => ({
                        value: districts?.id,
                        label: districts?.name,
                    })),
                }));
                setLoactionList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Make onClick Function Area
    const makeFunction = () => {
        axios
            .get('/common/vehicle/make/list')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                }));
                setMakeList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Model onClick Function Area
    const modelFunction = () => {
        axios
            .get('/common/vehicle/model/list')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                }));
                setModelList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const categoryFunction = (e) => {
        axios
            .get('/seller/parts/category')
            .then((res) => {
                setCategory(res?.data?.data?.category);
            })
            .catch((err) => {});
    };
    const pagesChange = (element) => {
        setPages(element);
        setisupdate(isupdate + 1);
    };
    const makeListOnchangFunction = (e) => {
        setMake(e);
        setisupdate(isupdate + 1);
    };
    const modelOnChangFunction = (e) => {
        setModel(e);
        setisupdate(isupdate + 1);
    };
    const partsTypeFunction = (e) => {
        setPartsType(e);
        setisupdate(isupdate + 1);
    };
    const categoryOnChangeFunction = (e) => {
        setCategoryId(e);
        axios
            .get(`/seller/parts/subcategory/${e}`)
            .then((res) => {
                setSubCategory(res?.data?.data?.subcategory);
            })
            .catch((err) => {});
        setisupdate(isupdate + 1);
    };
    const subCategoryFunction = (e) => {
        setSubCategoryId(e);
        setisupdate(isupdate + 1);
    };
    const locationOnChange = (value) => {
        const lastItem = value[value.length - 1];
        setLocation(lastItem);
        setisupdate(isupdate + 1);
    };
    const onSearch = (e) => {
        setOnSearch(e.target.value);
        setisupdate(isupdate + 1);
    };
    const rangeSelector = (event, newValue) => {
        const min_price = newValue[0];
        const max_price = newValue[1];
        setMinValue(min_price);
        setMaxValue(max_price);
        setisupdate(isupdate + 1);
    };
    useEffect(() => {
        productFunction();
    }, [isupdate]);
    //filter product
    const productFunction = () => {
        axios
            .get(
                `/seller/product/list?search=${search}&make_id=${make}&model_id=${model}&categories_id=${category}&subcategory_id=${subcategory}&parts_type=${partstype}&district_id=${location}&min_price=${min}&max_price=${max}&page=${pages}`
            )
            .then((res) => {
                totalPages(res?.data?.data?.product.total);
                setPages(res?.data?.data?.product.current_page);
                dispatch(setProducts(res?.data?.data?.product?.data));
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getDataCart = (e) => {
        dispatch(ADD(e));
    };
    console.log(loactionList, 'products products');
    return (
        <>
            <PCBreadcrambs />
            <br />
            <div className='container mb-30'>
                <div className='row flex-row-reverse'>
                    <div className='col-lg-4-5'>
                        {/*<div className="shop-product-fillter">*/}
                        {/*    <div className="totall-product">*/}
                        {/*        <p>We found <strong className="text-brand">29</strong> items for you!</p>*/}
                        {/*    </div>*/}
                        {/*    <div className="sort-by-product-area">*/}
                        {/*        <div className="sort-by-cover mr-10">*/}
                        {/*            <div className="sort-by-product-wrap">*/}
                        {/*                <div className="sort-by">*/}
                        {/*                    <span><i className="fi-rs-apps"></i>Show:</span>*/}
                        {/*                </div>*/}
                        {/*                <div className="sort-by-dropdown-wrap">*/}
                        {/*                    <span> 50 <i className="fi-rs-angle-small-down"></i></span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="sort-by-dropdown">*/}
                        {/*                <ul>*/}
                        {/*                    <li><Link className="active" to="#">50</Link></li>*/}
                        {/*                    <li><Link to="#">100</Link></li>*/}
                        {/*                    <li><Link to="#">150</Link></li>*/}
                        {/*                    <li><Link to="#">200</Link></li>*/}
                        {/*                    <li><Link to="#">All</Link></li>*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*        <div className="sort-by-cover">*/}
                        {/*            <div className="sort-by-product-wrap">*/}
                        {/*                <div className="sort-by">*/}
                        {/*                    <span><i className="fi-rs-apps-sort"></i>Sort by:</span>*/}
                        {/*                </div>*/}
                        {/*                <div className="sort-by-dropdown-wrap">*/}
                        {/*                    <span> Featured <i className="fi-rs-angle-small-down"></i></span>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="sort-by-dropdown">*/}
                        {/*                <ul>*/}
                        {/*                    <li><Link className="active" to="#">Featured</Link></li>*/}
                        {/*                    <li><Link to="#">Price: Low to High</Link></li>*/}
                        {/*                    <li><Link to="#">Price: High to Low</Link></li>*/}
                        {/*                    <li><Link to="#">Release Date</Link></li>*/}
                        {/*                    <li><Link to="#">Avg. Rating</Link></li>*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className='productCategories__area'>
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className='productCategories__details_header'>
                                            <Row>
                                                {/* <Col lg={6}>
                                    <p>   <Link to="/">Home</Link> / <Link to="/"> Product Categories </Link> / "Electrical & Electronics" </p>
                                    <h3 style={{ marginTop: "15px" }}> Bike Parts & Accessories  </h3>
                                </Col> */}
                                                <Col lg={12}>
                                                    {/* <h3 style={{width: '100%', textAlign: 'right'}}> Search by Products </h3> */}
                                                    <input
                                                        type='text'
                                                        style={{ height: '45px', marginBottom: 30 }}
                                                        placeholder={`Search for`}
                                                        onChange={(e) => onSearch(e)}
                                                    />
                                                    {/* <Search
                            placeholder="Search by Products"
                            allowClear
                            onChange={(e) => onSearch(e)}
                            style={{
                              width: "100%",
                            }}
                          /> */}
                                                </Col>
                                            </Row>
                                        </div>
                                        {products?.length > 0 ? (
                                            <>
                                                <Row>
                                                    {products?.map((item, i) => (
                                                        <div className='col-lg-3 col-md-4 col-12 col-sm-6'>
                                                            <div className='product-cart-wrap mb-30 wow animate__animated'>
                                                                <div className='product-img-action-wrap'>
                                                                    <div className='product-img product-img-zoom'>
                                                                        <Link to={`/product-details/${item?.slug}`}>
                                                                            <img
                                                                                className='default-img'
                                                                                src={apiImgUrl + item?.thumbnail}
                                                                                alt=''
                                                                            />
                                                                            <img
                                                                                className='hover-img'
                                                                                src={apiImgUrl + item?.thumbnail}
                                                                                alt=''
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                    <div className='product-action-1'>
                                                                        <Link aria-label='Add To Wishlist' className='action-btn' to=''>
                                                                            <i className='fi-rs-heart'></i>
                                                                        </Link>
                                                                        <Link aria-label='Compare' className='action-btn' to=''>
                                                                            <i className='fi-rs-shuffle'></i>
                                                                        </Link>
                                                                        <Link
                                                                            aria-label='Quick view'
                                                                            className='action-btn'
                                                                            data-bs-toggle='modal'
                                                                            data-bs-target='#quickViewModal'
                                                                        >
                                                                            <i className='fi-rs-eye'></i>
                                                                        </Link>
                                                                    </div>
                                                                    <div className='product-badges product-badges-position product-badges-mrg'>
                                                                        <span className='hot'>Hot</span>
                                                                    </div>
                                                                </div>
                                                                <div className='product-content-wrap'>
                                                                    <div className='product-category'>
                                                                        <Link to=''>Snack</Link>
                                                                    </div>
                                                                    <h2 className='text-ellipsis'>
                                                                        <Link to={`/product-details/${item?.slug}`}>{item?.name}</Link>
                                                                    </h2>
                                                                    <div className='product-rate-cover'>
                                                                        <div className='product-rate d-inline-block'>
                                                                            <div className='product-rating'></div>
                                                                        </div>
                                                                        <span className='font-small ml-5 text-muted'> (4.0)</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className='font-small text-muted'>
                                                                            By
                                                                            <Link to=''>NestFood</Link>
                                                                        </span>
                                                                    </div>
                                                                    <div className='product-card-bottom'>
                                                                        <div className='product-price'>
                                                                            <span>$28.85</span>
                                                                            <span className='old-price'>$32.8</span>
                                                                        </div>
                                                                        <div className='add-cart'>
                                                                            <Link className='add' to=''>
                                                                                <i className='fi-rs-shopping-cart mr-5'></i>
                                                                                Add{' '}
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Row>
                                                {/*<Row>*/}
                                                {/*    {products?.map((item, i) =>*/}
                                                {/*        <Col key={i} className='mb-3' lg={3}>*/}
                                                {/*            <div className='single_parts_area'>*/}
                                                {/*                <div className='image__body'>*/}
                                                {/*                    <Link to={`/product-details/${item?.slug}`}>*/}
                                                {/*                        <img className='image__body img-fit' src={apiImgUrl + item?.thumbnail} alt="" />*/}
                                                {/*                    </Link>*/}
                                                {/*                </div>*/}
                                                {/*                <div className='product__icon__'>*/}
                                                {/*                    <button> <RiEyeFill /></button>*/}
                                                {/*                </div>*/}
                                                {/*                <div className='product-card__info'>*/}
                                                {/*                    <div className='product-card_sku' style={{ paddingBottom: '6px' }}>*/}
                                                {/*                        <span>Make :</span>   {item?.make?.name}*/}
                                                {/*                        <p style={{ width: 'auto', float: 'right' }}> <span>Model :</span>   {item?.model?.name} </p>*/}
                                                {/*                    </div>*/}
                                                {/*                    <div className='product-card__name'>*/}
                                                {/*                        <Link to={`/product-details/${item?.slug}`}> {item?.name} </Link>*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}
                                                {/*                <div className='product-card__footer'>*/}
                                                {/*                    <h3>*/}
                                                {/*                        {item?.discount_price > 0 ? <>*/}
                                                {/*                            ৳{item?.discount_price}*/}
                                                {/*                            <del style={{ fontSize: '12px', color: '#222', marginLeft: '5px' }}> ৳{item?.selling_price} </del>*/}
                                                {/*                        </> : <>  ৳{item?.selling_price} </>}*/}
                                                {/*                    </h3>*/}
                                                {/*                    {item?.qnty > 0 ? <> <RiCheckFill className='svg__hover' /> </> : <> <RiShoppingCartLine onClick={() => getDataCart(item)} className='svg__hover' />  </>}*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        </Col>*/}
                                                {/*    )}*/}
                                                {/*</Row>*/}
                                            </>
                                        ) : (
                                            <>
                                                <div className='dataEmtey'>
                                                    <Empty />
                                                </div>
                                            </>
                                        )}
                                        {products?.length > 0 ? (
                                            <>
                                                <Pagination
                                                    onChange={(e) => pagesChange(e)}
                                                    defaultCurrent={1}
                                                    defaultPageSize={12}
                                                    total={totalPage}
                                                />
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Col>
                                </Row>
                            </Container>
                            <Loader loaderShow={loading} loaderHide={loading} />
                        </div>
                    </div>

                    <div className='col-lg-1-5 primary-sidebar sticky-sidebar'>
                        <div className='sidebar-widget widget-category-2 mb-30'>
                            <h5 className='section-title style-1 mb-30'>Category</h5>
                            <ul>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category1} alt='' />
                                        Milks & Dairies
                                    </Link>
                                    <span className='count'>30</span>
                                </li>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category2} alt='' />
                                        Clothing
                                    </Link>
                                    <span className='count'>35</span>
                                </li>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category3} alt='' />
                                        Pet Foods{' '}
                                    </Link>
                                    <span className='count'>42</span>
                                </li>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category4} alt='' />
                                        Baking material
                                    </Link>
                                    <span className='count'>68</span>
                                </li>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category5} alt='' />
                                        Fresh Fruit
                                    </Link>
                                    <span className='count'>87</span>
                                </li>
                            </ul>
                        </div>

                        <div className='sidebar-widget price_range range mb-30'>
                            <h5 className='section-title style-1 mb-30'>Filter Product</h5>
                            <Form form={form} layout='vertical'>
                                <Form.Item label='Location' tooltip='Select You Location'>
                                    <Cascader
                                        className='w-100'
                                        placeholder='Location'
                                        onChange={(e) => locationOnChange(e)}
                                        options={loactionList}
                                        changeOnSelect
                                    />
                                </Form.Item>
                                <Form.Item label='Price Range' tooltip='Select You Price Range'>
                                    <div className='price-filter'>
                                        <div className='price-filter-inner'>
                                            <Slider
                                                range={{ draggableTrack: true }}
                                                min={0}
                                                max={50000}
                                                step={100}
                                                onChange={rangeSelector}
                                                defaultValue={[0, 50000]}
                                            />
                                            <div className='d-flex justify-content-between'>
                                                <div className='caption'>
                                                    From: <strong className='text-brand'>{min ? min : 0}</strong>
                                                </div>
                                                <div className='caption'>
                                                    To: <strong className='text-brand'>{max ? max : 50000}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form.Item>
                                <Form.Item label='Category' tooltip='Select You Category'>
                                    <Select
                                        className='w-100'
                                        onClick={(e) => categoryFunction(e)}
                                        onChange={(e) => categoryOnChangeFunction(e)}
                                        allowClear
                                        placeholder='Select your Category'
                                    >
                                        {categoryList?.map((item, i) => (
                                            <Option key={i} value={`${item?.id}`}>
                                                {item?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Sub Category' tooltip='Select You Sub Category'>
                                    <Select
                                        mode='multiple'
                                        className='w-100'
                                        onChange={(e) => subCategoryFunction(e)}
                                        placeholder='Select your Category'
                                    >
                                        {subCategoryList?.map((item, i) => (
                                            <Option key={i} value={`${item?.id}`}>
                                                {item?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Parts Type' tooltip='Select Parts Type'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => partsTypeFunction(e)}
                                        allowClear
                                        placeholder='Select Parts type'
                                    >
                                        {partsTypesList?.map((item, i) => (
                                            <Option key={i} value={`${item?.id}`}>
                                                {item?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label='Make' tooltip='Select Parts Make'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => makeListOnchangFunction(e)}
                                        placeholder='Make'
                                        allowClear
                                        options={makeList}
                                    />
                                </Form.Item>
                                <Form.Item label='Model' tooltip='Select Parts Model'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => modelOnChangFunction(e)}
                                        placeholder='Model'
                                        allowClear
                                        options={modelList}
                                    />
                                </Form.Item>
                            </Form>

                            <a href='shop-grid-right.html' className='btn btn-sm btn-default btn-block'>
                                <i className='fi-rs-filter mr-5'></i> Fillter
                            </a>
                        </div>

                        {/*<div className="sidebar-widget product-sidebar mb-30 p-30 bg-grey border-radius-10">*/}
                        {/*  <h5 className="section-title style-1 mb-30">New products</h5>*/}
                        {/*  <div className="single-post clearfix">*/}
                        {/*    <div className="image">*/}
                        {/*      <img src={thumbnail3} alt="#" />*/}
                        {/*    </div>*/}
                        {/*    <div className="content pt-10">*/}
                        {/*      <h5>*/}
                        {/*        <a href="shop-product-detail.html">Chen Cardigan</a>*/}
                        {/*      </h5>*/}
                        {/*      <p className="price mb-0 mt-5">$99.50</p>*/}
                        {/*      <div className="product-rate">*/}
                        {/*        <div className="product-rating"></div>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*  <div className="single-post clearfix">*/}
                        {/*    <div className="image">*/}
                        {/*      <img src={thumbnail4} alt="#" />*/}
                        {/*    </div>*/}
                        {/*    <div className="content pt-10">*/}
                        {/*      <h6>*/}
                        {/*        <a href="shop-product-detail.html">Chen Sweater</a>*/}
                        {/*      </h6>*/}
                        {/*      <p className="price mb-0 mt-5">$89.50</p>*/}
                        {/*      <div className="product-rate">*/}
                        {/*        <div className="product-rating"></div>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*  <div className="single-post clearfix">*/}
                        {/*    <div className="image">*/}
                        {/*      <img src={thumbnail5} alt="#" />*/}
                        {/*    </div>*/}
                        {/*    <div className="content pt-10">*/}
                        {/*      <h6>*/}
                        {/*        <a href="shop-product-detail.html">Colorful Jacket</a>*/}
                        {/*      </h6>*/}
                        {/*      <p className="price mb-0 mt-5">$25</p>*/}
                        {/*      <div className="product-rate">*/}
                        {/*        <div className="product-rating"></div>*/}
                        {/*      </div>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {/*<div className="banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none">*/}
                        {/*  <img src={banner} alt="" />*/}
                        {/*  <div className="banner-text">*/}
                        {/*    <span>Oganic</span>*/}
                        {/*    <h4>*/}
                        {/*      Save 17% <br />*/}
                        {/*      on <span className="text-brand">Oganic</span>*/}
                        {/*      <br />*/}
                        {/*      Juice*/}
                        {/*    </h4>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>

            {/*<div className='productCategories__area'>*/}
            {/*    <Container>*/}
            {/*        <Row>*/}
            {/*            <Col lg={3}>*/}
            {/*                <Card title="Features" className="mb-3" size="small" bordered={false}>*/}
            {/*                    <div className='categori_details_list'>*/}
            {/*                        <Link style={{color: '#222'}} to="/services-product">*/}
            {/*                            <p> Service<VscChevronRight/></p>*/}
            {/*                        </Link>*/}
            {/*                        <p> Driver <VscChevronRight/></p>*/}
            {/*                    </div>*/}
            {/*                </Card>*/}
            {/*                <Card title="Location" className="mb-3" size="small" bordered={false}>*/}
            {/*                    <Cascader*/}
            {/*                        className='select__width'*/}
            {/*                        placeholder='Select You Location'*/}
            {/*                        onChange={(e) => locationOnChange(e)}*/}
            {/*                        options={loactionList}*/}
            {/*                        changeOnSelect/>*/}
            {/*                </Card>*/}
            {/*                <Card title="Price Range" className="mb-3" size="small" bordered={false}>*/}
            {/*                    <div>*/}
            {/*                        <Slider range={{draggableTrack: true}} min={0} max={50000} step={100}*/}
            {/*                                onChange={rangeSelector} defaultValue={[0, 50000]}/>*/}
            {/*                        <div className='min_max_p_area'>*/}
            {/*                            <div className='min__p'> {min ? min : 0} ৳</div>*/}
            {/*                            <div className='max__p'> {max ? max : 50000} ৳</div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </Card>*/}
            {/*                <Card title="Category" className="mb-3" size="small" bordered={false}>*/}
            {/*                    <Select className='select__width' onClick={(e) => categoryFunction(e)}*/}
            {/*                            onChange={(e) => categoryOnChangeFunction(e)} allowClear*/}
            {/*                            placeholder="Select your Category">*/}
            {/*                        {categoryList?.map((item, i) =>*/}
            {/*                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>*/}
            {/*                        )}*/}
            {/*                    </Select>*/}
            {/*                </Card>*/}
            {/*                <Card title="Sub Category" className="mb-3" size="small" bordered={false}>*/}
            {/*                    <Select mode="multiple" className='select__width'*/}
            {/*                            onChange={(e) => subCategoryFunction(e)} placeholder="Select your Category">*/}
            {/*                        {subCategoryList?.map((item, i) =>*/}
            {/*                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>*/}
            {/*                        )}*/}
            {/*                    </Select>*/}
            {/*                </Card>*/}
            {/*                <Card title="Parts Type" className="mb-3" size="small" bordered={false}>*/}
            {/*                    <Select className='select__width' onChange={(e) => partsTypeFunction(e)} allowClear*/}
            {/*                            placeholder="Select Parts type">*/}
            {/*                        {partsTypesList?.map((item, i) =>*/}
            {/*                            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>*/}
            {/*                        )}*/}
            {/*                    </Select>*/}
            {/*                </Card>*/}
            {/*                <Card title="Make" className="mb-3" size="small" bordered={false}>*/}
            {/*                    <Select dropdownStyle={{maxHeight: 150, overflowY: 'visible'}} className='make'*/}
            {/*                            onChange={(e) => makeListOnchangFunction(e)} placeholder='Make' allowClear*/}
            {/*                            options={makeList}/>*/}
            {/*                </Card>*/}
            {/*                <Card title="Model" className="mb-3" size="small" bordered={false}>*/}
            {/*                    <Select dropdownStyle={{maxHeight: 150, overflowY: 'visible'}} className='model'*/}
            {/*                            onChange={(e) => modelOnChangFunction(e)} placeholder='Model' allowClear*/}
            {/*                            options={modelList}/>*/}
            {/*                </Card>*/}
            {/*            </Col>*/}

            {/*            <Col lg={9}>*/}

            {/*                <div className='productCategories__details_header'>*/}
            {/*                    <Row>*/}
            {/*                        /!* <Col lg={6}>*/}
            {/*                        <p>   <Link to="/">Home</Link> / <Link to="/"> Product Categories </Link> / "Electrical & Electronics" </p>*/}
            {/*                        <h3 style={{ marginTop: "15px" }}> Bike Parts & Accessories  </h3>*/}
            {/*                    </Col> *!/*/}
            {/*                        <Col lg={12}>*/}
            {/*                            /!* <h3 style={{width: '100%', textAlign: 'right'}}> Search by Products </h3> *!/*/}
            {/*                            <Search*/}
            {/*                                placeholder="Search by Products"*/}
            {/*                                allowClear*/}
            {/*                                onChange={(e) => onSearch(e)}*/}
            {/*                                style={{*/}
            {/*                                    width: '100%',*/}
            {/*                                }}*/}
            {/*                            />*/}
            {/*                        </Col>*/}
            {/*                    </Row>*/}
            {/*                </div>*/}
            {/*                {products?.length > 0 ? <>*/}
            {/*                    <Row>*/}
            {/*                        {products?.map((item, i) =>*/}
            {/*                            <div className="col-lg-3 col-md-4 col-12 col-sm-6">*/}
            {/*                                <div className="product-cart-wrap mb-30 wow animate__animated">*/}
            {/*                                    <div className="product-img-action-wrap">*/}
            {/*                                        <div className="product-img product-img-zoom">*/}
            {/*                                            <Link to={`/product-details/${item?.slug}`}>*/}
            {/*                                                <img className="default-img"*/}
            {/*                                                     src={apiImgUrl + item?.thumbnail}*/}
            {/*                                                     alt=""/>*/}
            {/*                                                <img className="hover-img" src={apiImgUrl + item?.thumbnail}*/}
            {/*                                                     alt=""/>*/}
            {/*                                            </Link>*/}
            {/*                                        </div>*/}
            {/*                                        <div className="product-action-1">*/}
            {/*                                            <Link aria-label="Add To Wishlist" className="action-btn"*/}
            {/*                                                  to=""><i className="fi-rs-heart"></i></Link>*/}
            {/*                                            <Link aria-label="Compare" className="action-btn" to=""><i*/}
            {/*                                                className="fi-rs-shuffle"></i></Link>*/}
            {/*                                            <Link aria-label="Quick view" className="action-btn"*/}
            {/*                                                  data-bs-toggle="modal"*/}
            {/*                                                  data-bs-target="#quickViewModal"><i*/}
            {/*                                                className="fi-rs-eye"></i></Link>*/}
            {/*                                        </div>*/}
            {/*                                        <div*/}
            {/*                                            className="product-badges product-badges-position product-badges-mrg">*/}
            {/*                                            <span className="hot">Hot</span>*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                    <div className="product-content-wrap">*/}
            {/*                                        <div className="product-category">*/}
            {/*                                            <Link to="">Snack</Link>*/}
            {/*                                        </div>*/}
            {/*                                        <h2><Link to={`/product-details/${item?.slug}`}>{item?.name}</Link>*/}
            {/*                                        </h2>*/}
            {/*                                        <div className="product-rate-cover">*/}
            {/*                                            <div className="product-rate d-inline-block">*/}
            {/*                                                <div className="product-rating"></div>*/}
            {/*                                            </div>*/}
            {/*                                            <span className="font-small ml-5 text-muted"> (4.0)</span>*/}
            {/*                                        </div>*/}
            {/*                                        <div>*/}
            {/*                                    <span className="font-small text-muted">By*/}
            {/*                                        <Link to="">NestFood</Link></span>*/}
            {/*                                        </div>*/}
            {/*                                        <div className="product-card-bottom">*/}
            {/*                                            <div className="product-price">*/}
            {/*                                                <span>$28.85</span>*/}
            {/*                                                <span className="old-price">$32.8</span>*/}
            {/*                                            </div>*/}
            {/*                                            <div className="add-cart">*/}
            {/*                                                <Link className="add" to=""><i*/}
            {/*                                                    className="fi-rs-shopping-cart mr-5"></i>Add </Link>*/}
            {/*                                            </div>*/}
            {/*                                        </div>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        )}*/}
            {/*                    </Row>*/}
            {/*                    /!*<Row>*!/*/}
            {/*                    /!*    {products?.map((item, i) =>*!/*/}
            {/*                    /!*        <Col key={i} className='mb-3' lg={3}>*!/*/}
            {/*                    /!*            <div className='single_parts_area'>*!/*/}
            {/*                    /!*                <div className='image__body'>*!/*/}
            {/*                    /!*                    <Link to={`/product-details/${item?.slug}`}>*!/*/}
            {/*                    /!*                        <img className='image__body img-fit' src={apiImgUrl + item?.thumbnail} alt="" />*!/*/}
            {/*                    /!*                    </Link>*!/*/}
            {/*                    /!*                </div>*!/*/}
            {/*                    /!*                <div className='product__icon__'>*!/*/}
            {/*                    /!*                    <button> <RiEyeFill /></button>*!/*/}
            {/*                    /!*                </div>*!/*/}
            {/*                    /!*                <div className='product-card__info'>*!/*/}
            {/*                    /!*                    <div className='product-card_sku' style={{ paddingBottom: '6px' }}>*!/*/}
            {/*                    /!*                        <span>Make :</span>   {item?.make?.name}*!/*/}
            {/*                    /!*                        <p style={{ width: 'auto', float: 'right' }}> <span>Model :</span>   {item?.model?.name} </p>*!/*/}
            {/*                    /!*                    </div>*!/*/}
            {/*                    /!*                    <div className='product-card__name'>*!/*/}
            {/*                    /!*                        <Link to={`/product-details/${item?.slug}`}> {item?.name} </Link>*!/*/}
            {/*                    /!*                    </div>*!/*/}
            {/*                    /!*                </div>*!/*/}
            {/*                    /!*                <div className='product-card__footer'>*!/*/}
            {/*                    /!*                    <h3>*!/*/}
            {/*                    /!*                        {item?.discount_price > 0 ? <>*!/*/}
            {/*                    /!*                            ৳{item?.discount_price}*!/*/}
            {/*                    /!*                            <del style={{ fontSize: '12px', color: '#222', marginLeft: '5px' }}> ৳{item?.selling_price} </del>*!/*/}
            {/*                    /!*                        </> : <>  ৳{item?.selling_price} </>}*!/*/}
            {/*                    /!*                    </h3>*!/*/}
            {/*                    /!*                    {item?.qnty > 0 ? <> <RiCheckFill className='svg__hover' /> </> : <> <RiShoppingCartLine onClick={() => getDataCart(item)} className='svg__hover' />  </>}*!/*/}
            {/*                    /!*                </div>*!/*/}
            {/*                    /!*            </div>*!/*/}
            {/*                    /!*        </Col>*!/*/}
            {/*                    /!*    )}*!/*/}
            {/*                    /!*</Row>*!/*/}
            {/*                </> : <>*/}
            {/*                    <div className='dataEmtey'><Empty/></div>*/}
            {/*                </>}*/}
            {/*                {products?.length > 0 ? <>*/}
            {/*                    <Pagination onChange={(e) => pagesChange(e)} defaultCurrent={1} defaultPageSize={12}*/}
            {/*                                total={totalPage}/>*/}
            {/*                </> : <></>}*/}
            {/*            </Col>*/}

            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*    <Loader*/}
            {/*        loaderShow={loading}*/}
            {/*        loaderHide={loading}*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    );
}

export default ProductCategories;
