import React from "react";

const ConfirmationStepContent = () => {
  return (
    <div className="mt-50">
      <div className="row">
        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            From which Date?
          </label>

          <h4>10/05/2023</h4>
        </div>
        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Time of Journey
          </label>

          <h4>10:00 AM</h4>
        </div>
        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            For How many Days?
          </label>

          <h4>5</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Vehicle Type
          </label>

          <h4>SUV</h4>
        </div>
        <div className="col-xl-6 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Trip Type
          </label>

          <h4>Out City</h4>
        </div>

        <div className="col-xl-6 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            For which location?
          </label>

          <h4>Dhaka - No Fix Route</h4>
        </div>
        <div className="col-xl-6 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Notes
          </label>

          <h4>Only male driver allowed</h4>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationStepContent;
