import React, {useEffect, useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import axios from "../../Helper/Config";
import {apiImgUrl} from "../../Helper/BaseUrl";
function Brands() {
  const [brand, setBrand] = useState([])
  useEffect(() => {
    axios.get("/common/vehicle/make/list/16")
        .then((res) => {
          setBrand(res.data.data)
        }).catch((err) => {
    })
  }, [])
  return (
    <div className='home_category_area'>
      <Container>
        <Row>
          <Col>
            <div className='setion____title'>
              <h3> Our  <span> Brand </span> </h3>
            </div>
            <div className='card card_slider brand-card '>
              <Row>
                {brand.length > 0 ? <>
                    {brand?.map((item, i) =>
                      <Col key={i} xs={6} sm={6} md={4} lg={1} className="brand border1">
                        <Link to="/">
                          <img className='img-fit' src={apiImgUrl + item?.image} alt={item?.name} />
                          <p>{item?.name}</p>
                        </Link>
                      </Col>
                    )}
                </> : <>Brand Not Found...</>}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Brands
