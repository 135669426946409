import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../../Components/common/CustomPagination";
import InputWithLabel from "../../../Components/common/input/InputWithLabel";
import BasicCard from "../../../Components/common/card/BasicCard";
import { Link } from "react-router-dom";

const MyFavorite = () => {
  const [modal, setModal] = useState(false);

  const imageData = [
    "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
    "https://images.unsplash.com/photo-1502877338535-766e1452684a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80",
    "https://images.unsplash.com/photo-1570129476815-ba368ac77013?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    "https://images.unsplash.com/photo-1429772011165-0c2e054367b8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    "https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
  ];

  return (
    <div
      class="tab-pane fade"
      id="favorite"
      role="tabpanel"
      aria-labelledby="favorite-tab"
    >
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h3 class="mb-0">My Favorite List</h3>
          <span class="d-flex gap-2">
            <span>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control form-control-sm h-auto  rounded-1"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <span class="input-group-text" id="basic-addon2">
                  <SearchIcon />
                </span>
              </div>
            </span>

            <span>
              <InputWithLabel defaultOption="Search By" component="select" />
            </span>
          </span>
        </div>

        <div class="card-body">
          <div className="row">
            <div className="col-xl-4 mb-25">
              <BasicCard image={imageData[0]} badge="driver">
                <Link
                  to="#"
                  className="d-flex justify-content-between flex-wrap"
                >
                  <h5 className="card-title text-secondary">Martin Luther</h5>
                  <div class="product-rate-cover">
                    <div class="product-rate d-inline-block">
                      <div class="product-rating"></div>
                    </div>
                  </div>
                </Link>

                <p>0174258778</p>

                <p className="card-text">
                  Some quick example text to build on the card title.
                </p>

                <div className="text-end mt-15">
                  <a href="#" className="btn btn-primary">
                    Details
                  </a>
                </div>
              </BasicCard>
            </div>

            <div className="col-xl-4 mb-25">
              <BasicCard image={imageData[1]} badge="mechanic">
                <Link
                  to="#"
                  className="d-flex justify-content-between flex-wrap"
                >
                  <h5 className="card-title text-secondary">Jashim Uddin</h5>
                  <div class="product-rate-cover">
                    <div class="product-rate d-inline-block">
                      <div class="product-rating"></div>
                    </div>
                  </div>
                </Link>

                <p>0174258778</p>

                <p className="card-text">
                  Some quick example text to build on the card title.
                </p>

                <div className="text-end mt-15">
                  <a href="#" className="btn btn-primary">
                    Details
                  </a>
                </div>
              </BasicCard>
            </div>

            <div className="col-xl-4 mb-25">
              <BasicCard image={imageData[2]} badge="rent">
                <Link
                  to="#"
                  className="d-flex justify-content-between flex-wrap"
                >
                  <h5 className="card-title text-secondary">Toyota Corolla</h5>
                  <div class="product-rate-cover">
                    <div class="product-rate d-inline-block">
                      <div class="product-rating"></div>
                    </div>
                  </div>
                </Link>
                <p>
                  <strong>Model:</strong> 2005
                </p>

                <strong>Shamoli Rent A Car</strong>
                <p>0174258778</p>

                <div className="text-end mt-15">
                  <a href="#" className="btn btn-primary">
                    Details
                  </a>
                </div>
              </BasicCard>
            </div>

            <div className="col-xl-4">
              <BasicCard image={imageData[3]} badge="garage">
                <Link
                  to="#"
                  className="d-flex justify-content-between flex-wrap"
                >
                  <h5 className="card-title text-secondary">Rahim Garage</h5>
                  <div class="product-rate-cover">
                    <div class="product-rate d-inline-block">
                      <div class="product-rating"></div>
                    </div>
                  </div>
                </Link>

                <p>0174258778</p>

                <p className="card-text">Some quick example text to build .</p>

                <div className="text-end mt-15">
                  <a href="#" className="btn btn-primary">
                    Details
                  </a>
                </div>
              </BasicCard>
            </div>

            <div className="col-xl-4">
              <BasicCard image={imageData[4]} badge="parts">
                <Link
                  to="#"
                  className="d-flex justify-content-between flex-wrap"
                >
                  <h5 className="card-title text-secondary">Spark Plug</h5>
                  <div class="product-rate-cover">
                    <div class="product-rate d-inline-block">
                      <div class="product-rating"></div>
                    </div>
                  </div>
                </Link>
                <p>
                  <strong>Model:</strong> 2005
                </p>

                <p>
                  <strong>Firoz Auto Parts, </strong>
                  0174258778
                </p>

                <div className="text-end mt-15">
                  <a href="#" className="btn btn-primary">
                    Details
                  </a>
                </div>
              </BasicCard>
            </div>

            <div className="col-xl-4">
              <BasicCard image={imageData[5]} badge="car">
                <Link
                  to="#"
                  className="d-flex justify-content-between flex-wrap"
                >
                  <h5 className="card-title text-secondary">Honda City</h5>
                  <div class="product-rate-cover">
                    <div class="product-rate d-inline-block">
                      <div class="product-rating"></div>
                    </div>
                  </div>
                </Link>
                <p>
                  <strong>Model:</strong> 2006
                </p>

                <p>
                  <strong>Mr. Taher Islam,</strong> 0174258778
                </p>

                <div className="text-end mt-15">
                  <a href="#" className="btn btn-primary">
                    Details
                  </a>
                </div>
              </BasicCard>
            </div>
          </div>

          <CustomPagination placement="center" />
        </div>
      </div>
    </div>
  );
};

export default MyFavorite;
