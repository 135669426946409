import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Select, DatePicker, Input, Cascader, Form, Card} from 'antd';
import {VscChevronRight} from 'react-icons/vsc';
import {Link} from 'react-router-dom';
import {VscStarFull, VscStarEmpty, VscStarHalf, VscCallOutgoing, VscLocation} from 'react-icons/vsc';
import axios from '../../Helper/Config';
import {apiImgUrl} from '../../Helper/BaseUrl';
import Loader from '../../Helper/Loader';
import Slider from '@material-ui/core/Slider';
import PCBreadcrambs from '../../Components/ProductCategory/PCBreadcrambs';
import product1 from '../../assets/imgs/shop/product-5-1.jpg';
import product2 from '../../assets/imgs/shop/product-5-2.jpg';
import category1 from '../../assets/imgs/theme/icons/category-1.svg';
import category2 from '../../assets/imgs/theme/icons/category-2.svg';
import category3 from '../../assets/imgs/theme/icons/category-3.svg';
import category4 from '../../assets/imgs/theme/icons/category-4.svg';
import category5 from '../../assets/imgs/theme/icons/category-5.svg';
import thumbnail3 from '../../assets/imgs/shop/thumbnail-3.jpg';
import thumbnail4 from '../../assets/imgs/shop/thumbnail-4.jpg';
import thumbnail5 from '../../assets/imgs/shop/thumbnail-5.jpg';
// import banner from "*.png";
// import banner from "..//assets/imgs/banner/banner-11.png";
import banner from '../../assets/imgs/banner/banner-11.png';
import SCBreadcrambs from './SCBreadcrambs';
import vendor1 from '../../assets/imgs/vendor/vendor-1.png';
import svg from '../../assets/imgs/theme/icons/icon-contact.svg';
import svgEmail from '../../assets/imgs/theme/icons/icon-email-2.svg';
import svgRegNo from '../../assets/imgs/theme/icons/icon-1.svg';
import svgCart from '../../assets/imgs/theme/icons/icon-cart.svg';
import SearchInput from '../../Components/common/input/SearchInput';

function ServicesProduct() {
    // Our States
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(4);
    const [loactionList, setLoactionList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [makeList, setMakeList] = useState([]);
    const {Option} = Select;
    const {Search} = Input;
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const {SHOW_CHILD} = Cascader;
    const loadmore = () => {
        setVisible((prevValue) => prevValue + 4);
    };
    const [min, setMinValue] = useState('');
    const [max, setMaxValue] = useState('');
    const [isupdate, setisupdate] = React.useState(0);
    const rangeSelector = (event, newValue) => {
        const min_price = newValue[0];
        const max_price = newValue[1];
        setMinValue(min_price);
        setMaxValue(max_price);
        setisupdate(isupdate + 1);
    };
    useEffect(() => {
        axios
            .get('/service/center/list')
            .then((res) => {
                setData(res.data.data.services_center.data);
                const bankAllList = res.data.data.services_center.data.map((item) => {
                    return {value: item?.country?.id, label: item?.country?.name};
                });
                console.log(...bankAllList, 'bankAllList');
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
        setLoading(false);
        // Location
        locationFunction();
        // Services
        servicesFunction();
        // Make
        makeFunction();
        // Model
        modelFunction();
    }, [count]);

    // Location Area
    const locationFunction = () => {
        axios
            .get('/common/division-district/list ')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                    children: category?.districts.map((districts) => ({
                        value: districts?.id,
                        label: districts?.name,
                    })),
                }));
                setLoactionList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Services Area
    const servicesFunction = () => {
        axios
            .get('/service/category/list ')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                    children: category?.sub_category?.map((sub_category) => ({
                        value: sub_category.id,
                        label: sub_category.name,
                    })),
                }));
                setServicesList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Make Area
    const makeFunction = () => {
        axios
            .get('/common/vehicle/make/list')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                }));
                setMakeList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const makeListOnchangFunction = (e) => {
        if (e !== undefined) {
            axios
                .get(`/service/center/list?make_id=${e}`)
                .then((res) => {
                    console.log(res.data.data.services_center.data, '999999999');
                    setData(res.data.data.services_center.data);
                    // setCount(count + 1)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    // Model Area
    const modelFunction = () => {
        axios
            .get('/common/vehicle/model/list')
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                }));
                setModelList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const modelOnChangFunction = (e) => {
        if (e !== undefined) {
            axios
                .get(`/service/center/list?model_id=${e}`)
                .then((res) => {
                    console.log(res.data.data.services_center.data, '999999999');
                    setData(res.data.data.services_center.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    // Location Area
    const locationOnChange = (value, selectedOptions) => {
        if (value !== undefined) {
            if (value[1] !== undefined) {
                axios
                    .get(`/service/center/list?district_id=${value[1]}`)
                    .then((res) => {
                        console.log(res.data.data.services_center.data, '999999999');
                        setData(res.data.data.services_center.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
            }
        } else {
            console.log('999999');
            setCount(count + 1);
        }
    };

    // Catagories Services
    const servicesonChangeFun = (value) => {
        if (value !== undefined) {
            if (value[1] !== undefined) {
                axios
                    .get(`/service/center/list?categories_id=${value[1]}`)
                    .then((res) => {
                        console.log(res.data.data.services_center.data, '999999999');
                        setData(res.data.data.services_center.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
            }
        } else {
            console.log('999999');
            setCount(count + 1);
        }
    };
    console.log(data);

    const requestSearch = (searchedVal) => {
        if (searchedVal.length > 0) {
            const filteredRows = data?.filter((row) => {
                return row.company_name.toLowerCase().includes(searchedVal?.toLowerCase());
            });
            setData(filteredRows);
        } else {
            setCount(count + 1);
        }
    };
    console.log(data, 'data data');

    const handleSearch = (searchQuery) => {
        // Perform the search logic using the searchQuery
        console.log('Searching for:', searchQuery);
    };
    return (
        <>
            <SCBreadcrambs/>
            <br/>

            <div className='container'>
                <div className='row flex-row-reverse'>
                    <div className='col-lg-4-5'>
                        <SearchInput onSearch={handleSearch}/>

                        <div className='shop-product-fillter'>
                            <div className='totall-product'>
                                <p>
                                    We found <strong className='text-brand'>29</strong> items for you!
                                </p>
                            </div>
                            <div className='sort-by-product-area'>
                                <div className='sort-by-cover mr-10'>
                                    <div className='sort-by-product-wrap'>
                                        <div className='sort-by'>
                                            <span>
                                                <i className='fi-rs-apps'></i>Show:
                                            </span>
                                        </div>
                                        <div className='sort-by-dropdown-wrap'>
                                            <span>
                                                {' '}
                                                50 <i className='fi-rs-angle-small-down'></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='sort-by-dropdown'>
                                        <ul>
                                            <li>
                                                <Link className='active' to='#'>
                                                    50
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='#'>100</Link>
                                            </li>
                                            <li>
                                                <Link to='#'>150</Link>
                                            </li>
                                            <li>
                                                <Link to='#'>200</Link>
                                            </li>
                                            <li>
                                                <Link to='#'>All</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='sort-by-cover'>
                                    <div className='sort-by-product-wrap'>
                                        <div className='sort-by'>
                                            <span>
                                                <i className='fi-rs-apps-sort'></i>Sort by:
                                            </span>
                                        </div>
                                        <div className='sort-by-dropdown-wrap'>
                                            <span>
                                                {' '}
                                                Featured <i className='fi-rs-angle-small-down'></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='sort-by-dropdown'>
                                        <ul>
                                            <li>
                                                <Link className='active' to='#'>
                                                    Featured
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='#'>Price: Low to High</Link>
                                            </li>
                                            <li>
                                                <Link to='#'>Price: High to Low</Link>
                                            </li>
                                            <li>
                                                <Link to='#'>Release Date</Link>
                                            </li>
                                            <li>
                                                <Link to='#'>Avg. Rating</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row vendor-grid'>
                            {/*<div className="col-lg-1-5 col-md-4 col-12 col-sm-6">*/}
                            {/*    <div className="product-cart-wrap mb-30">*/}
                            {/*        <div className="product-img-action-wrap">*/}
                            {/*            <div className="product-img product-img-zoom">*/}
                            {/*                <Link to="shop-product-right.html">*/}
                            {/*                    <img className="default-img" src={product1} alt=""/>*/}
                            {/*                    <img className="hover-img" src={product2} alt=""/>*/}
                            {/*                </Link>*/}
                            {/*            </div>*/}
                            {/*            <div className="product-action-1">*/}
                            {/*                <Link aria-label="Add To Wishlist" className="action-btn"*/}
                            {/*                      to="shop-wishlist.html"><i className="fi-rs-heart"></i></Link>*/}
                            {/*                <Link aria-label="Compare" className="action-btn" to="shop-compare.html"><i*/}
                            {/*                    className="fi-rs-shuffle"></i></Link>*/}
                            {/*                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"*/}
                            {/*                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>*/}
                            {/*            </div>*/}
                            {/*            <div className="product-badges product-badges-position product-badges-mrg">*/}
                            {/*                <span className="hot">Hot</span>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        <div className="product-content-wrap">*/}
                            {/*            <div className="product-category">*/}
                            {/*                <Link to="shop-grid-right.html">Snack</Link>*/}
                            {/*            </div>*/}
                            {/*            <h2><Link to="shop-product-right.html">Seeds of Change Organic Quinoe</Link>*/}
                            {/*            </h2>*/}
                            {/*            <div className="product-rate-cover">*/}
                            {/*                <div className="product-rate d-inline-block">*/}
                            {/*                    <div className="product-rating"></div>*/}
                            {/*                </div>*/}
                            {/*                <span className="font-small ml-5 text-muted"> (4.0)</span>*/}
                            {/*            </div>*/}
                            {/*            <div>*/}
                            {/*            <span className="font-small text-muted">By <Link*/}
                            {/*                to="vendor-details-1.html">NestFood</Link></span>*/}
                            {/*            </div>*/}
                            {/*            <div className="product-card-bottom">*/}
                            {/*                <div className="product-price">*/}
                            {/*                    <span>$28.85</span>*/}
                            {/*                    <span className="old-price">$32.8</span>*/}
                            {/*                </div>*/}
                            {/*                <div className="add-cart">*/}
                            {/*                    <Link className="add" to="shop-cart.html"><i*/}
                            {/*                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        {/*<div className="productCategories__details_header">*/}
                                        {/*    <Row>*/}
                                        {/*        <Col lg={12}>*/}
                                        {/*            <Search*/}
                                        {/*                className="select__width"*/}
                                        {/*                onChange={(searchVal) =>*/}
                                        {/*                    requestSearch(searchVal.target.value)*/}
                                        {/*                }*/}
                                        {/*                placeholder="Search your Services"*/}
                                        {/*            />*/}
                                        {/*        </Col>*/}
                                        {/*    </Row>*/}
                                        {/*</div>*/}

                                        {data?.length > 0 ? (
                                            <>
                                                <Row>
                                                    {data?.slice(0, visible).map((item, i) => (
                                                        <div
                                                            className='col-lg-3-3 col-md-6 col-12 col-sm-6 justify-content-center'>
                                                            <div className='vendor-wrap mb-40'>
                                                                <div className='vendor-img-action-wrap'>
                                                                    <div className='vendor-img'>
                                                                        <Link
                                                                            to={`/services-center-product-details/${item?.slug}`}>
                                                                            <img
                                                                                className='default-img'
                                                                                src={apiImgUrl + item?.company_logo}
                                                                                alt=''
                                                                            />
                                                                            <img
                                                                                className='hover-img'
                                                                                src={apiImgUrl + item?.thumbnail}
                                                                                alt=''
                                                                            />
                                                                        </Link>
                                                                    </div>
                                                                    <div
                                                                        className='product-badges product-badges-position product-badges-mrg'>
                                                                        <span className='hot'>Mall</span>
                                                                    </div>
                                                                </div>
                                                                <div className='vendor-content-wrap'>
                                                                    <div
                                                                        className='d-flex justify-content-between align-items-end mb-30'>
                                                                        <div>
                                                                            <div className='product-category'>
                                                                                <span className='text-muted'>
                                                                                    <strong>Owner :</strong> {item?.company_owner}
                                                                                </span>
                                                                            </div>
                                                                            <h4 className='mb-5'>
                                                                                <Link
                                                                                    to={`/services-center-product-details/${item?.slug}`}>
                                                                                    {item?.company_name}
                                                                                </Link>
                                                                            </h4>

                                                                            <div className='vendor-info mb-10'>
                                                                                <ul className='contact-infor text-muted'>
                                                                                    {/*<li><img src={svg} alt=""/><strong> Address :</strong><span> {item?.company_owner}</span></li>*/}
                                                                                    <li className='mb-1'>
                                                                                        <img src={svgRegNo} alt=''/>
                                                                                        <strong> RegNo :</strong>
                                                                                        <span> {item?.registration_no}</span>
                                                                                    </li>
                                                                                    <li className='mb-1'>
                                                                                        <img src={svg} alt=''/>
                                                                                        <strong> Phone :</strong>
                                                                                        <span> {item?.phone}</span>
                                                                                    </li>
                                                                                    <li className='mb-1'>
                                                                                        <img src={svgEmail} alt=''/>
                                                                                        <strong> Email :</strong>
                                                                                        <span> {item?.email}</span>
                                                                                    </li>
                                                                                    <li className='mb-1'>
                                                                                        <img alt=''/>
                                                                                        <strong> Address :</strong>
                                                                                        <span> {item?.address}</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>

                                                                            <div className='product-rate-cover'>
                                                                                <span
                                                                                    className='font-small total-product'>
                                                                                    380 products
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='vendor-info mb-10'>
                                                                        <ul className='contact-infor text-muted'>
                                                                            <li>
                                                                                <img src={svgCart} alt=''/>
                                                                                <strong>Services :</strong>
                                                                                <span>
                                                                                    {' '}
                                                                                    {item?.service_list.length > 0 ? (
                                                                                        <>
                                                                                            {item?.service_list?.map((service_list, i) => (
                                                                                                <li className='mb-1'>
                                                                                                    <Link
                                                                                                        key={i}
                                                                                                        to={`/services-center-product-details/${service_list.slug}`}
                                                                                                    >
                                                                                                        {' '}
                                                                                                        <span>
                                                                                                            {service_list?.name}
                                                                                                        </span>{' '}
                                                                                                    </Link>
                                                                                                </li>
                                                                                            ))}
                                                                                        </>
                                                                                    ) : (
                                                                                        <> </>
                                                                                    )}
                                                                                </span>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <Link to='' className='btn btn-xs'>
                                                                        Visit Store <i
                                                                        className='fi-rs-arrow-small-right'></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Row>
                                            </>
                                        ) : (
                                            <> </>
                                        )}
                                        <Col lg={12}>
                                            {data?.length > visible ? (
                                                <>
                                                    <button className='loadMore__btn' type='button' onClick={loadmore}>
                                                        {' '}
                                                        Load More{' '}
                                                    </button>
                                                    {' '}
                                                </>
                                            ) : (
                                                <> </>
                                            )}
                                        </Col>

                                        <Col lg={12}>
                                            {data?.length > visible ? (
                                                <>
                                                    <button className='loadMore__btn' type='button' onClick={loadmore}>
                                                        {' '}
                                                        Load More
                                                    </button>
                                                </>
                                            ) : (
                                                <> </>
                                            )}
                                        </Col>
                                    </Col>
                                </Row>
                            </Container>
                        </div>

                        <div className='pagination-area mt-20 mb-20'>
                            <nav aria-label='Page navigation example'>
                                <ul className='pagination justify-content-start'>
                                    <li className='page-item'>
                                        <a className='page-link' href='#'>
                                            <i className='fi-rs-arrow-small-left'></i>
                                        </a>
                                    </li>
                                    <li className='page-item'>
                                        <a className='page-link' href='#'>
                                            1
                                        </a>
                                    </li>
                                    <li className='page-item active'>
                                        <a className='page-link' href='#'>
                                            2
                                        </a>
                                    </li>
                                    <li className='page-item'>
                                        <a className='page-link' href='#'>
                                            3
                                        </a>
                                    </li>
                                    <li className='page-item'>
                                        <a className='page-link dot' href='#'>
                                            ...
                                        </a>
                                    </li>
                                    <li className='page-item'>
                                        <a className='page-link' href='#'>
                                            6
                                        </a>
                                    </li>
                                    <li className='page-item'>
                                        <a className='page-link' href='#'>
                                            <i className='fi-rs-arrow-small-right'></i>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div className='col-lg-1-5 primary-sidebar sticky-sidebar'>
                        <div className='sidebar-widget widget-category-2 mb-30'>
                            <h5 className='section-title style-1 mb-30'>Category</h5>
                            <ul>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category1} alt=''/>
                                        Milks & Dairies
                                    </Link>
                                    <span className='count'>30</span>
                                </li>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category2} alt=''/>
                                        Clothing
                                    </Link>
                                    <span className='count'>35</span>
                                </li>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category3} alt=''/>
                                        Pet Foods{' '}
                                    </Link>
                                    <span className='count'>42</span>
                                </li>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category4} alt=''/>
                                        Baking material
                                    </Link>
                                    <span className='count'>68</span>
                                </li>
                                <li>
                                    <Link to='shop-grid-right.html'>
                                        <img src={category5} alt=''/>
                                        Fresh Fruit
                                    </Link>
                                    <span className='count'>87</span>
                                </li>
                            </ul>
                        </div>

                        <div className='sidebar-widget price_range range mb-30'>
                            <h5 className='section-title style-1 mb-30'>Filter Product</h5>
                            <Form form={form} layout='vertical'>
                                <Form.Item label='Location' tooltip='Select You Location'>
                                    <Cascader
                                        className='w-100'
                                        placeholder='Location'
                                        onChange={(e) => locationOnChange(e)}
                                        options={loactionList}
                                        changeOnSelect
                                    />
                                </Form.Item>
                                {/*<Form.Item label="Price Range" tooltip="Select You Price Range">*/}
                                {/*    <div className="price-filter">*/}
                                {/*        <div className="price-filter-inner">*/}
                                {/*            <Slider range={{draggableTrack: true}} min={0} max={50000} step={100}*/}
                                {/*                    onChange={rangeSelector} defaultValue={[0, 50000]}/>*/}
                                {/*            <div className="d-flex justify-content-between">*/}
                                {/*                <div className="caption">From: <strong*/}
                                {/*                    className="text-brand">{min ? min : 0}</strong></div>*/}
                                {/*                <div className="caption">To: <strong*/}
                                {/*                    className="text-brand">{max ? max : 50000}</strong></div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</Form.Item>*/}
                                <Form.Item label='Services' tooltip='Select You Services'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => servicesonChangeFun(e)}
                                        placeholder='Make'
                                        allowClear
                                        options={servicesList}
                                    />
                                    {/*<Select className='w-100' onClick={(e) => servicesonChangeFun(e)}*/}
                                    {/*        onChange={(e) => servicesonChangeFun(e)} allowClear*/}
                                    {/*        placeholder="Select You Services">*/}
                                    {/*    {servicesList?.map((item, i) =>*/}
                                    {/*        <Option key={i} value={`${item?.id}`}>{item?.name}</Option>*/}
                                    {/*    )}*/}
                                    {/*</Select>*/}
                                </Form.Item>
                                {/*<Form.Item label="Sub Category" tooltip="Select You Sub Category">*/}
                                {/*    <Select mode="multiple" className='w-100' onChange={(e) => servicesonChangeFun(e)}*/}
                                {/*            placeholder="Select your Category">*/}
                                {/*        {servicesList?.map((item, i) =>*/}
                                {/*            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>*/}
                                {/*        )}*/}
                                {/*    </Select>*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item label="Parts Type" tooltip="Select Parts Type">*/}
                                {/*    <Select className='w-100' onChange={(e) => partsTypeFunction(e)} allowClear*/}
                                {/*            placeholder="Select Parts type">*/}
                                {/*        {partsTypesList?.map((item, i) =>*/}
                                {/*            <Option key={i} value={`${item?.id}`}>{item?.name}</Option>*/}
                                {/*        )}*/}
                                {/*    </Select>*/}
                                {/*</Form.Item>*/}
                                <Form.Item label='Make' tooltip='Select Parts Make'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => makeListOnchangFunction(e)}
                                        placeholder='Make'
                                        allowClear
                                        options={makeList}
                                    />
                                </Form.Item>
                                <Form.Item label='Model' tooltip='Select Parts Model'>
                                    <Select
                                        className='w-100'
                                        onChange={(e) => modelOnChangFunction(e)}
                                        placeholder='Model'
                                        allowClear
                                        options={modelList}
                                    />
                                </Form.Item>
                            </Form>

                            <a href='shop-grid-right.html' className='btn btn-sm btn-default btn-block'>
                                <i className='fi-rs-filter mr-5'></i> Fillter
                            </a>
                        </div>

                        {/*<div className='sidebar-widget product-sidebar mb-30 p-30 bg-grey border-radius-10'>*/}
                        {/*    <h5 className='section-title style-1 mb-30'>New products</h5>*/}
                        {/*    <div className='single-post clearfix'>*/}
                        {/*        <div className='image'>*/}
                        {/*            <img src={thumbnail3} alt='#' />*/}
                        {/*        </div>*/}
                        {/*        <div className='content pt-10'>*/}
                        {/*            <h5>*/}
                        {/*                <a href='shop-product-detail.html'>Chen Cardigan</a>*/}
                        {/*            </h5>*/}
                        {/*            <p className='price mb-0 mt-5'>$99.50</p>*/}
                        {/*            <div className='product-rate'>*/}
                        {/*                <div className='product-rating'></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className='single-post clearfix'>*/}
                        {/*        <div className='image'>*/}
                        {/*            <img src={thumbnail4} alt='#' />*/}
                        {/*        </div>*/}
                        {/*        <div className='content pt-10'>*/}
                        {/*            <h6>*/}
                        {/*                <a href='shop-product-detail.html'>Chen Sweater</a>*/}
                        {/*            </h6>*/}
                        {/*            <p className='price mb-0 mt-5'>$89.50</p>*/}
                        {/*            <div className='product-rate'>*/}
                        {/*                <div className='product-rating'></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className='single-post clearfix'>*/}
                        {/*        <div className='image'>*/}
                        {/*            <img src={thumbnail5} alt='#' />*/}
                        {/*        </div>*/}
                        {/*        <div className='content pt-10'>*/}
                        {/*            <h6>*/}
                        {/*                <a href='shop-product-detail.html'>Colorful Jacket</a>*/}
                        {/*            </h6>*/}
                        {/*            <p className='price mb-0 mt-5'>$25</p>*/}
                        {/*            <div className='product-rate'>*/}
                        {/*                <div className='product-rating'></div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className='banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none'>*/}
                        {/*    <img src={banner} alt='' />*/}
                        {/*    <div className='banner-text'>*/}
                        {/*        <span>Oganic</span>*/}
                        {/*        <h4>*/}
                        {/*            Save 17% <br />*/}
                        {/*            on <span className='text-brand'>Oganic</span>*/}
                        {/*            <br />*/}
                        {/*            Juice*/}
                        {/*        </h4>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServicesProduct;
