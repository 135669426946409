import React from "react";
import { Button, Modal } from "react-bootstrap";

const CustomModal = (props) => {
  const { modalTitle = false, children, footer = true } = props;

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      {modalTitle && (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>{children}</Modal.Body>

      {footer && (
        <Modal.Footer>
          <Button className="btn-secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button onClick={props.onHide}>Submit</Button>
        </Modal.Footer>
      )}
    </Modal>
    // <div class="modal fade show" style={modalStyle}>
    //   <div class={`modal-dialog modal-dialog-centered ${size}`}>
    //     <div class="modal-content">
    //       {modalTitle && (
    //         <div class="modal-header">
    //           <h3 class="modal-title">{modalTitle}</h3>
    //         </div>
    //       )}

    //       <div class="modal-body">
    //         <div class="container">{children}</div>
    //       </div>

    //       {footer && (
    //         <div class="modal-footer">
    //           <button
    //             type="button"
    //             class="btn btn-secondary"
    //             data-bs-dismiss="modal"
    //             onClick={onClose}
    //           >
    //             Cancel
    //           </button>

    //           <button
    //             type="button"
    //             class="btn btn-primary"
    //             data-bs-dismiss="modal"
    //             onClick={onSubmit}
    //           >
    //             Confirm
    //           </button>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // </div>
  );
};

export default CustomModal;
