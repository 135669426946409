import React from "react";
import { Grid, Typography, styled } from "@material-ui/core";
import { InputField, SelectField } from "../../FormFields";

const CustomInputField = styled(InputField)(({ theme }) => ({
  "& .MuiInputBase-input:focus-visible": {
    border: "none",
  },
}));

export default function AddressForm(props) {
  const {
    formField: {
      fullName,
      kilometer,
      contactNo,
      problemdetails,
      useraddres,
      make,
      model,
    },
  } = props;
  const cities = [
    {
      value: undefined,
      label: "None",
    },
    {
      value: "1",
      label: "New York",
    },
    {
      value: "2",
      label: "Chicago",
    },
    {
      value: "3",
      label: "Saigon",
    },
  ];
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Form Fill-Up
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <CustomInputField
            name={fullName?.name}
            label={fullName?.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInputField
            name={kilometer?.name}
            label={kilometer?.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInputField
            name={contactNo?.name}
            label={contactNo?.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInputField
            name={problemdetails?.name}
            label={problemdetails?.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <CustomInputField
            name={useraddres?.name}
            label={useraddres?.label}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={make?.name}
            label={make?.label}
            data={cities}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField
            name={model?.name}
            label={model?.label}
            data={cities}
            fullWidth
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
