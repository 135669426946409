import React from "react";
import Headline from "../../../../../../Components/common/Headline";

const ConfirmationStepContent = () => {
  return (
    <div className="mt-50">
      <Headline title="Booking Details" />

      <div className="row">
        <div className="col-xl-6">
          <div class="d-flex align-items-center gap-5 mb-4">
            <div>
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder"
              >
                From Date
              </label>

              <h4>10/05/2022</h4>
            </div>

            <div>
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder"
              >
                For
              </label>

              <h4>5 Days</h4>
            </div>

            <div>
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder"
              >
                Trip Type
              </label>

              <h4>In City</h4>
            </div>

            <div>
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder"
              >
                Vehicle Type
              </label>

              <h4>Suv</h4>
            </div>

            <div>
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder"
              >
                Status
              </label>

              <h4 className="text-brand">Available</h4>
            </div>
          </div>

          <div class="d-flex align-items-center gap-5 mb-4">
            <div>
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder"
              >
                Time of Journey
              </label>

              <h4>1.30 PM</h4>
            </div>

            <div>
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder"
              >
                Vehicle Type
              </label>

              <h4>Suv</h4>
            </div>

            <div>
              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold"
              >
                Notes
              </label>

              <h5>Please arrive at pick up location on time</h5>
            </div>
          </div>
        </div>
        <div className="col-xl-4 d-flex align-items-center justify-content-center flex-column border-start">
          <div className="">
            <h5>Total Cost</h5>
            <h3 className="mb-2">6000 BDT</h3>
          </div>
          {/* <table class="table">
            <label
              for="exampleFormControlInput1"
              class="form-label d-block font-weight-bold"
            >
              Total Charges
            </label>

            <tbody>
              <tr>
                <td>Total Rate Charges</td>
                <td>4000</td>
              </tr>
              <tr>
                <td>Other Charges</td>
                <td>2000</td>
              </tr>
              <tr>
                <th scope="row">
                  <h6>Sub Total</h6>
                </th>
                <td className="fw-bold">
                  <h6>6000</h6>
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationStepContent;
