import React from 'react';
import BreadCrumb from '../../Components/common/BreadCrumb';
import MyAccountNavList from './MyAccountNavList';
import MyAccountPageList from './MyAccountPageList';

const MyAccount = () => {
    return (
        <main class='main pages'>
            <BreadCrumb root='Home' category='page' sub='My Account' />

            <div class='page-content pt-50 pb-100'>
                <div class='container'>
                    <div class='row'>
                        <div class='col-lg-12 m-auto'>
                            <div class='row'>
                                <div class='col-md-3'>
                                    <MyAccountNavList />
                                </div>
                                <div class='col-md-9'>
                                    <MyAccountPageList />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default MyAccount;
