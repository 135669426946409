import React from "react";
import Iframe from "react-iframe";

const GoogleMap = ({
  latitude = 23.874,
  longitude = 90.3798,
  height = "150px",
  width = "100%",
  classes = "",
}) => {
  return (
    <Iframe
      url={`https://maps.google.com/maps?q=${latitude},${longitude}&output=embed`}
      width={width}
      height={height}
      id="myId"
      className={classes}
      display="initial"
      position="relative"
    />
  );
};

export default GoogleMap;
