import React from "react";
import SliderImage from "react-zoom-slider";

const ProductGallery = ({ dataImg }) => {
  // console.log(dataImg);
  return (
    <div className="detail-gallery">
      <span className="zoom-icon">
        <i className="fi-rs-search"></i>
      </span>
      <div className="product-image-slider">
        {dataImg?.length > 0 ? (
          <>
            <SliderImage
              data={dataImg}
              showDescription={true}
              direction="right"
              height="1000px"
            />
          </>
        ) : (
          <>
            <div className="images____area">
              <img
                src="https://image.shutterstock.com/image-vector/photography-prohibited-sign-not-allowed-260nw-1897944295.jpg"
                alt="okk"
              />
              <div className="images__white__bottom"></div>
            </div>
          </>
        )}
        {/* <figure className="border-radius-10">
          <img src={dataImg[0]?.image} alt="product image" />
        </figure> */}
        {/* <figure className="border-radius-10">
            <img src="assets/imgs/shop/product-16-2.jpg" alt="product image"/>
          </figure>
          <figure className="border-radius-10">
            <img src="assets/imgs/shop/product-16-1.jpg" alt="product image"/>
          </figure>
          <figure className="border-radius-10">
            <img src="assets/imgs/shop/product-16-3.jpg" alt="product image"/>
          </figure>
          <figure className="border-radius-10">
            <img src="assets/imgs/shop/product-16-4.jpg" alt="product image"/>
          </figure>
          <figure className="border-radius-10">
            <img src="assets/imgs/shop/product-16-5.jpg" alt="product image"/>
          </figure>
          <figure className="border-radius-10">
            <img src="assets/imgs/shop/product-16-6.jpg" alt="product image"/>
          </figure>
          <figure className="border-radius-10">
            <img src="assets/imgs/shop/product-16-7.jpg" alt="product image"/>
          </figure> */}
      </div>
      <div className="slider-nav-thumbnails">
        {/* <div><img src="assets/imgs/shop/thumbnail-3.jpg" alt="product image"/></div>
          <div><img src="assets/imgs/shop/thumbnail-4.jpg" alt="product image"/></div>
          <div><img src="assets/imgs/shop/thumbnail-5.jpg" alt="product image"/></div>
          <div><img src="assets/imgs/shop/thumbnail-6.jpg" alt="product image"/></div>
          <div><img src="assets/imgs/shop/thumbnail-7.jpg" alt="product image"/></div>
          <div><img src="assets/imgs/shop/thumbnail-8.jpg" alt="product image"/></div>
          <div><img src="assets/imgs/shop/thumbnail-9.jpg" alt="product image"/></div> */}
      </div>
    </div>
  );
};

export default ProductGallery;
