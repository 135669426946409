import React from "react";
import ProductOne from "../../../assets/imgs/shop/product-1-1.jpg";
import ProductTwo from "../../../assets/imgs/shop/product-2-1.jpg";
import ProductThree from "../../../assets/imgs/shop/product-3-1.jpg";

const SummaryList = () => {
  return (
    <tbody>
      <tr class="pt-30">
        <td class="custome-checkbox pl-30">
          <input
            class="form-check-input"
            type="checkbox"
            name="checkbox"
            id="exampleCheckbox1"
            value=""
          />
          <label class="form-check-label" for="exampleCheckbox1"></label>
        </td>
        <td class="image product-thumbnail pt-40">
          <img src={ProductOne} alt="#" />
        </td>
        <td class="product-des product-name">
          <h6 class="mb-5">
            <a
              class="product-name mb-10 text-heading"
              href="shop-product-right.html"
            >
              Field Roast Chao Cheese Creamy Original
            </a>
          </h6>
          <div class="product-rate-cover">
            <div class="product-rate d-inline-block">
              {/* <div class="product-rating" style="width:90%"></div> */}
            </div>
            <span class="font-small ml-5 text-muted"> (4.0)</span>
          </div>
        </td>
        <td class="price" data-title="Price">
          <h4 class="text-body">$2.51 </h4>
        </td>
        <td class="text-center detail-info" data-title="Stock">
          <div class="detail-extralink mr-15">
            <div class="detail-qty border radius">
              <a href="#" class="qty-down">
                <i class="fi-rs-angle-small-down"></i>
              </a>
              <input
                type="text"
                name="quantity"
                class="qty-val"
                value="1"
                min="1"
              />
              <a href="#" class="qty-up">
                <i class="fi-rs-angle-small-up"></i>
              </a>
            </div>
          </div>
        </td>
        <td class="price" data-title="Price">
          <h4 class="text-brand">$2.51 </h4>
        </td>
        <td class="action text-center" data-title="Remove">
          <a href="#" class="text-body">
            <i class="fi-rs-trash"></i>
          </a>
        </td>
      </tr>
      <tr>
        <td class="custome-checkbox pl-30">
          <input
            class="form-check-input"
            type="checkbox"
            name="checkbox"
            id="exampleCheckbox2"
            value=""
          />
          <label class="form-check-label" for="exampleCheckbox2"></label>
        </td>
        <td class="image product-thumbnail">
          <img src={ProductTwo} alt="#" />
        </td>
        <td class="product-des product-name">
          <h6 class="mb-5">
            <a
              class="product-name mb-10 text-heading"
              href="shop-product-right.html"
            >
              Blue Diamond Almonds Lightly Salted
            </a>
          </h6>
          <div class="product-rate-cover">
            <div class="product-rate d-inline-block">
              {/* <div class="product-rating" style="width:90%"></div> */}
            </div>
            <span class="font-small ml-5 text-muted"> (4.0)</span>
          </div>
        </td>
        <td class="price" data-title="Price">
          <h4 class="text-body">$3.2 </h4>
        </td>
        <td class="text-center detail-info" data-title="Stock">
          <div class="detail-extralink mr-15">
            <div class="detail-qty border radius">
              <a href="#" class="qty-down">
                <i class="fi-rs-angle-small-down"></i>
              </a>
              <input
                type="text"
                name="quantity"
                class="qty-val"
                value="1"
                min="1"
              />
              <a href="#" class="qty-up">
                <i class="fi-rs-angle-small-up"></i>
              </a>
            </div>
          </div>
        </td>
        <td class="price" data-title="Price">
          <h4 class="text-brand">$3.2 </h4>
        </td>
        <td class="action text-center" data-title="Remove">
          <a href="#" class="text-body">
            <i class="fi-rs-trash"></i>
          </a>
        </td>
      </tr>
      <tr>
        <td class="custome-checkbox pl-30">
          <input
            class="form-check-input"
            type="checkbox"
            name="checkbox"
            id="exampleCheckbox3"
            value=""
          />
          <label class="form-check-label" for="exampleCheckbox3"></label>
        </td>
        <td class="image product-thumbnail">
          <img src={ProductThree} alt="#" />
        </td>
        <td class="product-des product-name">
          <h6 class="mb-5">
            <a
              class="product-name mb-10 text-heading"
              href="shop-product-right.html"
            >
              Fresh Organic Mustard Leaves Bell Pepper
            </a>
          </h6>
          <div class="product-rate-cover">
            <div class="product-rate d-inline-block">
              {/* <div class="product-rating" style="width:90%"></div> */}
            </div>
            <span class="font-small ml-5 text-muted"> (4.0)</span>
          </div>
        </td>
        <td class="price" data-title="Price">
          <h4 class="text-body">$2.43 </h4>
        </td>
        <td class="text-center detail-info" data-title="Stock">
          <div class="detail-extralink mr-15">
            <div class="detail-qty border radius">
              <a href="#" class="qty-down">
                <i class="fi-rs-angle-small-down"></i>
              </a>
              <input
                type="text"
                name="quantity"
                class="qty-val"
                value="1"
                min="1"
              />
              <a href="#" class="qty-up">
                <i class="fi-rs-angle-small-up"></i>
              </a>
            </div>
          </div>
        </td>
        <td class="price" data-title="Price">
          <h4 class="text-brand">$2.43 </h4>
        </td>
        <td class="action text-center" data-title="Remove">
          <a href="#" class="text-body">
            <i class="fi-rs-trash"></i>
          </a>
        </td>
      </tr>
    </tbody>
  );
};

export default SummaryList;
