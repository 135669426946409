import React from "react";
import { Link, useParams } from "react-router-dom";

const OfferDetailsModal = ({
  modalTitle = "Special Offer",
  onClose,
  onSubmit,
  data,
}) => {
  const { slug } = useParams();

  const { title: bodyTitle, image, description, price } = data;

  const modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0, .7)",
  };

  return (
    <div class="modal fade show" style={modalStyle}>
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{modalTitle}</h3>

            <div>
              <Link
                className="hover-up btn btn-sm"
                to={`/services-booking/${slug}`}
              >
                <span> Book Service Schedule </span>
              </Link>
              {/* <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // onClick={onClose}
              ></button> */}
            </div>
          </div>
          <div class="modal-body">
            <div class="container">
              <div class="row">
                <div class="col-8">
                  <h4 className="mb-15">{bodyTitle}</h4>

                  <p className="mb-30">{description}</p>

                  <h6>Offer Price: {price}</h6>
                </div>
                <div class="col-4">
                  <img
                    className="border-radius-10"
                    src={image}
                    alt={bodyTitle}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferDetailsModal;
