import React, {useState} from 'react';
import {Link} from "react-router-dom";
import product1 from "../../assets/imgs/shop/product-5-1.jpg";
import product2 from "../../assets/imgs/shop/product-5-2.jpg";
import product3 from "../../assets/imgs/shop/product-2-1.jpg";
import product4 from "../../assets/imgs/shop/product-2-2.jpg";
import product5 from "../../assets/imgs/shop/product-3-1.jpg";
import product6 from "../../assets/imgs/shop/product-3-1.jpg";
function HomeProducts() {
    const [setTabActive, setTabState] = useState([1])
    function  setTabs(tab){
        setTabState(tab);
    }
    return (
        <div>
            <section className="product-tabs section-padding position-relative">
                <div className="container">
                    <div className="section-title style-2 wow animate__animated animate__fadeIn">
                        <h3> New Products </h3>
                        <ul className="nav nav-tabs links" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className={setTabActive === 1 ? 'nav-link active' : 'nav-link'} onClick={() => setTabs(1)} id="nav-tab-one" data-bs-toggle="tab"
                                         type="button" role="tab" aria-controls="tab-one"
                                        aria-selected="true">All
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={setTabActive === 2 ? 'nav-link active' : 'nav-link'}  onClick={() => setTabs(2)} id="nav-tab-two" data-bs-toggle="tab"
                                         type="button" role="tab" aria-controls="tab-two"
                                        aria-selected="false">Milks & Dairies
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={setTabActive === 3 ? 'nav-link active' : 'nav-link'} onClick={() => setTabs(3)} id="nav-tab-three" data-bs-toggle="tab"
                                         type="button" role="tab" aria-controls="tab-three"
                                        aria-selected="false">Coffes & Teas
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={setTabActive === 4 ? 'nav-link active' : 'nav-link'} onClick={() => setTabs(4)} id="nav-tab-four" data-bs-toggle="tab"
                                         type="button" role="tab" aria-controls="tab-four"
                                        aria-selected="false">Pet Foods
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={setTabActive === 5 ? 'nav-link active' : 'nav-link'} onClick={() => setTabs(5)} id="nav-tab-five" data-bs-toggle="tab"
                                        type="button" role="tab" aria-controls="tab-five"
                                        aria-selected="false">Meats
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={setTabActive === 6 ? 'nav-link active' : 'nav-link'} onClick={() => setTabs(6)} id="nav-tab-six" data-bs-toggle="tab"
                                         type="button" role="tab" aria-controls="tab-six"
                                        aria-selected="false">Vegetables
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={setTabActive === 7 ? 'nav-link active' : 'nav-link'} onClick={() => setTabs(7)} id="nav-tab-seven" data-bs-toggle="tab"
                                         type="button" role="tab" aria-controls="tab-seven"
                                        aria-selected="false">Fruits
                                </button>
                            </li>
                        </ul>
                    </div>

                    <div className="tab-content" id="myTabContent">
                        <div className={setTabActive === 1 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                              <div className="row product-grid-4">
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                   to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                   data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                      <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                           data-wow-delay=".1s">
                                          <div className="product-img-action-wrap">
                                              <div className="product-img product-img-zoom">
                                                  <Link to="">
                                                      <img className="default-img" src={product1}
                                                           alt=""/>
                                                      <img className="hover-img" src={product2}
                                                           alt=""/>
                                                  </Link>
                                              </div>
                                              <div className="product-action-1">
                                                  <Link aria-label="Add To Wishlist" className="action-btn"
                                                        to=""><i className="fi-rs-heart"></i></Link>
                                                  <Link aria-label="Compare" className="action-btn" to=""><i
                                                      className="fi-rs-shuffle"></i></Link>
                                                  <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                        data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                              </div>
                                              <div className="product-badges product-badges-position product-badges-mrg">
                                                  <span className="hot">Hot</span>
                                              </div>
                                          </div>
                                          <div className="product-content-wrap">
                                              <div className="product-category">
                                                  <Link to="">Snack</Link>
                                              </div>
                                              <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                  & Red Rice</Link></h2>
                                              <div className="product-rate-cover">
                                                  <div className="product-rate d-inline-block">
                                                      <div className="product-rating" ></div>
                                                  </div>
                                                  <span className="font-small ml-5 text-muted"> (4.0)</span>
                                              </div>
                                              <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                              </div>
                                              <div className="product-card-bottom">
                                                  <div className="product-price">
                                                      <span>$28.85</span>
                                                      <span className="old-price">$32.8</span>
                                                  </div>
                                                  <div className="add-cart">
                                                      <Link className="add" to=""><i
                                                          className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                  <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                       data-wow-delay=".1s">
                                      <div className="product-img-action-wrap">
                                          <div className="product-img product-img-zoom">
                                              <Link to="">
                                                  <img className="default-img" src={product1}
                                                       alt=""/>
                                                  <img className="hover-img" src={product2}
                                                       alt=""/>
                                              </Link>
                                          </div>
                                          <div className="product-action-1">
                                              <Link aria-label="Add To Wishlist" className="action-btn"
                                                    to=""><i className="fi-rs-heart"></i></Link>
                                              <Link aria-label="Compare" className="action-btn" to=""><i
                                                  className="fi-rs-shuffle"></i></Link>
                                              <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                    data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                          </div>
                                          <div className="product-badges product-badges-position product-badges-mrg">
                                              <span className="hot">Hot</span>
                                          </div>
                                      </div>
                                      <div className="product-content-wrap">
                                          <div className="product-category">
                                              <Link to="">Snack</Link>
                                          </div>
                                          <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                              & Red Rice</Link></h2>
                                          <div className="product-rate-cover">
                                              <div className="product-rate d-inline-block">
                                                  <div className="product-rating" ></div>
                                              </div>
                                              <span className="font-small ml-5 text-muted"> (4.0)</span>
                                          </div>
                                          <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                          </div>
                                          <div className="product-card-bottom">
                                              <div className="product-price">
                                                  <span>$28.85</span>
                                                  <span className="old-price">$32.8</span>
                                              </div>
                                              <div className="add-cart">
                                                  <Link className="add" to=""><i
                                                      className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                  <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                       data-wow-delay=".1s">
                                      <div className="product-img-action-wrap">
                                          <div className="product-img product-img-zoom">
                                              <Link to="">
                                                  <img className="default-img" src={product1}
                                                       alt=""/>
                                                  <img className="hover-img" src={product2}
                                                       alt=""/>
                                              </Link>
                                          </div>
                                          <div className="product-action-1">
                                              <Link aria-label="Add To Wishlist" className="action-btn"
                                                    to=""><i className="fi-rs-heart"></i></Link>
                                              <Link aria-label="Compare" className="action-btn" to=""><i
                                                  className="fi-rs-shuffle"></i></Link>
                                              <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                    data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                          </div>
                                          <div className="product-badges product-badges-position product-badges-mrg">
                                              <span className="hot">Hot</span>
                                          </div>
                                      </div>
                                      <div className="product-content-wrap">
                                          <div className="product-category">
                                              <Link to="">Snack</Link>
                                          </div>
                                          <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                              & Red Rice</Link></h2>
                                          <div className="product-rate-cover">
                                              <div className="product-rate d-inline-block">
                                                  <div className="product-rating" ></div>
                                              </div>
                                              <span className="font-small ml-5 text-muted"> (4.0)</span>
                                          </div>
                                          <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                          </div>
                                          <div className="product-card-bottom">
                                              <div className="product-price">
                                                  <span>$28.85</span>
                                                  <span className="old-price">$32.8</span>
                                              </div>
                                              <div className="add-cart">
                                                  <Link className="add" to=""><i
                                                      className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                  <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                       data-wow-delay=".1s">
                                      <div className="product-img-action-wrap">
                                          <div className="product-img product-img-zoom">
                                              <Link to="">
                                                  <img className="default-img" src={product1}
                                                       alt=""/>
                                                  <img className="hover-img" src={product2}
                                                       alt=""/>
                                              </Link>
                                          </div>
                                          <div className="product-action-1">
                                              <Link aria-label="Add To Wishlist" className="action-btn"
                                                    to=""><i className="fi-rs-heart"></i></Link>
                                              <Link aria-label="Compare" className="action-btn" to=""><i
                                                  className="fi-rs-shuffle"></i></Link>
                                              <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                    data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                          </div>
                                          <div className="product-badges product-badges-position product-badges-mrg">
                                              <span className="hot">Hot</span>
                                          </div>
                                      </div>
                                      <div className="product-content-wrap">
                                          <div className="product-category">
                                              <Link to="">Snack</Link>
                                          </div>
                                          <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                              & Red Rice</Link></h2>
                                          <div className="product-rate-cover">
                                              <div className="product-rate d-inline-block">
                                                  <div className="product-rating" ></div>
                                              </div>
                                              <span className="font-small ml-5 text-muted"> (4.0)</span>
                                          </div>
                                          <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                          </div>
                                          <div className="product-card-bottom">
                                              <div className="product-price">
                                                  <span>$28.85</span>
                                                  <span className="old-price">$32.8</span>
                                              </div>
                                              <div className="add-cart">
                                                  <Link className="add" to=""><i
                                                      className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                  <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                       data-wow-delay=".1s">
                                      <div className="product-img-action-wrap">
                                          <div className="product-img product-img-zoom">
                                              <Link to="">
                                                  <img className="default-img" src={product1}
                                                       alt=""/>
                                                  <img className="hover-img" src={product2}
                                                       alt=""/>
                                              </Link>
                                          </div>
                                          <div className="product-action-1">
                                              <Link aria-label="Add To Wishlist" className="action-btn"
                                                    to=""><i className="fi-rs-heart"></i></Link>
                                              <Link aria-label="Compare" className="action-btn" to=""><i
                                                  className="fi-rs-shuffle"></i></Link>
                                              <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                    data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                          </div>
                                          <div className="product-badges product-badges-position product-badges-mrg">
                                              <span className="hot">Hot</span>
                                          </div>
                                      </div>
                                      <div className="product-content-wrap">
                                          <div className="product-category">
                                              <Link to="">Snack</Link>
                                          </div>
                                          <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                              & Red Rice</Link></h2>
                                          <div className="product-rate-cover">
                                              <div className="product-rate d-inline-block">
                                                  <div className="product-rating" ></div>
                                              </div>
                                              <span className="font-small ml-5 text-muted"> (4.0)</span>
                                          </div>
                                          <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                          </div>
                                          <div className="product-card-bottom">
                                              <div className="product-price">
                                                  <span>$28.85</span>
                                                  <span className="old-price">$32.8</span>
                                              </div>
                                              <div className="add-cart">
                                                  <Link className="add" to=""><i
                                                      className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                  <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                       data-wow-delay=".1s">
                                      <div className="product-img-action-wrap">
                                          <div className="product-img product-img-zoom">
                                              <Link to="">
                                                  <img className="default-img" src={product1}
                                                       alt=""/>
                                                  <img className="hover-img" src={product2}
                                                       alt=""/>
                                              </Link>
                                          </div>
                                          <div className="product-action-1">
                                              <Link aria-label="Add To Wishlist" className="action-btn"
                                                    to=""><i className="fi-rs-heart"></i></Link>
                                              <Link aria-label="Compare" className="action-btn" to=""><i
                                                  className="fi-rs-shuffle"></i></Link>
                                              <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                    data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                          </div>
                                          <div className="product-badges product-badges-position product-badges-mrg">
                                              <span className="hot">Hot</span>
                                          </div>
                                      </div>
                                      <div className="product-content-wrap">
                                          <div className="product-category">
                                              <Link to="">Snack</Link>
                                          </div>
                                          <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                              & Red Rice</Link></h2>
                                          <div className="product-rate-cover">
                                              <div className="product-rate d-inline-block">
                                                  <div className="product-rating" ></div>
                                              </div>
                                              <span className="font-small ml-5 text-muted"> (4.0)</span>
                                          </div>
                                          <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                          </div>
                                          <div className="product-card-bottom">
                                              <div className="product-price">
                                                  <span>$28.85</span>
                                                  <span className="old-price">$32.8</span>
                                              </div>
                                              <div className="add-cart">
                                                  <Link className="add" to=""><i
                                                      className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                  <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                       data-wow-delay=".1s">
                                      <div className="product-img-action-wrap">
                                          <div className="product-img product-img-zoom">
                                              <Link to="">
                                                  <img className="default-img" src={product1}
                                                       alt=""/>
                                                  <img className="hover-img" src={product2}
                                                       alt=""/>
                                              </Link>
                                          </div>
                                          <div className="product-action-1">
                                              <Link aria-label="Add To Wishlist" className="action-btn"
                                                    to=""><i className="fi-rs-heart"></i></Link>
                                              <Link aria-label="Compare" className="action-btn" to=""><i
                                                  className="fi-rs-shuffle"></i></Link>
                                              <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                    data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                          </div>
                                          <div className="product-badges product-badges-position product-badges-mrg">
                                              <span className="hot">Hot</span>
                                          </div>
                                      </div>
                                      <div className="product-content-wrap">
                                          <div className="product-category">
                                              <Link to="">Snack</Link>
                                          </div>
                                          <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                              & Red Rice</Link></h2>
                                          <div className="product-rate-cover">
                                              <div className="product-rate d-inline-block">
                                                  <div className="product-rating" ></div>
                                              </div>
                                              <span className="font-small ml-5 text-muted"> (4.0)</span>
                                          </div>
                                          <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                          </div>
                                          <div className="product-card-bottom">
                                              <div className="product-price">
                                                  <span>$28.85</span>
                                                  <span className="old-price">$32.8</span>
                                              </div>
                                              <div className="add-cart">
                                                  <Link className="add" to=""><i
                                                      className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                      <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                           data-wow-delay=".1s">
                                          <div className="product-img-action-wrap">
                                              <div className="product-img product-img-zoom">
                                                  <Link to="">
                                                      <img className="default-img" src={product1}
                                                           alt=""/>
                                                      <img className="hover-img" src={product2}
                                                           alt=""/>
                                                  </Link>
                                              </div>
                                              <div className="product-action-1">
                                                  <Link aria-label="Add To Wishlist" className="action-btn"
                                                        to=""><i className="fi-rs-heart"></i></Link>
                                                  <Link aria-label="Compare" className="action-btn" to=""><i
                                                      className="fi-rs-shuffle"></i></Link>
                                                  <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                        data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                              </div>
                                              <div className="product-badges product-badges-position product-badges-mrg">
                                                  <span className="hot">Hot</span>
                                              </div>
                                          </div>
                                          <div className="product-content-wrap">
                                              <div className="product-category">
                                                  <Link to="">Snack</Link>
                                              </div>
                                              <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                  & Red Rice</Link></h2>
                                              <div className="product-rate-cover">
                                                  <div className="product-rate d-inline-block">
                                                      <div className="product-rating" ></div>
                                                  </div>
                                                  <span className="font-small ml-5 text-muted"> (4.0)</span>
                                              </div>
                                              <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                              </div>
                                              <div className="product-card-bottom">
                                                  <div className="product-price">
                                                      <span>$28.85</span>
                                                      <span className="old-price">$32.8</span>
                                                  </div>
                                                  <div className="add-cart">
                                                      <Link className="add" to=""><i
                                                          className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                      <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                           data-wow-delay=".1s">
                                          <div className="product-img-action-wrap">
                                              <div className="product-img product-img-zoom">
                                                  <Link to="">
                                                      <img className="default-img" src={product1}
                                                           alt=""/>
                                                      <img className="hover-img" src={product2}
                                                           alt=""/>
                                                  </Link>
                                              </div>
                                              <div className="product-action-1">
                                                  <Link aria-label="Add To Wishlist" className="action-btn"
                                                        to=""><i className="fi-rs-heart"></i></Link>
                                                  <Link aria-label="Compare" className="action-btn" to=""><i
                                                      className="fi-rs-shuffle"></i></Link>
                                                  <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                        data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                              </div>
                                              <div className="product-badges product-badges-position product-badges-mrg">
                                                  <span className="hot">Hot</span>
                                              </div>
                                          </div>
                                          <div className="product-content-wrap">
                                              <div className="product-category">
                                                  <Link to="">Snack</Link>
                                              </div>
                                              <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                  & Red Rice</Link></h2>
                                              <div className="product-rate-cover">
                                                  <div className="product-rate d-inline-block">
                                                      <div className="product-rating" ></div>
                                                  </div>
                                                  <span className="font-small ml-5 text-muted"> (4.0)</span>
                                              </div>
                                              <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                              </div>
                                              <div className="product-card-bottom">
                                                  <div className="product-price">
                                                      <span>$28.85</span>
                                                      <span className="old-price">$32.8</span>
                                                  </div>
                                                  <div className="add-cart">
                                                      <Link className="add" to=""><i
                                                          className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                            </div>
                        </div>
                      
                        <div className={setTabActive === 2 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab-two" role="tabpanel" aria-labelledby="tab-two">
                            <div className="row product-grid-4">
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={setTabActive === 3 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab-three" role="tabpanel" aria-labelledby="tab-three">
                            <div className="row product-grid-4">
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={setTabActive === 4 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab-one" role="tabpanel" aria-labelledby="tab-one">
                            <div className="row product-grid-4">
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={setTabActive === 5 ? 'tab-pane fade show active' : 'tab-pane fade'}  id="tab-five" role="tabpanel" aria-labelledby="tab-five">
                            <div className="row product-grid-4">
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product3}
                                                         alt=""/>
                                                    <img className="hover-img" src={product4}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        <div className={setTabActive === 6 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab-six" role="tabpanel" aria-labelledby="tab-six">
                            <div className="row product-grid-4">
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product5}
                                                         alt=""/>
                                                    <img className="hover-img" src={product6}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className={setTabActive === 7 ? 'tab-pane fade show active' : 'tab-pane fade'} id="tab-seven" role="tabpanel" aria-labelledby="tab-seven">
                            <div className="row product-grid-4">
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                                <span className="font-small text-muted">By
                                                    <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                            <span className="font-small text-muted">By
                                                <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div className="product-cart-wrap mb-30 wow animate__animated animate__fadeIn"
                                         data-wow-delay=".1s">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img" src={product1}
                                                         alt=""/>
                                                    <img className="hover-img" src={product2}
                                                         alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <Link aria-label="Add To Wishlist" className="action-btn"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn" to=""><i
                                                    className="fi-rs-shuffle"></i></Link>
                                                <Link aria-label="Quick view" className="action-btn" data-bs-toggle="modal"
                                                      data-bs-target="#quickViewModal"><i className="fi-rs-eye"></i></Link>
                                            </div>
                                            <div className="product-badges product-badges-position product-badges-mrg">
                                                <span className="hot">Hot</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Snack</Link>
                                            </div>
                                            <h2><Link to="">Seeds of Change Organic Quinoa, Brown,
                                                & Red Rice</Link></h2>
                                            <div className="product-rate-cover">
                                                <div className="product-rate d-inline-block">
                                                    <div className="product-rating" ></div>
                                                </div>
                                                <span className="font-small ml-5 text-muted"> (4.0)</span>
                                            </div>
                                            <div>
                                        <span className="font-small text-muted">By
                                            <Link to="">NestFood</Link></span>
                                            </div>
                                            <div className="product-card-bottom">
                                                <div className="product-price">
                                                    <span>$28.85</span>
                                                    <span className="old-price">$32.8</span>
                                                </div>
                                                <div className="add-cart">
                                                    <Link className="add" to=""><i
                                                        className="fi-rs-shopping-cart mr-5"></i>Add </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                 
                </div>
            </section>
        </div>
    );
}
export default HomeProducts
