import React from "react";
import Headline from "../../../../../../Components/common/Headline";

const ConfirmationStepContent = () => {
  return (
    <div className="mt-50">
      <Headline title="Booking Details" />

      <div className="row">
        <div className="col-xl-8">
          <div className="row">
            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Name
              </label>

              <h4>Rahat Hossain</h4>
            </div>
            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Mobile Number
              </label>

              <h4>0170123575</h4>
            </div>
            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Address
              </label>

              <h4>100 ft, Notun Bazar Dhaka</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Make
              </label>

              <h4>Toyota</h4>
            </div>
            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Model
              </label>

              <h4>Corolla</h4>
            </div>
            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Year
              </label>

              <h4>2021</h4>
            </div>

            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Preferred Date
              </label>

              <h4>10/05/2023</h4>
            </div>
            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Preferred Time
              </label>

              <h4>10:30 AM</h4>
            </div>
            <div className="col-xl-4 mb-30">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block fw-bolder mb-1"
              >
                Notes
              </label>

              <h4>Car is making Noise</h4>
            </div>
          </div>
        </div>
        <div className="col-xl-4 d-flex align-items-center justify-content-center flex-column border-start">
          <div className="text-center">
            <h5>Cost Per Day</h5>
            <h3 className="mb-2">6000 BDT</h3>
          </div>
          {/* <table class="table">
            <label
              for="exampleFormControlInput1"
              class="form-label d-block font-weight-bold"
            >
              Total Charges
            </label>

            <tbody>
              <tr>
                <td>Total Rate Charges</td>
                <td>4000</td>
              </tr>
              <tr>
                <td>Other Charges</td>
                <td>2000</td>
              </tr>
              <tr>
                <th scope="row">
                  <h6>Sub Total</h6>
                </th>
                <td className="fw-bold">
                  <h6>6000</h6>
                </td>
              </tr>
            </tbody>
          </table> */}
        </div>
      </div>
    </div>
  );
};

export default ConfirmationStepContent;
