import React from "react";
import BkashPayment from "../BkashPayment";
import CardPayment from "../CardPayment/CardPayment";

const PayNowStepper = () => {
  return (
    <div className="mt-50">
      <div className="row">
        <div className="col-xl-6">
          <BkashPayment />
        </div>
        <div className="col-xl-6">
          <CardPayment />
        </div>
      </div>
    </div>
  );
};

export default PayNowStepper;
