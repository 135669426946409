import React, { useEffect } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RiShoppingCartLine } from "react-icons/ri";
import axios from "../../Helper/Config";
import {apiImgUrl} from "../../Helper/BaseUrl";
import { useState } from 'react';

function CarService() {
    const [data, setData] = useState([])
    const options = {
        navText: ["Prev", "Next"],
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 4,
            },
            1280: {
                items: 4,
            },
            1366: {
                items: 5,
            },
        }
    };
    useEffect(() => {
        axios.get("/service/active/all")
            .then((res) => {
                setData(res.data.data.services.data)
            }).catch((err) => {

            })
    }, [])
    return (
        <div className='home_category_area'>
            <Container>
                <Row>
                    <Col>
                        <div className='setion____title'>
                            <h3> Our <span> Service </span> </h3>
                        </div>
                        {data.length > 0 ? <>
                            <OwlCarousel className='owl-theme'
                                loop
                                margin={25}
                                nav={false}
                                dots={false}
                                autoplay={false}
                                autoplayTimeout={5000}
                                {...options}>
                                {data.map((item, i) =>
                                    <div className='item' key={i}>
                                        <div className='single_parts_area'>
                                            <div className='image__body'>
                                                <Link to={`/services-product-details/${item.slug}`}>
                                                    <img className='image__body img-fit' src={apiImgUrl + item?.images[0]?.content_path} alt="" />
                                                </Link>
                                            </div>
                                            <div className='product__icon__'>
                                                {/* <button> <RiEyeFill /></button> */}
                                                {/* <button><RiHeartFill /> </button> */}
                                            </div>
                                            <div className='product-card__info' >
                                                <div className='product-card_sku' style={{paddingBottom: '6px'}}>
                                                    <span>Make :</span>   {item?.make.name}
                                                    <p style={{width: 'auto', float: 'right'}}> <span>Model :</span>   {item?.model.name} </p>
                                                </div>
                                                <div className='product-card__name'>
                                                    <Link to="/services-product"> {item?.name} </Link>
                                                </div>
                                            </div>
                                            <div className='product-card__footer'>
                                                <h3> ৳{item?.price} </h3>
                                                <Link to={`/services-product-details/${item.slug}`}> <RiShoppingCartLine className='svg__hover' /> </Link>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </OwlCarousel>
                        </> : <>  </>}
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CarService
