export const FEATURED_PRODUCTS_OPTION = {
    navText: ["Prev", "Next"],
    responsive: {
        0: {
            items: 2,
        },
        450: {
            items: 2,
        },
        600: {
            items: 2,
        },
        768: {
            items: 2,
        },
        1024: {
            items: 4,
        },
        1280: {
            items: 4,
        },
        1366: {
            items: 4,
        },
    }
}