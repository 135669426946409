import React from "react";

const ConfirmationStepContent = () => {
  return (
    <div className="mt-50">
      <div className="row">
        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            From which Date?
          </label>

          <h4>10/05/2023</h4>
        </div>
        <div className="col-xl-5 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Type of Mechanic
          </label>

          <h4>Automotive Mechanics</h4>
        </div>

        <div className="col-xl-9 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Notes
          </label>

          <h4>
            VVTi engine of toyota Corolla repair. Also car is making noise
          </h4>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationStepContent;
