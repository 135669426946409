import React from "react";
import { HeartIcon, SocialShareIcon } from "../../assets/icons/icons";
import { apiImgUrl } from "../../Helper/BaseUrl";
import { Link } from "react-router-dom";

const CompanyDetailsBar = ({ data, slug }) => {
  return (
    <div className="companyDetails border-bottom border-top px-4 p-20 mb-20">
      <div className=" d-flex align-items-center justify-content-between">
        {/* Left Side */}
        <div className="d-flex align-items-center gap-3 flex-wrap">
          <img
            className="default-img"
            src={apiImgUrl + data?.company_logo}
            alt=""
            height={50}
          />

          {slug ? (
            <Link
              //   className="hover-up btn btn-sm btn-rounded"
              to={`/services-center-product-details/${slug}`}
            >
              <h5> {data.company_name} </h5>
            </Link>
          ) : (
            <h5 className="">{data.company_name}</h5>
          )}

          <div className="d-flex align-items-center gap-2 cursor-pointer">
            <SocialShareIcon />
            Share
          </div>
          <div className="d-flex align-items-center gap-2 cursor-pointer">
            <HeartIcon />
            Favourite
          </div>
        </div>
        {/* Right Side */}
        <div>
          <div class="product-rate-cover">
            <div class="product-rate d-inline-block">
              <div class="product-rating"></div>
            </div>
            <span class="font-small ml-5 text-muted">50 Ratings</span>
            <span className="px-3">|</span>
            <a href="#">View Comments</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsBar;
