import React from "react";
import { Cascader, DatePicker, Form, Select, Slider } from "antd";
import CustomRadioButton from "../../../../../Components/common/CustomRadioButton";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Headline from "../../../../../Components/common/Headline";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

const BiddingStepContent = () => {
  const [addBooking, setAddBooking] = useState(false);

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleAddBooking = () => {
    setAddBooking((prevState) => !prevState);
  };

  let content = null;
  const renderStepContent = () => {
    return (
      <div class="row">
        <div className="col-xl-8 mb-20">
          <select
            class="form-select form-select-sm"
            aria-label="Default select example"
          >
            <option selected>Select Product</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>

          <span>
            If you can't find the product please{" "}
            <span
              className="text-brand text-decoration-underline cursor-pointer"
              onClick={handleAddBooking}
            >
              (Click Here)
            </span>
          </span>
        </div>
        <div className="col-xl-4 mb-20">
          <div class="input-group input-group-sm">
            <input
              type="number"
              class="form-control h-auto"
              aria-label="Amount (to the nearest dollar)"
              placeholder="0"
            />
            <span class="input-group-text">Qty.</span>
          </div>
        </div>

        <div className="col-xl-12">
          <table class="table">
            <thead class="table-light">
              <tr>
                <th scope="col">Sl No</th>
                <th scope="col">Product Name</th>
                <th scope="col">Qty.</th>
                <th scope="col" style={{ width: "10%" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Back Camera</td>
                <td>1</td>
                <td>
                  <span className="d-flex align-items-center justify-content-center gap-2 h-100">
                    <span className="d-flex align-items-center justify-content-center p-1 bg-danger rounded-circle cursor-pointer">
                      <ClearIcon sx={{ fontSize: 15, color: "#fff" }} />
                    </span>

                    <span className="d-flex align-items-center justify-content-center p-1 bg-grey-9 rounded-circle cursor-pointer">
                      <EditIcon sx={{ fontSize: 15 }} />
                    </span>
                  </span>
                </td>
              </tr>

              <tr>
                <th scope="row">2</th>
                <td>
                  Spoiler <br />
                  <strong>Brand:</strong> Toyota <br />
                  <strong>Model:</strong> Corolla
                </td>
                <td>1</td>
                <td>
                  <span className="d-flex align-items-center justify-content-center gap-2 h-100">
                    <span className="d-flex align-items-center justify-content-center p-1 bg-danger rounded-circle cursor-pointer">
                      <ClearIcon sx={{ fontSize: 15, color: "#fff" }} />
                    </span>

                    <span className="d-flex align-items-center justify-content-center p-1 bg-grey-9 rounded-circle cursor-pointer">
                      <EditIcon sx={{ fontSize: 15 }} />
                    </span>
                  </span>

                  {/* <button className="btn btn-primary btn-sm ms">
                </button> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-xl-12">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Notes
          </label>

          <div class="form-floating">
            <textarea
              class="form-control h-100 rounded-1"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
            ></textarea>
            <label for="floatingTextarea2">Comments</label>
          </div>
        </div>
      </div>
    );
  };

  const renderAddProduct = () => {
    return (
      <div>
        <label
          for="exampleFormControlInput1"
          class="form-label d-block font-weight-bold"
        >
          Product Name <span className="text-danger">*</span>
        </label>

        <div class="form-floating mb-20">
          <div class="input-group input-group-sm">
            <input
              type="text"
              class="form-control h-auto"
              aria-label="Amount (to the nearest dollar)"
              placeholder="Enter Product Name"
            />
          </div>
        </div>

        <label
          for="exampleFormControlInput1"
          class="form-label d-block font-weight-bold"
        >
          Add Product Image <span className="text-danger">*</span>
        </label>

        <img
          src="https://images.unsplash.com/photo-1519752594763-2633d8d4ea29?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
          alt=""
        />

        <div className="d-flex justify-content-end gap-3 my-4">
          <button className="btn bg-danger" onClick={handleAddBooking}>
            Cancel
          </button>
          <button className="btn bg-primary" onClick={handleAddBooking}>
            Add
          </button>
        </div>
      </div>
    );
  };

  addBooking ? (content = renderAddProduct()) : (content = renderStepContent());

  return (
    <div className="mt-50">
      <div className="row">
        <div className="col-xl-4">
          <div className="primary-sidebar">
            <div className="sidebar-widget price_range range p-3 mb-30">
              <Form /* form={form} */ layout="vertical">
                <Form.Item label="Category" tooltip="Select You Category">
                  <Select
                    className="w-100"
                    // onClick={(e) => categoryFunction(e)}
                    // onChange={(e) => categoryOnChangeFunction(e)}
                    allowClear
                    placeholder="Select your Category"
                  >
                    {/* {categoryList?.map((item, i) => (
                <Option key={i} value={`${item?.id}`}>
                  {item?.name}
                </Option>
              ))} */}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Sub Category"
                  tooltip="Select You Sub Category"
                >
                  <Select
                    mode="multiple"
                    className="w-100"
                    // onChange={(e) => subCategoryFunction(e)}
                    placeholder="Select your Category"
                  >
                    {/* {subCategoryList?.map((item, i) => (
                <Option key={i} value={`${item?.id}`}>
                  {item?.name}
                </Option>
              ))} */}
                  </Select>
                </Form.Item>
                <Form.Item label="Parts Type" tooltip="Select Parts Type">
                  <Select
                    className="w-100"
                    // onChange={(e) => partsTypeFunction(e)}
                    allowClear
                    placeholder="Select Parts type"
                  >
                    {/* {partsTypesList?.map((item, i) => (
                <Option key={i} value={`${item?.id}`}>
                  {item?.name}
                </Option>
              ))} */}
                  </Select>
                </Form.Item>
                <Form.Item label="Brand" tooltip="Select Brand">
                  <Select
                    className="w-100"
                    // onChange={(e) => makeListOnchangFunction(e)}
                    placeholder="Brand"
                    allowClear
                    // options={makeList}
                  />
                </Form.Item>
                <Form.Item label="Model" tooltip="Select Model">
                  <Select
                    className="w-100"
                    // onChange={(e) => modelOnChangFunction(e)}
                    placeholder="Model"
                    allowClear
                    // options={modelList}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <Headline title="Add Your Bidding Product" />

          {content}
        </div>
      </div>
    </div>
  );
};

export default BiddingStepContent;
