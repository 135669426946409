import React, {useState} from 'react';
import {Link} from "react-router-dom";

function PCBreadcrambs() {
    return (
        <div className="page-header breadcrumb-wrap">
            <div className="container">
                <div className="breadcrumb">
                    <Link to="/" rel="nofollow">
                        <i className="fi-rs-home mr-5"></i>Home
                    </Link>
                    <span></span> Parts & Accessories
                </div>
            </div>
        </div>
        // <div className="page-header mt-30 mb-50">
        //     <div className="container">
        //         <div className="archive-header-11">
        //             <div className="row align-items-center">
        //                 <div className="col-xl-3">
        //                     <h1 className="mb-15">Products</h1>
        //                     <div className="breadcrumb">
        //                         <Link to="index.html" rel="nofollow"><i className="fi-rs-home mr-5"></i>Home</Link>
        //                         <span></span> Shop <span></span> Products
        //                     </div>
        //                 </div>
        //
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}

export default PCBreadcrambs
