import React from "react";
import ProductTabNav from "./ProductTabNav";
import ProductTabContent from "./ProductTabContent";

const ProductInfo = ({ product }) => {
  return (
    <div className="product-info">
      <div className="tab-style3">
        <ProductTabNav />

        <ProductTabContent product={product} />
      </div>
    </div>
  );
};

export default ProductInfo;
