import React from "react";
import { Link } from "react-router-dom";

import CallIcon from "@mui/icons-material/Call";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaidIcon from "@mui/icons-material/Paid";
import ShareIcon from "@mui/icons-material/Share";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import GoogleMap from "../../../../Components/common/GoogleMap";

const DriverDetailsInfo = ({ product, setIsBooking, handleRef }) => {
  const { name, discount_price } = product;

  const handleClick = () => {
    setIsBooking(true);
    handleRef();
  };
  return (
    <div className="detail-info pr-30 pl-30">
      {/* <span className="stock-status out-stock"> Sale Off </span> */}
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="title-detail">{name}</h3>

        <span className="d-flex align-items-center ">
          <CallIcon
            sx={{
              fontSize: 22,
              color: "black",
            }}
            className="text-brand"
          />
          <h5> 0174121447</h5>
        </span>
      </div>

      <div className="d-flex align-items-center justify-content-between flex-wrap gap-4">
        <div className="product-detail-rating pb-0">
          <div className="product-rate-cover text-end">
            <div className="product-rate d-inline-block">
              <div className="product-rating"></div>
            </div>
            <Link to="#review" className="font-small ml-5">
              {" "}
              (32 reviews)
            </Link>
          </div>
        </div>

        <div className="d-flex align-items-center flex-wrap gap-4">
          <Link
            to="shop-grid-right.html"
            className="d-flex align-items-center gap-2"
          >
            <ShareIcon
              sx={{
                fontSize: 14,
              }}
              color="primary"
            />
            <p>Share</p>
          </Link>

          <Link
            to="shop-grid-right.html"
            className="d-flex align-items-center gap-2"
          >
            <FavoriteIcon
              sx={{
                fontSize: 14,
                color: "red",
              }}
            />
            <p>Favorite</p>
          </Link>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-xl-8">
          <h5 className="mb-2">Years of experience: 2</h5>
          <span className="d-flex align-items-center gap-2 mb-2">
            <TaskAltIcon
              sx={{
                fontSize: 18,
              }}
              className="text-brand"
            />
            <h6>Trips: 50</h6>
          </span>
          <div className="mb-2">
            <span className="d-flex align-items-center gap-2 mb-2">
              <LocationOnIcon
                sx={{
                  fontSize: 18,
                }}
                className="text-danger"
              />
              <h6>Distance: 0.3 km</h6>
            </span>

            <span className="d-flex gap-2 mb-2">
              <PaidIcon
                sx={{
                  fontSize: 18,
                }}
                className="text-brand-2"
              />
              <span>
                <h6>Per day: 1000 (Inside Dhaka)</h6>
                <h6>Per day: 1500 (Outside Dhaka)</h6>
              </span>
            </span>
          </div>

          {/* <div className="clearfix product-price-cover">
            <div className="product-price primary-color float-left">
              <span className="current-price text-brand">
                ${discount_price}
              </span>
            </div>
          </div> */}
        </div>

        <div className="col-xl-4">
          <GoogleMap />
        </div>
      </div>

      <div className="detail-extralink mb-50">
        <div className="product-extra-link2">
          <button
            type="submit"
            className="button button-add-to-cart"
            onClick={handleClick}
          >
            Book Now
          </button>
          <Link
            aria-label="Add To Wishlist"
            className="action-btn hover-up"
            to="shop-wishlist.html"
          >
            <i className="fi-rs-heart"></i>
          </Link>
          <Link
            aria-label="Compare"
            className="action-btn hover-up"
            to="shop-compare.html"
          >
            <i className="fi-rs-shuffle"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DriverDetailsInfo;
