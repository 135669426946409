import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { VscCallOutgoing } from "react-icons/vsc";
import { RiChatHistoryLine, RiUserLocationLine } from "react-icons/ri";
import Iframe from "react-iframe";
import sBanner from "../../../assets/images/sBanner.png";
import iCon from "../../../assets/images/iCon.png";
import CheckoutPage from "../../../Components/ServicesBookingFrom/CheckoutPage";
import axios from "../../../Helper/Config";
import { useParams } from "react-router-dom";
import { apiImgUrl } from "../../../Helper/BaseUrl";
import { Card } from "antd";
import { Image } from "antd";
import BreadCrumb from "../../../Components/common/BreadCrumb";
import BasicSlider from "../../../Components/common/BasicSlider";
import CompanyDetailsBar from "../../../Components/common/CompanyDetailsBar";
import ServiceRightSide from "../ServicesCenterProductDetails/ServicesRightSide/ServiceRightSide";
import Headline from "../../../Components/common/Headline";
function ServicesBookingReg() {
  const [data, setData] = useState([]);
  const { slug } = useParams();
  useEffect(() => {
    axios
      .get(`/service/center/list `)
      .then((res) => {
        const dataFound = res?.data?.data?.services_center?.data?.filter(
          (obj) => {
            return obj.slug === slug;
          }
        );
        setData(dataFound[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(data);
  return (
    <div>
      <BreadCrumb category="Shop" sub="Service" />

      <BasicSlider data={data} />

      <CompanyDetailsBar slug={slug} data={data} />

      <div className="container">
        {/* flex-row-reverse */}
        <div className="row">
          <div className="col-lg-4-5">
            {/* Main Content */}
            <div className="">
              <p className="mb-4">
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.{" "}
              </p>

              <Headline title="Services Registration From" />

              <p>
                Please fill up the following form to book your service
                appointment
              </p>

              <Card
                size="small"
                // title="Services Registration From"
                className="my-4"
              >
                <CheckoutPage />
              </Card>
            </div>
          </div>

          <div className="col-lg-1-5 primary-sidebar sticky-sidebar">
            <ServiceRightSide data={data} />
          </div>
        </div>
      </div>

      {/* <div className="s_p_Details">
                <img className="img-fit" src={sBanner} alt="images" />
                <Container>
                    <div className="s_p_Details_cntx">
                        <img className="img-fit" src={iCon} alt="images" />
                        <h4> Help on the road </h4>
                        <h5> {data?.phone}</h5>
                        <p> Find out more about please call </p>
                    </div>
                </Container>
            </div> */}
      {/* <div className="s_p_banner__bootom_area">
                <Container>
                    <div className="s_p_banner__bootom_box">
                        <Row className="justify-content-center">
                            <Col lg={3} className="d-flex align-items-stretch">
                                <div className="card">
                                    <div className="s_p_banner__bootom_single_box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="70"
                                            height="81"
                                            viewBox="0 0 70 81"
                                        >
                                            <path
                                                d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
                                                fill="#ed1d24"
                                            ></path>
                                        </svg>
                                        <RiChatHistoryLine className="s_p_icon" />
                                        <h3> Service Center Information </h3>
                                    </div>
                                    <div className="s_p_banner__bootom_single_box__details">
                                        <img
                                            src={apiImgUrl + data?.company_logo}
                                        />
                                        <h4 className="text-danger mb-2">
                                            {data?.company_name}{" "}
                                        </h4>
                                        <h6 className="mb-2">
                                            {data?.address}, {data?.thana?.name}
                                            , {data?.district?.name} ,{" "}
                                            {data?.division?.name},{" "}
                                            {data?.country?.name}{" "}
                                        </h6>
                                        <h5> {data?.phone} </h5>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className="d-flex align-items-stretch">
                                <div className="card">
                                    <div className="s_p_banner__bootom_single_box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="70"
                                            height="81"
                                            viewBox="0 0 70 81"
                                        >
                                            <path
                                                d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
                                                fill="#ed1d24"
                                            ></path>
                                        </svg>
                                        <RiUserLocationLine className="s_p_icon" />
                                        <h3> Location </h3>
                                    </div>
                                    <div className="s_p_banner__bootom_single_box__details">
                                        <Iframe
                                            url={`https://maps.google.com/maps?q=${data?.latitude},${data?.longitude}&output=embed`}
                                            width="100%"
                                            height="150px"
                                            id="myId"
                                            className="myClassname"
                                            display="initial"
                                            position="relative"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className="d-flex align-items-stretch">
                                <div className="card">
                                    <div className="s_p_banner__bootom_single_box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="70"
                                            height="81"
                                            viewBox="0 0 70 81"
                                        >
                                            <path
                                                d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
                                                fill="#ed1d24"
                                            ></path>
                                        </svg>
                                        <VscCallOutgoing className="s_p_icon" />
                                        <h3> 24hr Hotline </h3>
                                    </div>
                                    <div
                                        className="s_p_banner__bootom_single_box__details"
                                        style={{ textAlign: "center" }}
                                    >
                                        <h5 className="mb-2">Owner Info: </h5>
                                        <h5 className="mb-2">
                                            {" "}
                                            <strong>
                                                {data?.company_owner}
                                            </strong>{" "}
                                        </h5>
                                        <h6 className="mb-2">
                                            Email: {data?.email}{" "}
                                        </h6>
                                        <h5 className="mb-2">
                                            {" "}
                                            Mobile: {data?.phone}{" "}
                                        </h5>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} className="d-flex align-items-stretch">
                                <div className="card">
                                    <div className="s_p_banner__bootom_single_box">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="70"
                                            height="81"
                                            viewBox="0 0 70 81"
                                        >
                                            <path
                                                d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
                                                fill="#ed1d24"
                                            ></path>
                                        </svg>
                                        <VscCallOutgoing className="s_p_icon" />
                                        <h3> Gallery </h3>
                                    </div>
                                    <div
                                        className="p-4"
                                        style={{ textAlign: "center" }}
                                    >
                                        <Image
                                            width={80}
                                            height={80}
                                            style={{
                                                border: "1px solid #ededed",
                                            }}
                                            src={apiImgUrl + data?.company_logo}
                                        />
                                        <Image
                                            width={80}
                                            height={80}
                                            style={{
                                                border: "1px solid #ededed",
                                            }}
                                            src={apiImgUrl + data?.company_logo}
                                        />
                                        <Image
                                            width={80}
                                            height={80}
                                            style={{
                                                border: "1px solid #ededed",
                                            }}
                                            src={apiImgUrl + data?.company_logo}
                                        />
                                        <Image
                                            width={80}
                                            height={80}
                                            style={{
                                                border: "1px solid #ededed",
                                            }}
                                            src={apiImgUrl + data?.company_logo}
                                        />
                                        <Image
                                            width={80}
                                            height={80}
                                            style={{
                                                border: "1px solid #ededed",
                                            }}
                                            src={apiImgUrl + data?.company_logo}
                                        />
                                        <Image
                                            width={80}
                                            height={80}
                                            style={{
                                                border: "1px solid #ededed",
                                            }}
                                            src={apiImgUrl + data?.company_logo}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div> */}
      {/* <div className="s_p_d_left_box_area">
        <Container>
          <p className="mb-4">
            {" "}
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.{" "}
          </p>

          <Card
            size="small"
            title="Services Registration From"
            className="mb-4"
          >
            <CheckoutPage />
          </Card>
        </Container>
      </div> */}
    </div>
  );
}

export default ServicesBookingReg;
