import React from "react";
import { Link } from "react-router-dom";

const ProductBreadcrumb = () => {
  return (
    <div className="page-header breadcrumb-wrap">
      <div className="container">
        <div className="breadcrumb">
          <Link to="#" rel="nofollow">
            <i className="fi-rs-home mr-5"></i>Home
          </Link>
          <span></span> <Link to="#">Vegetables & tubers</Link> <span></span>{" "}
          Seeds of Change Organic
        </div>
      </div>
    </div>
  );
};

export default ProductBreadcrumb;
