import React from "react";
import { DatePicker } from "antd";

const BiddingStepContent = () => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div className="mt-50">
      <div class="row">
        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            From which Date?
          </label>

          <DatePicker
            className="mb-20"
            onChange={onChange}
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Type of Mechanic
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>Automotive Mechanic</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div className="col-xl-8">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Notes
          </label>

          <div class="form-floating">
            <textarea
              class="form-control h-100 rounded-1"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
            ></textarea>
            <label for="floatingTextarea2">Comments</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiddingStepContent;
