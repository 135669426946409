import React from "react";
import { Link } from "react-router-dom";

const DetailInfo = ({ product }) => {
  const {
    name,
    discount_price,
    selling_price,
    short_description,
    product_code,
    stock,
  } = product;

  return (
    <div className="detail-info pr-30 pl-30">
      <span className="stock-status out-stock"> Sale Off </span>
      <h3 className="title-detail">{name}</h3>
      <div className="product-detail-rating">
        <div className="product-rate-cover text-end">
          <div className="product-rate d-inline-block">
            <div className="product-rating"></div>
          </div>
          <span className="font-small ml-5 text-muted"> (32 reviews)</span>
        </div>
      </div>
      <div className="clearfix product-price-cover">
        <div className="product-price primary-color float-left">
          <span className="current-price text-brand">${discount_price}</span>
          <span>
            <span className="save-price font-md color3 ml-15">26% Off</span>
            <span className="old-price font-md ml-15">${selling_price}</span>
          </span>
        </div>
      </div>
      <div className="short-desc mb-30">
        <p className="font-lg">{short_description}</p>
      </div>
      <div className="attr-detail attr-size mb-30">
        <strong className="mr-10">Size / Weight: </strong>
        <ul className="list-filter size-filter font-small">
          <li>
            <Link to="#">50g</Link>
          </li>
          <li className="active">
            <Link to="#">60g</Link>
          </li>
          <li>
            <Link to="#">80g</Link>
          </li>
          <li>
            <Link to="#">100g</Link>
          </li>
          <li>
            <Link to="#">150g</Link>
          </li>
        </ul>
      </div>
      <div className="detail-extralink mb-50">
        <div className="detail-qty border radius">
          <Link href="#" className="qty-down">
            <i className="fi-rs-angle-small-down"></i>
          </Link>
          <input
            type="text"
            name="quantity"
            className="qty-val"
            value="1"
            min="1"
          />
          <Link to="#" className="qty-up">
            <i className="fi-rs-angle-small-up"></i>
          </Link>
        </div>
        <div className="product-extra-link2">
          <button type="submit" className="button button-add-to-cart">
            <i className="fi-rs-shopping-cart"></i>Add to cart
          </button>
          <Link
            aria-label="Add To Wishlist"
            className="action-btn hover-up"
            to="shop-wishlist.html"
          >
            <i className="fi-rs-heart"></i>
          </Link>
          <Link
            aria-label="Compare"
            className="action-btn hover-up"
            to="shop-compare.html"
          >
            <i className="fi-rs-shuffle"></i>
          </Link>
        </div>
      </div>
      <div className="font-xs">
        <ul className="mr-50 float-start">
          <li className="mb-5">
            Type: <span className="text-brand">Organic</span>
          </li>
          <li className="mb-5">
            MFG:<span className="text-brand"> Jun 4.2022</span>
          </li>
          <li>
            LIFE: <span className="text-brand">70 days</span>
          </li>
        </ul>
        <ul className="float-start">
          <li className="mb-5">
            SKU: <Link to="#">{product_code}</Link>
          </li>
          <li className="mb-5">
            Tags:{" "}
            <Link to="#" rel="tag">
              Snack
            </Link>
            ,
            <Link to="#" rel="tag">
              Organic
            </Link>
            ,
            <Link to="#" rel="tag">
              Brown
            </Link>
          </li>
          <li>
            Stock:
            <span className="in-stock text-brand ml-5">
              {stock > 1 ? `${stock} Items` : `${stock} Item`} In Stock
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DetailInfo;
