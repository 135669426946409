import React,{ useEffect, useState }  from 'react'
import { Input, Form } from 'antd';
import { Link } from 'react-router-dom'
import Figure78 from "../assets/images/figure78.png"
import Figure79 from "../assets/images/figure79.png"
import axios from "../Helper/Config";

function ContactUs() {
    const [form] = Form.useForm();
    const { TextArea } = Input;
const [singleData, setAllData] = useState([]);

    const onFinish = (value) => {
        console.log('Success:', value);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        axios.get(`/common/company/contactus`)
          .then((res) => {
            const temp = { ...res?.data?.data?.contactUs[0], qnty: 1 }
            setAllData(temp)

          }).catch((err) => {
            console.log(err)
          })
      }, [])

    return (
        <div>
            <section className="breadcrumb-wrap">
                {/* <div className="breadcrumb-img1">
                    <img src={Figure79} alt="figure" width="223" height="109" />
                </div> */}
                {/* <div className="breadcrumb-img2">
                    <img src={Figure78} alt="figure" width="185" height="56" />
                </div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="breadcrumb-box">
                            <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"> Home </Link>/
                                        {singleData?.contact_us_banner_title}</li>
                                    </ol>
                                </nav>
                                <h1 className="page-title"> {singleData?.contact_us_banner_title}</h1>
                                {/* <nav>
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/"> Home </Link>/
                                        {singleData?.contact_us_banner_title}</li>
                                    </ol>
                                </nav> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className='about__contant about__contant__txt'>

                            <h1>  Get In <span className="heading-color"> Touch </span> </h1>
                            {/* <h1> {singleData?.contact_us_title}  -Get In <span className="heading-color"> Touch </span> </h1> */}

                            <p style={{ paddingTop: "15px" }}> {singleData?.contact_us_description}
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className='about__contant'>
                            <Form
                                name="basic"
                                layout="vertical"
                                className='from_home_area'
                                form={form}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Item
                                            label="Your Name"
                                            name="yName"
                                            className="form-group form-box"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Name!',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Your Name' className="form-control" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            className="form-group form-box"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Email' className="form-control" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Item
                                            label="Contact Number"
                                            name="contactnumber"
                                            className="form-group form-box"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Contact Number',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Contact Number' className="form-control" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Item
                                            label="Subject"
                                            name="subject"
                                            className="form-group form-box"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Subject',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Subject' className="form-control" />
                                        </Form.Item>
                                    </div>
                                    <div className='col-lg-12'>
                                        <Form.Item
                                            label="Message"
                                            name="message"
                                            className="form-group form-box"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your Message',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} placeholder="Message"/>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn-md contact__btn btn-theme">Submit</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <img src={HeaderBg} alt="" /> */}
        </div>
    )
}

export default ContactUs