import React from "react";
import ProductOne from "../../../assets/imgs/shop/product-1-1.jpg";
import ProductTwo from "../../../assets/imgs/shop/product-2-1.jpg";
import ProductThree from "../../../assets/imgs/shop/product-3-1.jpg";
import CartCoupon from "./CartCoupon";
import CartTotal from "./CartTotal";
import SummaryHeader from "./SummaryHeader";
import SummaryList from "./SummaryList";

const ShoppingSummary = () => {
  return (
    <div class="table-responsive shopping-summery">
      <table class="table table-wishlist">
        <SummaryHeader />

        <SummaryList />
      </table>
    </div>
  );
};

export default ShoppingSummary;
