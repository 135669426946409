import React from "react";
import { DatePicker } from "antd";

const BiddingStepContent = () => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div className="mt-50">
      <div class="row">
        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            From which Date?
          </label>

          <DatePicker
            className="mb-20"
            onChange={onChange}
            style={{ width: "100%" }}
          />
        </div>

        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Time Of Journey
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>10.00 A.M</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            For how many days?
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>5</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Vehicle Type
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>SUV</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Trip Type
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>In City</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            For Which Location?
          </label>

          <div className="d-flex gap-2">
            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>Dhaka</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
            -
            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>No fix route</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>

        <div className="col-xl-12">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Notes
          </label>

          <div class="form-floating">
            <textarea
              class="form-control h-100"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
            ></textarea>
            <label for="floatingTextarea2">Comments</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiddingStepContent;
