import React from "react";
import ProductGallery from "../../ProductDetails/ProductGallery";
import RentCarProfileInfo from "./RentCarProfileInfo";
import RentCarProfileTab from "./RentCarProfileTab/RentCarProfileTab";

const dataImg = [
  {
    image:"https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"

  },
  {
    image:
      "https://images.unsplash.com/photo-1473655587843-eda8944061e8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80",
  },
];

const driverData = {
  id: 50,
  name: "Toyota, Corolla",
  discount_price: 1000,
};

const RentCarProfile = () => {

  return (
    <div className="container mb-30 mt-30">
      <div className="row">
        {/* col-lg-4-5 col-xl-10 col-lg-12 m-auto */}
        <div className="col-xl-12">
          <div className="product-detail accordion-detail">
            <div className="row mb-50 mt-30">
              <div className="col-md-4 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                <ProductGallery dataImg={dataImg} />
              </div>
              <div className="col-md-8 col-sm-12 col-xs-12">
                <RentCarProfileInfo
                  product={driverData}
                />
              </div>
            </div>

            <RentCarProfileTab product={driverData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentCarProfile;
