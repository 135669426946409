import React, { useState } from "react";
import HomeSlider from "../../Components/Slider/HomeSlider";
import CarPartsBiddingList from "./BiddingListHome/CarPartsBiddingList";
import VehicleBiddingList from "./BiddingListHome/VehicleBiddingList";
import MechanicBiddingList from "./BiddingListHome/MechanicBiddingList";
import DriverBiddingList from "./BiddingListHome/DriverBiddingList";
import BiddingCategory from "./BiddingListHome/BiddingCategory";

function UserBiddingListNew() {
  return (
    <div className=" mb-50">
      <HomeSlider />

      <BiddingCategory />

      <CarPartsBiddingList />
      <VehicleBiddingList />
      <MechanicBiddingList />
      <DriverBiddingList />
    </div>
  );
}
export default UserBiddingListNew;
