import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
    VscRemoteExplorer,
    VscArrowRight,
    VscCallOutgoing,
} from "react-icons/vsc";
import {
    RiChatHistoryLine,
    RiMapPin2Line,
    RiUser3Line,
    RiUserLocationLine,
} from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Iframe from "react-iframe";
import axios from "../../../Helper/Config";
import { apiImgUrl } from "../../../Helper/BaseUrl";
import sBanner from "../../../assets/images/sBanner.png";
import iCon from "../../../assets/images/iCon.png";
import ServicesSingleProductDetails from "../../../Components/Products/ServicesSingleProductDetails";
import ServicesProductSpecialOffers from "../../../Components/Products/ServicesProductSpecialOffers";
import Gallery from "../../../Components/Products/Gallery";
import NoImg from "../../../assets/images/noImg.png";
import svg from "../../../assets/imgs/theme/icons/icon-contact.svg";
import svgEmail from "../../../assets/imgs/theme/icons/icon-email-2.svg";
import svgRegNo from "../../../assets/imgs/theme/icons/icon-1.svg";
import svgCart from "../../../assets/imgs/theme/icons/icon-cart.svg";
import BreadCrumb from "../../../Components/common/BreadCrumb";
import ServiceList from "./ServiceList";
import ServiceRightSide from "./ServicesRightSide/ServiceRightSide";
import { HeartIcon, SocialShareIcon } from "../../../assets/icons/icons";
import SpecialOffer from "./SpecialOffer";
import BasicSlider from "../../../Components/common/BasicSlider";
import CompanyDetailsBar from "../../../Components/common/CompanyDetailsBar";
const DUMMY_SERVICE = [
    {
        name: "Car Service - 1",
        image: "https://images.unsplash.com/photo-1498887960847-2a5e46312788?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
    },
];

function ServicesCenterProductDetails() {
    const { slug } = useParams();
    const [alldata, setAllData] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        axios
            .get(`/service/center/wise/service/${slug}`)
            .then((res) => {
                setAllData(res?.data?.data?.services);
            })
            .catch((err) => {
                console.log(err);
            });
        axios
            .get(`/service/center/list`)
            .then((res) => {
                const dataFound =
                    res?.data?.data?.services_center?.data?.filter((obj) => {
                        return obj.slug === slug;
                    });
                setData(dataFound[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    // console.log(alldata, "data data");
    // console.log(data, "data data");
    return (
        <>
            <BreadCrumb category="Shop" sub="Service" />

            {/* <div className="sc-slider">
                {data?.banners?.length > 0 ? (
                    <>
                        <Carousel className="">
                            {data?.banners?.map((item, i) => (
                                <Carousel.Item key={i}>
                                    <img
                                        className="d-block w-100 img-fit img__height"
                                        src={apiImgUrl + item?.image_path}
                                        alt="First slide"
                                        style={{ maxHeight: 400 }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </>
                ) : (
                    <>
                        <img
                            className="no_img__banner"
                            src={NoImg}
                            alt="Images"
                        />
                    </>
                )}
            </div> */}

            <BasicSlider data={data} />

            <CompanyDetailsBar data={data} />

            <div className="container">
                {/* flex-row-reverse */}
                <div className="row">
                    <div className="col-lg-4-5">
                        {/* Main Content */}
                        <div className="">
                            <div>
                                <ServiceList />
                            </div>

                            <div>
                                <SpecialOffer />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-1-5 primary-sidebar sticky-sidebar">
                        <ServiceRightSide data={data} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ServicesCenterProductDetails;

{
    /* <Col lg={12}>
<div className="row">
  {alldata?.data?.map((item) => (
    <div className="col-lg-3-3 col-md-6 col-12 col-sm-6 justify-content-center">
      <div className="vendor-wrap mb-40">
        <div className="vendor-img-action-wrap">
          <div className="vendor-img">
            <Link
              to={`/services-center-product-details/${item?.slug}`}
            >
              <img
                className="default-img"
                src={apiImgUrl + item?.company_logo}
                alt=""
              />
              <img
                className="hover-img"
                src={apiImgUrl + item?.images[0].content_path}
                alt=""
              />
            </Link>
          </div>
          <div className="product-badges product-badges-position product-badges-mrg">
            <span className="hot">Service</span>
          </div>
        </div>
        <div className="vendor-content-wrap">
          <div className="d-flex justify-content-between align-items-end mb-30">
            <div>
              <div className="product-category">
                <span className="text-muted">
                  <strong>Owner :</strong> {item?.company_owner}
                </span>
              </div>
              <h4 className="mb-5">
                <Link
                  to={`/services-center-product-details/${item?.slug}`}
                >
                  {item?.company_name}
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))}
</div>
</Col>

<div className="s_p_banner__bootom_area">
<Container>
  <div className="s_p_banner__bootom_box">
    <Row>
      <Col lg={3} className="d-flex align-items-stretch">
        <div className="card">
          <div className="s_p_banner__bootom_single_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="81"
              viewBox="0 0 70 81"
            >
              <path
                d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
                fill="#ed1d24"
              ></path>
            </svg>
            <RiChatHistoryLine className="s_p_icon" />
            <h3> Service Center Information </h3>
          </div>
          <div className="s_p_banner__bootom_single_box__details">
            <img src={apiImgUrl + data?.company_logo} />
            <h4 className="text-danger mb-2">
              {data?.company_name}{" "}
            </h4>
            <h6 className="mb-2">
              {data?.address}, {data?.thana?.name},{" "}
              {data?.district?.name} , {data?.division?.name},{" "}
              {data?.country?.name}{" "}
            </h6>
            <h5> {data?.phone} </h5>
          </div>
        </div>
      </Col>
      <Col lg={3} className="d-flex align-items-stretch">
        <div className="card">
          <div className="s_p_banner__bootom_single_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="81"
              viewBox="0 0 70 81"
            >
              <path
                d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
                fill="#ed1d24"
              ></path>
            </svg>
            <RiUserLocationLine className="s_p_icon" />
            <h3> Location </h3>
          </div>
          <div className="s_p_banner__bootom_single_box__details">
            <Iframe
              url={`https://maps.google.com/maps?q=${data?.latitude},${data?.longitude}&output=embed`}
              width="100%"
              height="150px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
            />
          </div>
        </div>
      </Col>
      <Col lg={3} className="d-flex align-items-stretch">
        <div className="card">
          <div className="s_p_banner__bootom_single_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="81"
              viewBox="0 0 70 81"
            >
              <path
                d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
                fill="#ed1d24"
              ></path>
            </svg>
            <VscCallOutgoing className="s_p_icon" />
            <h3> 24hr Hotline </h3>
          </div>
          <div
            className="s_p_banner__bootom_single_box__details"
            style={{ textAlign: "center" }}
          >
            <h5 className="mb-2">Owner Info: </h5>
            <h5 className="mb-2">
              {" "}
              <strong>{data?.company_owner}</strong>{" "}
            </h5>
            <h6 className="mb-2">Email: {data?.email} </h6>
            <h5 className="mb-2"> Mobile: {data?.phone} </h5>
          </div>
        </div>
      </Col>
      <Col lg={3} className="d-flex align-items-stretch">
        <div className="card">
          <div className="s_p_banner__bootom_single_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="81"
              viewBox="0 0 70 81"
            >
              <path
                d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
                fill="#ed1d24"
              ></path>
            </svg>
            <VscRemoteExplorer className="s_p_icon" />
            <h3> Book Schedule </h3>
          </div>
          <div className="s_p_banner__bootom_single_box__details">
            <p>
              {" "}
              Take it easy driving– the life you save may be mine.{" "}
            </p>
            <div className="button__s_p">
              <Link
                className="bookShidul_posihion"
                to={`/services-booking/${slug}`}
              >
                <VscArrowRight />
                <span> Book Service Schedule </span>
              </Link>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>
</Container>
</div>
<div className="s_p_d_left_box_area">
<Container>
  <p>
    {" "}
    <div
      dangerouslySetInnerHTML={{ __html: data?.description }}
    ></div>{" "}
  </p>
</Container>
</div>
<div className="s_p_d_left_box_area">
<Container>
  <ServicesSingleProductDetails allData={alldata?.data} />
</Container>
</div>
<div className="s_p_d_left_box_area s_p_special_left_box_area ">
<Container>
  <Row>
    <Col lg={8}>
      <ServicesProductSpecialOffers />
    </Col>
    {data?.galleries?.length > 0 ? (
      <>
        <Col lg={4}>
          <Gallery imagesData={data.galleries} />
        </Col>
      </>
    ) : (
      <> </>
    )}
  </Row>
</Container>
</div> */
}
