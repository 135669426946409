import React from "react";
import { DatePicker } from "antd";
import Headline from "../../../../../../Components/common/Headline";

const BookingStepContent = () => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div className="mt-50">
      <Headline
        title="Booking Details"
        endItem={<button className="btn btn-google">Check Availability</button>}
      />
      {/* d-flex align-items-center gap-3 */}
      <div class="row">
        <div className="col-xl-2">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            From Date
          </label>

          <DatePicker
            className="mb-20"
            onChange={onChange}
            style={{ width: 200 }}
          />
        </div>

        <div className="col-xl-3 offset-xl-1">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            For
          </label>

          <div className="d-flex gap-2">
            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>5</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>

            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>Days</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>

        <div className="col-xl-3 offset-xl-1">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Trip Type
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>In City</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-5">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold"
              >
                Route (From - To)
              </label>

              <select
                class="form-select form-select-sm mb-20"
                aria-label="Default select example"
              >
                <option selected>Dhaka</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
            <div className="col-xl-2">
              <div className="d-flex align-items-center justify-content-center h-100">
                <span>To</span>
              </div>
            </div>
            <div className="col-xl-5">
              <label
                for="exampleFormControlInput1"
                class="form-label d-block font-weight-bold invisible"
              >
                Time
              </label>

              <select
                class="form-select form-select-sm mb-20"
                aria-label="Default select example"
              >
                <option selected>Chittagong</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-xl-3 offset-xl-1">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Time Of Journey
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>10.00 A.M</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Vehicle Type
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>SUV</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div className="col-xl-3">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Transmission Type
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>Automatic</option>
            <option value="1">Manual</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div className="col-xl-4"></div>
        <div className="col-xl-6">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Notes
          </label>

          <div class="form-floating">
            <textarea
              class="form-control h-100"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
            ></textarea>
            <label for="floatingTextarea2">Comments</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingStepContent;
