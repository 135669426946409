import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../../Components/common/CustomPagination";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import CustomModal from "../../../Components/common/modal/CustomModal";
import InputWithLabel from "../../../Components/common/input/InputWithLabel";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";

const MyComplain = () => {
  const [modal, setModal] = useState(false);

  return (
    <div
      class="tab-pane fade"
      id="complain"
      role="tabpanel"
      aria-labelledby="complain-tab"
    >
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h3 class="mb-0">My Complain List</h3>
          <span>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control form-control-sm h-auto  rounded-1"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon2"
              />
              <span class="input-group-text" id="basic-addon2">
                <SearchIcon />
              </span>
            </div>
          </span>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Title</th>
                  <th>Complain To</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>12/3/2022</td>
                  <td>Regarding Service</td>
                  <td>Rahim Service Center</td>
                  <td>
                    <span className="badge rounded-pill bg-brand">Request</span>
                  </td>
                  <td>
                    <span
                      className="d-flex align-items-center gap-2 cursor-pointer"
                      onClick={() => setModal(true)}
                    >
                      <TextsmsOutlinedIcon sx={{ fontSize: 16 }} />
                      {/* <a href="#" class="btn-small d-block">
                        View
                      </a> */}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>12/3/2022</td>
                  <td>Regarding Service</td>
                  <td>Rahim Service Center</td>
                  <td>
                    <span className="badge rounded-pill bg-brand">Request</span>
                  </td>
                  <td>
                    <span
                      className="d-flex align-items-center gap-2 cursor-pointer"
                      onClick={() => setModal(true)}
                    >
                      <TextsmsOutlinedIcon sx={{ fontSize: 16 }} />
                      {/* <a href="#" class="btn-small d-block">
                        View
                      </a> */}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>12/3/2022</td>
                  <td>Regarding Service</td>
                  <td>Rahim Service Center</td>
                  <td>
                    <span className="badge rounded-pill bg-brand">Request</span>
                  </td>
                  <td>
                    <span
                      className="d-flex align-items-center gap-2 cursor-pointer"
                      onClick={() => setModal(true)}
                    >
                      <TextsmsOutlinedIcon sx={{ fontSize: 16 }} />
                      {/* <a href="#" class="btn-small d-block">
                        View
                      </a> */}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>12/3/2022</td>
                  <td>Regarding Service</td>
                  <td>Rahim Service Center</td>
                  <td>
                    <span className="badge rounded-pill bg-brand">Request</span>
                  </td>
                  <td>
                    <span
                      className="d-flex align-items-center gap-2 cursor-pointer"
                      onClick={() => setModal(true)}
                    >
                      <TextsmsOutlinedIcon sx={{ fontSize: 16 }} />
                      {/* <a href="#" class="btn-small d-block">
                        View
                      </a> */}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>12/3/2022</td>
                  <td>Regarding Service</td>
                  <td>Rahim Service Center</td>
                  <td>
                    <span className="badge rounded-pill bg-brand">Request</span>
                  </td>
                  <td>
                    <span
                      className="d-flex align-items-center gap-2 cursor-pointer"
                      onClick={() => setModal(true)}
                    >
                      <TextsmsOutlinedIcon sx={{ fontSize: 16 }} />
                      {/* <a href="#" class="btn-small d-block">
                        View
                      </a> */}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <CustomPagination placement="center" />

          {/* Modal */}
          <CustomModal
            size="lg"
            footer={true}
            onHide={() => setModal(false)}
            show={modal}
            modalTitle={"Complain Request"}
          >
            <div className="row">
              <div className="col-xl-6">
                {" "}
                <p>Complain To</p>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control form-control-sm h-auto  rounded-1"
                    placeholder="Administrator"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <span class="input-group-text" id="basic-addon2">
                    <SearchIcon />
                  </span>
                </div>
              </div>

              <div className="col-xl-6">
                <InputWithLabel
                  label="Complain For"
                  defaultOption="Service Quality"
                  component="select"
                />
              </div>
            </div>

            <label
              for="exampleFormControlInput1"
              class="form-label d-block font-weight-bold"
            >
              Notes
            </label>

            <div class="form-floating">
              <textarea
                class="form-control h-100 rounded-1"
                placeholder="Leave a comment here"
                id="floatingTextarea2"
              ></textarea>
              <label for="floatingTextarea2">Notes (Optional)</label>
            </div>

            <h5 className="mt-20 mb-10">Attachment</h5>
            <div className="row">
              <div className="col-xl-3">
                <div className="rounded-3 text-center border border-3 border-dashed cursor-pointer mb-3 py-2">
                  <CameraAltIcon sx={{ fontSize: 25, color: "black" }} />
                  <h6>Web Cam</h6>
                </div>
              </div>

              <div className="col-xl-3">
                <div className=" rounded-3 border border-2 cursor-pointer pt-20 pb-20">
                  <div className="d-flex gap-2 justify-content-center align-items-center">
                    <AttachFileOutlinedIcon
                      sx={{ fontSize: 25, color: "black" }}
                    />
                    <h6>From File</h6>
                  </div>
                </div>
              </div>
            </div>
          </CustomModal>
        </div>
      </div>
    </div>
  );
};

export default MyComplain;
