import React from "react";
import CartCoupon from "./CartCoupon";
import CartTotal from "./CartTotal";
import ShoppingSummary from "./ShoppingSummary";

const CartBody = () => {
  return (
    <div class="row">
      <div class="col-lg-12">
        <ShoppingSummary />

        <div class="row mt-50">
          {/* Coupon */}
          <CartCoupon />

          {/* Cart Total */}
          <CartTotal />
        </div>
      </div>
    </div>
  );
};

export default CartBody;
