import React from "react";
import { DatePicker } from "antd";
import CustomRadioButton from "../../../../../Components/common/CustomRadioButton";

const BiddingStepContent = () => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div className="mt-50">
      <CustomRadioButton />

      <div class="row mt-10">
        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Vehicle Type
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>Heavy</option>
            <option value="1">One</option>
          </select>
        </div>
        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Select Vehicle
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>Mini Bus</option>
            <option value="1">One</option>
          </select>
        </div>
        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Pick Date
          </label>

          <DatePicker
            className="mb-20"
            onChange={onChange}
            style={{ width: "100%" }}
          />
        </div>

        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Time
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>10:00AM</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>
        <div className="col-xl-4">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            For how many days?
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>5</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div className="col-xl-8">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            For which location?
          </label>
          <div className="d-md-flex gap-2">
            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>From</option>
              <option value="1">One</option>
            </select>

            <span className="d-none d-sm-block d-md-block">-</span>

            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>To</option>
              <option value="1">One</option>
            </select>
          </div>
        </div>

        <div className="col-xl-8">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Notes
          </label>

          <div class="form-floating">
            <textarea
              class="form-control h-100 rounded-1"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
            ></textarea>
            <label for="floatingTextarea2">Comments</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiddingStepContent;
