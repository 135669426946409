import React from "react";
import { Link } from "react-router-dom";
import { apiImgUrl } from "../../../Helper/BaseUrl";

const PartsInventoryItem = ({ part }) => {
  const {
    name,
    all_image,
    discount_price,
    selling_price,
    parts_seller,
    thumbnail,
    slug,
  } = part || {};

  const renderImage = () => {
    if (all_image.length > 0)
      all_image?.map((item, index) => {
        if (index === 0) {
          return (
            <img
              key={item.id}
              className="default-img"
              src={apiImgUrl + item.image}
              alt=""
            />
          );
        }
        if (index === 1) {
          return (
            <img
              key={item.id}
              className="hover-img"
              src={apiImgUrl + item.image}
              alt=""
            />
          );
        }
      });
  };
  return (
    <div className="product-cart-wrap">
      <div className="product-img-action-wrap">
        <div className="product-img product-img-zoom">
          <Link to={`/product-details/${slug}`}>
            <img
              className="default-img"
              //   src={apiImgUrl + all_image[0]?.image}
              src={apiImgUrl + thumbnail}
              alt={name}
            />
            {/* <img className="default-img" src={product1} alt="" />
            <img className="hover-img" src={product2} alt="" /> */}
          </Link>
        </div>
        <div className="product-action-1">
          <button
            aria-label="Quick view"
            className="action-btn small hover-up"
            data-bs-toggle="modal"
            data-bs-target="#quickViewModal"
          >
            {" "}
            <i className="fi-rs-eye"></i>
          </button>
          <Link
            aria-label="Add To Wishlist"
            className="action-btn small hover-up"
            to={`/product-details/${slug}`}
          >
            <i className="fi-rs-heart"></i>
          </Link>
          <Link
            aria-label="Compare"
            className="action-btn small hover-up"
            to={`/product-details/${slug}`}
          >
            <i className="fi-rs-shuffle"></i>
          </Link>
        </div>
        <div className="product-badges product-badges-position product-badges-mrg">
          <span className="new">Save 35%</span>
        </div>
      </div>
      <div className="product-content-wrap">
        <div className="product-category">
          <Link to={`/parts-seller-details/${parts_seller?.slug}`}>
            {parts_seller !== null || undefined
              ? parts_seller.shop_name
              : "Parts Seller"}
          </Link>
        </div>
        <h2>
          <Link to={`/product-details/${slug}`}>{name}</Link>
        </h2>
        <div className="product-rate d-inline-block">
          <div className="product-rating"></div>
        </div>
        <div className="product-price mt-10">
          <span>${discount_price} </span>
          <span className="old-price">${selling_price}</span>
        </div>
        <div className="sold mt-15 mb-15">
          <div className="progress mb-5">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <span className="font-xs text-heading"> Sold: 90/120</span>
        </div>
        <Link to="" className="btn w-100 hover-up">
          <i className="fi-rs-shopping-cart mr-5"></i>Add To Cart
        </Link>
      </div>
    </div>
  );
};

export default PartsInventoryItem;
