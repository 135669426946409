import React from "react";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const MyAccountNavList = () => {
  return (
    <div class="dashboard-menu">
      <ul class="nav flex-column" role="tablist">
        {/* Dashboard */}
        <li class="nav-item">
          <a
            class="nav-link active"
            id="dashboard-tab"
            data-bs-toggle="tab"
            href="#dashboard"
            role="tab"
            aria-controls="dashboard"
            aria-selected="false"
          >
            <i class="fi-rs-settings-sliders mr-10"></i>Dashboard
          </a>
        </li>

        {/* Dashboard */}
        <li class="nav-item">
          <a
            class="nav-link"
            id="account-detail-tab"
            data-bs-toggle="tab"
            href="#account-detail"
            role="tab"
            aria-controls="account-detail"
            aria-selected="true"
          >
            <i class="fi-rs-user mr-10"></i>Account details
          </a>
        </li>

        {/* Orders */}
        <li class="nav-item">
          <a
            class="nav-link"
            id="orders-tab"
            data-bs-toggle="tab"
            href="#orders"
            role="tab"
            aria-controls="orders"
            aria-selected="false"
          >
            <i class="fi-rs-shopping-bag mr-10"></i>My Booking
          </a>
        </li>

        {/* My Favorite */}
        <li class="nav-item">
          <a
            class="nav-link"
            id="favorite-tab"
            data-bs-toggle="tab"
            href="#favorite"
            role="tab"
            aria-controls="favorite"
            aria-selected="false"
          >
            <BookmarkIcon sx={{ mr: 1 }} className="text-grey-4" />
            My Favorite
          </a>
        </li>

        {/* My enquiry */}
        <li class="nav-item">
          <a
            class="nav-link"
            id="enquiry-tab"
            data-bs-toggle="tab"
            href="#enquiry"
            role="tab"
            aria-controls="enquiry"
            aria-selected="false"
          >
            <HelpOutlineIcon sx={{ mr: 1 }} className="text-grey-4" />
            My Enquiry
          </a>
        </li>

        {/* My Complain */}
        <li class="nav-item">
          <a
            class="nav-link"
            id="complain-tab"
            data-bs-toggle="tab"
            href="#complain"
            role="tab"
            aria-controls="complain"
            aria-selected="false"
          >
            <LabelOutlinedIcon sx={{ mr: 1 }} className="text-grey-4" />
            My Complain
          </a>
        </li>

        {/* Track Your Order */}
        <li class="nav-item">
          <a
            class="nav-link"
            id="track-orders-tab"
            data-bs-toggle="tab"
            href="#track-orders"
            role="tab"
            aria-controls="track-orders"
            aria-selected="false"
          >
            <i class="fi-rs-shopping-cart-check mr-10"></i>Track Your Order
          </a>
        </li>

        {/* My Address */}
        <li class="nav-item">
          <a
            class="nav-link"
            id="address-tab"
            data-bs-toggle="tab"
            href="#address"
            role="tab"
            aria-controls="address"
            aria-selected="true"
          >
            <i class="fi-rs-marker mr-10"></i>
            My Address
          </a>
        </li>

        {/* Logout */}
        <li class="nav-item">
          <a class="nav-link" href="page-login.html">
            <i class="fi-rs-sign-out mr-10"></i>Logout
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MyAccountNavList;
