import React from "react";
import CompareLayout from "./CompareLayout";
import CallIcon from "@mui/icons-material/Call";

const DriverCompare = () => {
  const images = [
    "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    "https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    "https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1448&q=80",
  ];
  return (
    <CompareLayout>
      <div class="col-xl-10 col-lg-12 m-auto">
        <h1 class="heading-2 mb-10">Comparison For: Driver</h1>
        <h6 class="text-body mb-40">
          There are <span class="text-brand">3</span> products to compare
        </h6>
        <div class="table-responsive">
          <table class="table text-center table-compare">
            <tbody>
              {/* Image */}
              <tr class="pr_image">
                <td class="text-muted font-sm fw-600 font-heading mw-200">
                  Preview
                </td>
                <td class="row_img">
                  <img src={images[0]} alt="compare-img" />
                  <h5 className="my-2">Martin Luther</h5>

                  <p>
                    <CallIcon sx={{ fontSize: 18 }} />
                    0175652135
                  </p>
                </td>
                <td class="row_img">
                  <img src={images[1]} alt="compare-img" />

                  <h5 className="my-2">Rasel Ahmed</h5>

                  <p>
                    <CallIcon sx={{ fontSize: 18 }} />
                    0175652135
                  </p>
                </td>
                <td class="row_img">
                  <img src={images[2]} alt="compare-img" />

                  <h5 className="my-2">John Cooper</h5>

                  <p>
                    <CallIcon sx={{ fontSize: 18 }} />
                    0175652135
                  </p>
                </td>
              </tr>

              {/* Year of Experience */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Year of Experience
                </td>
                <td class="row_text font-xs">2</td>
                <td class="row_text font-xs">3</td>
                <td class="row_text font-xs">1</td>
              </tr>

              {/* Rating */}
              <tr class="pr_rating">
                <td class="text-muted font-sm fw-600 font-heading">Rating</td>
                <td>
                  <div class="rating_wrap">
                    <div class="product-rate d-inline-block">
                      <div
                        class="product-rating"
                        style={{ width: "90%" }}
                      ></div>
                    </div>
                    <span class="rating_num">(121)</span>
                  </div>
                </td>
                <td>
                  <div class="rating_wrap">
                    <div class="product-rate d-inline-block">
                      <div
                        class="product-rating"
                        style={{ width: "90%" }}
                      ></div>
                    </div>
                    <span class="rating_num">(35)</span>
                  </div>
                </td>
                <td>
                  <div class="rating_wrap">
                    <div class="product-rate d-inline-block">
                      <div
                        class="product-rating"
                        style={{ width: "90%" }}
                      ></div>
                    </div>
                    <span class="rating_num">(125)</span>
                  </div>
                </td>
              </tr>

              {/* Number of Trip */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Number of Trip
                </td>
                <td>50</td>
                <td>20</td>
                <td>30</td>
              </tr>

              {/* Skill */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Skill</td>
                <td>
                  Land Cruiser Driving <br />
                  BMW Driving <br />
                  Basic knowledge on Car Engine <br />
                  Multilanguage
                </td>
                <td>
                  Land Cruiser Driving <br />
                  BMW Driving <br />
                  Basic knowledge on Car Engine <br />
                  Multilanguage
                </td>
                <td>
                  Land Cruiser Driving <br />
                  BMW Driving <br />
                  Basic knowledge on Car Engine <br />
                  Multilanguage
                </td>
              </tr>

              {/* Location */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">Location</td>
                <td>123, Ring Road, Adabor, Dhaka</td>
                <td>123, Ring Road, Adabor, Dhaka</td>
                <td>123, Ring Road, Adabor, Dhaka</td>
              </tr>

              {/* Near by Me */}
              <tr>
                <td class="text-muted font-sm fw-600 font-heading">
                  Near by Me
                </td>
                <td>0.3 km</td>
                <td>0.3 km</td>
                <td>0.3 km</td>
              </tr>

              {/* Price */}
              <tr class="pr_price">
                <td class="text-muted font-sm fw-600 font-heading">Price</td>
                <td class="product_price">
                  <h4 class="price text-brand">$1000/ Per Day</h4>
                </td>
                <td class="product_price">
                  <h4 class="price text-brand">$1000/ Per Day</h4>
                </td>
                <td class="product_price">
                  <h4 class="price text-brand">$1000/ Per Day</h4>
                </td>
              </tr>
              <tr class="pr_remove text-muted">
                <td class="text-muted font-md fw-600"></td>
                <td class="row_remove">
                  <a href="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </a>
                </td>
                <td class="row_remove">
                  <a href="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </a>
                </td>
                <td class="row_remove">
                  <a href="#" class="text-muted">
                    <i class="fi-rs-trash mr-5"></i>
                    <span>Remove</span>{" "}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </CompareLayout>
  );
};

export default DriverCompare;
