import React,{ useEffect, useState }  from 'react'
// import { AudioOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { Link } from 'react-router-dom'
import axios from "../Helper/Config";
function FawPages() {
    const { Search } = Input;


    const [singleData, setAllData] = useState([]);
    useEffect(() => {
        // axios.get(`/seller/product/details/test-category-sub-category-1669451272`)
        axios.get(`/common/company/faq/list`)
          .then((res) => {
            const temp = { ...res?.data?.data }
            setAllData(temp)
          }).catch((err) => {
            console.log(err)
          })
      }, [])

    return (
        <div className='faq__pages'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h3> How can we help you? </h3>
                        <Search
                            placeholder="input search text"
                            allowClear
                            enterButton="Search"
                            size="large"
                        />
                        <h4> You can also browser the topics below to find what you are looking for </h4>
                    </div>




                    {singleData?.faq?.length > 0 ? <>
              {singleData?.faq?.map((item, i) =>
        

                    <div key={i}  className='col-lg-3 d-flex align-items-stretch'>
                        <div className='card f_faq_box'>
                            <h5> {item?.name} </h5>


                    {item?.faqList?.length > 0 ? <>
              {item?.faqList?.map((items, i) =>

                            <Link key={i}  to="/faq-details"> {items?.name} </Link>
                            // <Link key={i}  to="/faq-details"> {items?.name} </Link>
                            // <Link to="/faq-details"> General Category 2 </Link>
                            // <Link to="/faq-details"> General Category 3 </Link>

                            )}
                            </> : <> </>}



                        </div>
                    </div>

               )}
                    
            </> : <> </>}



{/* 
                    <div className='col-lg-3 d-flex align-items-stretch'>
                        <div className='card f_faq_box'>
                            <h5> General 2</h5>
                            <Link to="/faq-details"> General Category 1 </Link>
                            <Link to="/faq-details"> General Category 2 </Link>
                            <Link to="/faq-details"> General Category 3 </Link>
                        </div>
                    </div>
                    <div className='col-lg-3 d-flex align-items-stretch'>
                        <div className='card f_faq_box'>
                            <h5> General </h5>
                            <Link to="/faq-details"> General Category 1 </Link>
                            <Link to="/faq-details"> General Category 2 </Link>
                            <Link to="/faq-details"> General Category 3 </Link>
                        </div>
                    </div>
                    <div className='col-lg-3 d-flex align-items-stretch'>
                        <div className='card f_faq_box'>
                            <h5> General </h5>
                            <Link to="/faq-details"> General Category 1 </Link>
                            <Link to="/faq-details"> General Category 2 </Link>
                            <Link to="/faq-details"> General Category 3 </Link>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default FawPages