import React from "react";
import BookingLanding from "../../../../Components/Booking/BookingLanding";
import DriverListItem from "./DriverListItem";

const Driver = () => {
  return (
    <div>
      <BookingLanding>
        <DriverListItem />
      </BookingLanding>
    </div>
  );
};

export default Driver;
