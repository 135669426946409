import React from "react";
import RentCarProfileTabNav from "./RentCarProfileTabNav";
import RentCarProfileTabContent from "./RentCarProfileTabContent";

const RentCarProfileTab = ({ product }) => {
  return (
    <div className="product-info">
      <div className="tab-style3">
        <RentCarProfileTabNav />

        <RentCarProfileTabContent product={product} />
      </div>
    </div>
  );
};

export default RentCarProfileTab;
