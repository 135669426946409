import React, { useState } from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import BiddingStepContent from "./stepContent/BiddingStepContent";
import ConfirmationStepContent from "./stepContent/ConfirmationStepContent";
import BiddingSuccess from "./stepContent/BiddingSuccess";

const steps = ["Data Entry", "Confirmation", "Success"];

const RentCarBidding = ({ back }) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    activeStep === 1
      ? setActiveStep(3)
      : setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <BiddingStepContent />;
      case 1:
        return <ConfirmationStepContent />;
      default:
        return null;
    }
  };

  const renderBackButton = () => {
    if (back && activeStep === 0) {
      return (
        <button
          color="inherit"
          className="btn btn-secondary"
          //   disabled={activeStep === 0}
          onClick={back}
          sx={{ mr: 1 }}
        >
          Back
        </button>
      );
    } else {
      return (
        <button
          color="inherit"
          className="btn btn-secondary"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </button>
      );
    }
  };

  let content = null;

  if (activeStep === steps.length) {
    content = <BiddingSuccess />;
  } else {
    content = (
      <>
        {renderStepContent(activeStep)}

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {renderBackButton()}

          <Box sx={{ flex: "1 1 auto" }} />

          <button className="btn" onClick={handleNext}>
            {activeStep === steps.length - 2 ? "Submit" : "Next"}
          </button>
        </Box>
      </>
    );
  }

  return (
    <Box p={3} sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel={false}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {content}
    </Box>
  );
};

export default RentCarBidding;
