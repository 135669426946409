import React from "react";
import { apiImgUrl } from "../../../../Helper/BaseUrl";
// import AuthorTwo from "../../../../../assets/imgs/blog/author-2.png";
import AuthorTwo from "../../../../assets/imgs/blog/author-2.png";

const RentCarProfileTabContent = ({ product }) => {
  const { parts_seller } = product;

  console.log(`${apiImgUrl}${parts_seller?.shop_logo}`);
  return (
    <div className="tab-content shop_info_tab entry-main-content">
      {/* Tab - 1 */}
      <div className="tab-pane fade show active" id="Rates">
        <div className="d-flex flex-wrap gap-5">
          <table class="table">
            <tbody>
              <tr>
                <td>
                  <h5>Hourly Rate: 200 BDT</h5>
                </td>
                <td>
                  <h5>Weekend Daily: 2000 BDT</h5>
                </td>
              </tr>
              <tr>
                <td>
                  <h5>Daily Cost: 1000 BDT</h5>
                </td>
                <td>
                  <h5>Weekly: 7000 BDT</h5>
                </td>
              </tr>

              <tr>
                <td>
                  <h5>Half Day: 700 BDT</h5>
                </td>
                <td>
                  <h5>Monthly: 35000 BDT</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Tab - 2 */}
      <div className="tab-pane fade" id="Additional-info">
        <h5 className="d-flex gap-5 mb-3">
          <span>Land Cruiser Driving (4 years)</span>
          <span>BMW Driving (2 years)</span>
        </h5>

        <p>September 2015 - Current</p>
        <p className="mb-3">Motijhil - Gazipur, Dhaka</p>

        <h6>Taxi Driver</h6>
        <p className="mb-2">
          • Answered, scheduled and responded to over 30 reservation calls per
          shift at specific times and locations.
          <br /> • Performed daily vehicle inspections before beginning shifts.
          <br /> • Utilized maps, GPS, landmarks and traffic conditions to
          expertly navigate to and from destinations efficiently.
        </p>
      </div>

      {/* Tab - 3 */}
      <div className="tab-pane fade" id="License">
        <ul className="">
          <li><h5>Commercial Driver's License(CDL) Class B - Updated June 2022</h5></li>
          <li><h5>CPR and First aid Red Cross Certification - Updated March 2023</h5></li>
        </ul>
      </div>

      {/* Tab - 3 */}
      <div className="tab-pane fade" id="Reviews">
        <div className="comments-area">
          <div className="row">
            <div className="col-lg-8">
              <h4 className="mb-30">Customer questions & answers</h4>
              <div className="comment-list">
                <div className="single-comment justify-content-between d-flex mb-30">
                  <div className="user justify-content-between d-flex">
                    <div className="thumb text-center">
                      <img src={AuthorTwo} alt="" />
                      <a href="#" className="font-heading text-brand">
                        Sienna
                      </a>
                    </div>
                    <div className="desc">
                      <div className="d-flex justify-content-between mb-10">
                        <div className="d-flex align-items-center">
                          <span className="font-xs text-muted">
                            December 4, 2022 at 3:12 pm{" "}
                          </span>
                        </div>
                        {/* <div className="product-rate d-inline-block">
                            <div
                              className="product-rating"
                              style="width: 100%"
                            ></div>
                          </div> */}
                      </div>
                      <p className="mb-10">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Delectus, suscipit exercitationem accusantium
                        obcaecati quos voluptate nesciunt facilis itaque modi
                        commodi dignissimos sequi repudiandae minus ab deleniti
                        totam officia id incidunt?{" "}
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="single-comment justify-content-between d-flex mb-30 ml-30">
                  <div className="user justify-content-between d-flex">
                    <div className="thumb text-center">
                      <img src={AuthorTwo} alt="" />
                      <a href="#" className="font-heading text-brand">
                        Brenna
                      </a>
                    </div>
                    <div className="desc">
                      <div className="d-flex justify-content-between mb-10">
                        <div className="d-flex align-items-center">
                          <span className="font-xs text-muted">
                            December 4, 2022 at 3:12 pm{" "}
                          </span>
                        </div>
                        {/* <div className="product-rate d-inline-block">
                            <div
                              className="product-rating"
                              style="width: 80%"
                            ></div>
                          </div> */}
                      </div>
                      <p className="mb-10">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Delectus, suscipit exercitationem accusantium
                        obcaecati quos voluptate nesciunt facilis itaque modi
                        commodi dignissimos sequi repudiandae minus ab deleniti
                        totam officia id incidunt?{" "}
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="single-comment justify-content-between d-flex">
                  <div className="user justify-content-between d-flex">
                    <div className="thumb text-center">
                      <img src={AuthorTwo} alt="" />
                      <a href="#" className="font-heading text-brand">
                        Gemma
                      </a>
                    </div>
                    <div className="desc">
                      <div className="d-flex justify-content-between mb-10">
                        <div className="d-flex align-items-center">
                          <span className="font-xs text-muted">
                            December 4, 2022 at 3:12 pm{" "}
                          </span>
                        </div>
                        {/* <div className="product-rate d-inline-block">
                            <div
                              className="product-rating"
                              style="width: 80%"
                            ></div>
                          </div> */}
                      </div>
                      <p className="mb-10">
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Delectus, suscipit exercitationem accusantium
                        obcaecati quos voluptate nesciunt facilis itaque modi
                        commodi dignissimos sequi repudiandae minus ab deleniti
                        totam officia id incidunt?{" "}
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <h4 className="mb-30">Customer reviews</h4>
              <div className="d-flex mb-30">
                {/* <div className="product-rate d-inline-block mr-15">
                    <div className="product-rating" style="width: 90%"></div>
                  </div> */}
                <h6>4.8 out of 5</h6>
              </div>

              {/* <div className="progress">
                  <span>5 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 50%"
                    aria-valuenow="50"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    50%
                  </div>
                </div>
                <div className="progress">
                  <span>4 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 25%"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    25%
                  </div>
                </div>
                <div className="progress">
                  <span>3 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 45%"
                    aria-valuenow="45"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    45%
                  </div>
                </div>
                <div className="progress">
                  <span>2 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 65%"
                    aria-valuenow="65"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    65%
                  </div>
                </div>
                <div className="progress mb-30">
                  <span>1 star</span>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style="width: 85%"
                    aria-valuenow="85"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    85%
                  </div>
                </div> */}
              <a href="#" className="font-xs text-muted">
                How are ratings calculated?
              </a>
            </div>
          </div>
        </div>

        <div className="comment-form">
          <h4 className="mb-15">Add a review</h4>
          <div className="product-rate d-inline-block mb-30"></div>
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <form
                className="form-contact comment_form"
                action="#"
                id="commentForm"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className="form-control w-100"
                        name="comment"
                        id="comment"
                        cols="30"
                        rows="9"
                        placeholder="Write Comment"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="name"
                        id="name"
                        type="text"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="email"
                        id="email"
                        type="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        className="form-control"
                        name="website"
                        id="website"
                        type="text"
                        placeholder="Website"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="button button-contactForm">
                    Submit Review
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentCarProfileTabContent;
