import React from "react";
import { Link } from "react-router-dom";
import { apiImgUrl } from "../../../Helper/BaseUrl";
import svg from "../../../assets/imgs/theme/icons/icon-contact.svg";

const VendorListItem = ({ vendor }) => {
  const { company_name, company_logo, phone, slug } = vendor || {};

  return (
    <div className="col-lg-3 col-md-6 col-12 col-sm-6 justify-content-center">
      <div className="vendor-wrap mb-40">
        <div className="vendor-img-action-wrap">
          <div className="vendor-img">
            <Link to={`/services-center-product-details/${slug}`}>
              <img
                className="default-img"
                src={apiImgUrl + company_logo}
                alt=""
              />
            </Link>
          </div>
          <div className="product-badges product-badges-position product-badges-mrg">
            <span className="hot">Mall</span>
          </div>
        </div>
        <div className="vendor-content-wrap">
          <div className="d-flex justify-content-between align-items-end mb-30">
            <div>
              <div className="product-category">
                <span className="text-muted">Since 2012</span>
              </div>
              <h4 className="mb-5">
                <Link to={`/services-center-product-details/${slug}`}>
                  {company_name}
                </Link>
              </h4>
              <div className="product-rate-cover">
                <span className="font-small total-product">380 products</span>
              </div>
            </div>
          </div>
          <div className="vendor-info mb-30">
            <ul className="contact-infor text-muted">
              <li>
                <img src={svg} alt="" />
                <strong>Call Us: </strong>
                <span>{phone}</span>
              </li>
            </ul>
          </div>

          <Link
            to={`/services-center-product-details/${slug}`}
            className="btn btn-xs"
          >
            Visit Store <i className="fi-rs-arrow-small-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VendorListItem;
