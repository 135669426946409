import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { VscRemoteExplorer, VscArrowRight, VscCallOutgoing } from "react-icons/vsc";
import { RiChatHistoryLine, RiUserLocationLine } from "react-icons/ri";
import { Link, useParams } from 'react-router-dom'
import Iframe from 'react-iframe'
import axios from "../../Helper/Config";
import { apiImgUrl } from "../../Helper/BaseUrl";
import sBanner from "../../assets/images/sBanner.png"
import iCon from "../../assets/images/iCon.png"
import ServicesSingleProductDetails from "../../Components/Products/ServicesSingleProductDetails"
import ServicesProductSpecialOffers from "../../Components/Products/ServicesProductSpecialOffers"
function ServicesProductDetails() {
  const { slug } = useParams();
  const [data, setData] = useState([])

  useEffect(() => {
    axios.get(`/service/details/${slug}`)
      .then((res) => {
        setData(res.data.data.service_details)
      }).catch((err) => {

      })
  }, [])

  return (
    <div>
      <div className='s_p_Details'>
        <img className='img-fit' src={sBanner} alt="images" />
        <Container>
          <div className='s_p_Details_cntx'>
            <img className='img-fit' src={iCon} alt="images" />
            <h4> Help on the road </h4>
            <h5>  0123 456 789</h5>
            <p> Find out more about please call </p>
          </div>
        </Container>
      </div>
      <div className='s_p_banner__bootom_area'>
        <Container>
          <div className='s_p_banner__bootom_box'>
            <Row>
              <Col lg={3} className="d-flex align-items-stretch">
                <div className='card'>
                  <div className='s_p_banner__bootom_single_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="81" viewBox="0 0 70 81"><path d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z" fill="#ed1d24"></path></svg>
                    <VscRemoteExplorer className='s_p_icon' />
                    <h3> Book Schedule  </h3>
                  </div>
                  <div className='s_p_banner__bootom_single_box__details'>
                    <p> Take it easy driving– the life you save may be mine. </p>
                    <div className='button__s_p'>
                      <Link className='bookShidul_posihion' to="/services-booking">
                        <VscArrowRight />
                        <span> Book Service Schedule </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="d-flex align-items-stretch">
                <div className='card'>
                  <div className='s_p_banner__bootom_single_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="81" viewBox="0 0 70 81"><path d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z" fill="#ed1d24"></path></svg>
                    <RiChatHistoryLine className='s_p_icon' />
                    <h3> Service Center Information  </h3>
                  </div>
                  <div className='s_p_banner__bootom_single_box__details'>
                    <p> Name : {data?.service_center?.first_name} {data?.service_center?.last_name} ({data?.service_center?.username}) </p>
                    <p> Email : {data?.service_center?.email} </p>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="d-flex align-items-stretch">
                <div className='card'>
                  <div className='s_p_banner__bootom_single_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="81" viewBox="0 0 70 81"><path d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z" fill="#ed1d24"></path></svg>
                    <RiUserLocationLine className='s_p_icon' />
                    <h3> Location  </h3>
                  </div>
                  <div className='s_p_banner__bootom_single_box__details'>
                    <p> Take it easy driving– the life you save may be mine.  </p>
                    <div className='button__s_p'>
                      <Iframe url="https://maps.google.com/maps?width=887&amp;height=588&amp;hl=en&amp;q=%20Dhaka+()&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        width="100%"
                        height="80px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative" />
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="d-flex align-items-stretch">
                <div className='card'>
                  <div className='s_p_banner__bootom_single_box'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="81" viewBox="0 0 70 81"><path d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z" fill="#ed1d24"></path></svg>
                    <VscCallOutgoing className='s_p_icon' />
                    <h3> 24hr Hotline  </h3>
                  </div>
                  <div className='s_p_banner__bootom_single_box__details' style={{ textAlign: "center" }}>
                    <h4> {data?.service_center?.phone_number} </h4>
                    <div className='button__s_p'>
                      <p> Mobile: {data?.service_center?.phone_number} </p>
                      <p>  Fax: Data Not Found</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className='s_p_d_left_box_area'>
        <Container>
          <p >  <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>  </p>
        </Container>
      </div>
      <div className='s_p_d_left_box_area'>
        <Container>
          <ServicesSingleProductDetails allData={data} />
        </Container>
      </div>
      <div className='s_p_d_left_box_area s_p_special_left_box_area '>
        <Container>
          <Row>
            <Col lg={8}>
              <ServicesProductSpecialOffers />
            </Col>
          </Row>
        </Container>
      </div>
    </div >
  )
}

export default ServicesProductDetails
