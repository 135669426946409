import React from "react";

const VendorListHeader = () => {
  return (
    <div
      className="section-title wow animate__animated animate__fadeIn"
      data-wow-delay="0"
    >
      <h4 className="">Our All Vendor List </h4>
      <a className="show-all" href="shop-grid-right.html">
        All Vendors
        <i className="fi-rs-angle-right"></i>
      </a>
    </div>
  );
};

export default VendorListHeader;
