import React from "react";
import Headline from "../Headline";
import { DatePicker } from "antd";

const BookingStepContent = () => {
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  return (
    <div className="mt-50">
      <Headline title="Booking" />

      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-5">
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="oneWay"
              id="oneWay"
              checked
            />
            <label className="form-check-label" htmlFor="oneWay">
              One Way Trip
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="roundTrip"
              id="roundTrip"
            />
            <label className="form-check-label" htmlFor="roundTrip">
              Round Trip
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="monthly"
              id="monthly"
            />
            <label className="form-check-label" htmlFor="monthly">
              Monthly
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="Corporate"
              id="Corporate"
            />
            <label className="form-check-label" htmlFor="Corporate">
              Corporate
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="Weeding"
              id="Weeding"
            />
            <label className="form-check-label" htmlFor="Weeding">
              Weeding
            </label>
          </div>
        </div>

        <button className="btn btn-google">Check Availability</button>
      </div>

      <div class="d-flex align-items-center gap-3">
        <div>
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Pick Date
          </label>

          <DatePicker
            className="mb-20"
            onChange={onChange}
            style={{ width: 200 }}
          />
        </div>

        <div>
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Time
          </label>

          <select
            class="form-select form-select-sm mb-20"
            aria-label="Default select example"
          >
            <option selected>10:00 AM</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
        </div>

        <div>
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            For
          </label>

          <div className="d-flex gap-2">
            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>5</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>

            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>Days</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
      </div>

      <div className=" ">
        <div className="row">
          <div className="col-xl-2">
            <label
              for="exampleFormControlInput1"
              class="form-label d-block font-weight-bold"
            >
              Location Route (From - To)
            </label>

            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>10:00 AM</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="col-xl-1">
            <div className="d-flex align-items-center justify-content-center h-100">
              <span>To</span>
            </div>
          </div>
          <div className="col-xl-2">
            <label
              for="exampleFormControlInput1"
              class="form-label d-block font-weight-bold invisible"
            >
              Time
            </label>

            <select
              class="form-select form-select-sm mb-20"
              aria-label="Default select example"
            >
              <option selected>10:00 AM</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
      </div>

      <div className=" ">
        <div className="row">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block font-weight-bold"
          >
            Other Facilities
          </label>
          <div className="col-xl-3">
            <div className="row">
              <div className="col-xl-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Driver
                  </label>
                </div>
              </div>
              <div className="col-xl-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Child Seat
                  </label>
                </div>
              </div>
              <div className="col-xl-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    F1
                  </label>
                </div>
              </div>
              <div className="col-xl-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    F2
                  </label>
                </div>
              </div>
              <div className="col-xl-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    F3
                  </label>
                </div>
              </div>
              <div className="col-xl-6">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    F4
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <label
              for="exampleFormControlInput1"
              class="form-label d-block font-weight-bold"
            >
              Notes
            </label>

            <div class="form-floating">
              <textarea
                class="form-control h-100"
                placeholder="Leave a comment here"
                id="floatingTextarea2"
              ></textarea>
              <label for="floatingTextarea2">Comments</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingStepContent;
