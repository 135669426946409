import { ActionTypes } from "./action-types";
// ALL PRODUCT
export const setProducts = (products) => {
  return {
    type: ActionTypes.SET_PRODUCTS,
    payload: products,
  };
};


// // SINGLE PRODUCT DETAILS
// export const setProductsDetails = (productsdetails) => {
//   return {
//     type: ActionTypes.SET_PRODUCTS_DETAILS,
//     payload: productsdetails,
//   };
// };
// // SINGLE PRODUCT IMAGES
// export const setProductsDetailImage = (productsdetailsimage) => {
//   return {
//     type: ActionTypes.SET_PRODUCTS_DETAILS_IMAGE,
//     payload: productsdetailsimage,
//   };
// };
// // SINGLE PRODUCT Attribute
// export const setProductsDetailAttribute = (productsdetailsattribute) => {
//   const res = groupBy(productsdetailsattribute.details_arr, "color_attr_value");
//   const arr1 = getUniqueListBy(productsdetailsattribute.details_arr, 'color_attr_value')
  
// console.log(arr1, 'arr1 arr1 arr1 arr1')

// console.log(productsdetailsattribute, 'productsdetailsattribute productsdetailsattribute')
//   return {
//     type: ActionTypes.SET_PRODUCTS_DETAILS_ATTRIBUTE,
//     payload: productsdetailsattribute,
//   };
// };
// export const removeSelectedProduct = () => {
//   return {
//     type: ActionTypes.REMOVE_SELECTED_PRODUCT,
//   };
// };

// const groupBy = (arr, key) => {
//   const initialValue = {};
//   return arr.reduce((acc, cval) => {
//     const myAttribute = cval[key];
//     acc[myAttribute] = [...(acc[myAttribute] || []), cval]
//     return acc;
//   }, initialValue);
// };

 

//   function getUniqueListBy(arr, key) {
//     return [...new Map(arr.map(item => [item[key], item])).values()]
// }
