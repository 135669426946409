import React, { useEffect, useState } from 'react'
import Logo from "../../assets/imgs/theme/logo.jpg";
import iconHeart from "../../assets/imgs/theme/icons/icon-heart.svg";
import iconlocationSearch from "../../assets/imgs/theme/icons/icon-location.svg";
import iconCart from "../../assets/imgs/theme/icons/icon-cart.svg";
import iconUser from "../../assets/imgs/theme/icons/icon-user.svg";
import iconHeadphone from "../../assets/imgs/theme/icons/icon-headphone.svg";
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu, Space } from 'antd';
import AuthService from "../../Helper/Auth.Services"

import useAxiosPrivate from "../../Helper/useAxiosPrivate"

import { toast } from 'react-toastify';
import svg1 from "../../assets/imgs/theme/icons/category-1.svg";
import svg2 from "../../assets/imgs/theme/icons/category-2.svg";
import svg3 from "../../assets/imgs/theme/icons/category-3.svg";
import svg4 from "../../assets/imgs/theme/icons/category-4.svg";
import svg5 from "../../assets/imgs/theme/icons/category-5.svg";
import svg6 from "../../assets/imgs/theme/icons/category-6.svg";
import svg7 from "../../assets/imgs/theme/icons/category-7.svg";
import svg8 from "../../assets/imgs/theme/icons/category-8.svg";
import svg9 from "../../assets/imgs/theme/icons/category-9.svg";
import svg10 from "../../assets/imgs/theme/icons/category-10.svg";
import CartDropDown from '../common/CartDropDown';
function Header() {
    const userActive = AuthService.getCurrentUser();
    const axiosPrivate = useAxiosPrivate();
    const redirect = useNavigate()
    const [getCate, getCategory] = useState([0])
    const [ActiveToken, setActiveToken] = useState(false)
    const [cUserName, setUserNAme] = useState([])
    const location = useLocation()
    const string = location.pathname.includes("product-categories");
    if(string === true) {
        console.log("00000000 00000 00000000")
    } else {
        localStorage.removeItem("categories_id"); 
        localStorage.removeItem("locationDefault"); 
        localStorage.removeItem("locationId"); 
        localStorage.removeItem("make_id"); 
        localStorage.removeItem("model_id"); 
        localStorage.removeItem("max_price"); 
        localStorage.removeItem("min_price"); 
        localStorage.removeItem("parts_type"); 
        localStorage.removeItem("subcategory_id"); 
    }
    // const axiosPrivate = useAxiosPrivate();
    useEffect(() => {
        if (Number(userActive) === 0) {
            setActiveToken(false)
        } else {
            setActiveToken(true)
            const UserName = localStorage.getItem("Username") || " "
            setUserNAme(UserName)
        }
    }, [userActive])


    const LogoutFunction = () => {
        // console.log(userLogout, "userLogoutuserLogout")
        axiosPrivate.post("/auth/logout")
            .then(res => {
                console.log(res.data.message)
                toast.dark(res.data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                AuthService.logout()
                redirect("/")
            })
    }
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <Link to="/"> Account Setting </Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <button onClick={LogoutFunction} className='logout__btn'> Logout </button>
                    ),
                },
            ]}
        />
    );

    function  setCategory(cat){
        if(cat == 0){
            getCategory(1);
        }else{
            getCategory(0);
        }

    }

    return (
        <div style={{ width: "100%" }}>
            <header className="header-area header-style-1 header-height-2">
                <div className="header-top header-top-ptb-1 d-none d-lg-block">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-3 col-lg-4">
                                <div className="header-info">
                                    <ul>
                                        <li><Link to="page-account.html">My Cart</Link></li>
                                        <li><Link to="shop-wishlist.html">Checkout</Link></li>
                                        <li><Link to="shop-order.html">Order Tracking</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-4">
                                {/*<div className="text-center">*/}
                                {/*    <div id="news-flash" className="d-inline-block">*/}
                                {/*        <ul>*/}
                                {/*            <li>100% Secure delivery without contacting the courier</li>*/}
                                {/*            <li>Supper Value Deals - Save more with coupons</li>*/}
                                {/*            <li>Trendy 25silver jewelry, save up 35% off today</li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="col-xl-3 col-lg-4">
                                <div className="header-info header-info-right">
                                    <ul>

                                        <li>
                                            <a className="language-dropdown-active" href="#">English <i
                                                className="fi-rs-angle-small-down"></i></a>
                                            <ul className="language-dropdown">
                                                <li>
                                                    <Link to="#"><img src="assets/imgs/theme/flag-fr.png" alt=""/>Français</Link>
                                                </li>
                                                <li>
                                                    <Link to="#"><img src="assets/imgs/theme/flag-dt.png" alt=""/>Deutsch</Link>
                                                </li>
                                                <li>
                                                    <Link to="#"><img src="assets/imgs/theme/flag-ru.png" alt=""/>Pусский</Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li>Need help? Call Us: <strong className="text-brand"> + 1800 900</strong></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
                    <div className="container">
                        <div className="header-wrap">
                            <div className="logo logo-width-1">
                                <Link to="/"><img src={Logo} alt="logo"/></Link>
                            </div>
                            <div className="header-right">
                                <div className="search-style-2">
                                    <form action="#">
                                        <select className="select-active">
                                            <option>All Categories</option>
                                            <option>Milks and Dairies</option>
                                            <option>Wines & Alcohol</option>
                                            <option>Clothing & Beauty</option>
                                            <option>Pet Foods & Toy</option>
                                            <option>Fast food</option>
                                            <option>Baking material</option>
                                            <option>Vegetables</option>
                                            <option>Fresh Seafood</option>
                                            <option>Noodles & Rice</option>
                                            <option>Ice cream</option>
                                        </select>
                                        <input type="text" placeholder="Search for items..."/>
                                    </form>
                                </div>
                                <div className="header-action-right">
                                    <div className="header-action-2">
                                        <div className="search-location">
                                            <form action="#">
                                                <select className="select-active">
                                                    <option>Your Location</option>
                                                    <option>Alabama</option>
                                                    <option>Alaska</option>
                                                    <option>Arizona</option>
                                                    <option>Delaware</option>
                                                    <option>Florida</option>
                                                    <option>Georgia</option>
                                                    <option>Hawaii</option>
                                                    <option>Indiana</option>
                                                    <option>Maryland</option>
                                                    <option>Nevada</option>
                                                    <option>New Jersey</option>
                                                    <option>New Mexico</option>
                                                    <option>New York</option>
                                                </select>
                                            </form>
                                        </div>

                                        <div className="header-action-icon-2">
                                            <Link to="neearest-shop-locations">
                                                <img className="svgInject" alt="AdvanceSearch" src={iconlocationSearch} />
                                                {/*<span className="pro-count blue">6</span>*/}
                                            </Link>
                                            <a href="neearest-shop-locations"><span className="lable">Advance Search</span></a>
                                        </div>
                                        <div className="header-action-icon-2">
                                            <Link to="shop-wishlist.html">
                                                <img className="svgInject" alt="AdvanceSearch" src={iconHeart} />
                                                <span className="pro-count blue">6</span>
                                            </Link>
                                            <a href="shop-wishlist.html"><span className="lable">Wishlist</span></a>
                                        </div>
                                        <div className="header-action-icon-2">
                                            <Link className="mini-cart-icon" to="/cart">
                                                <img alt="Cart" src={iconCart} />
                                                <span className="pro-count blue">2</span>
                                            </Link>
                                            <Link to="/cart"><span className="lable">Cart</span></Link>
                                            
                                            <CartDropDown />
                                        </div>
                                        <div className="header-action-icon-2">
                                            <a href="/my-account">
                                                <img className="svgInject" alt="Account" src={iconUser}/>
                                            </a>
                                            <a href="/my-account"><span className="lable ml-0">Account</span></a>
                                            <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                                <ul>
                                                    <li>
                                                        <Link to="/my-account"><i
                                                            className="fi fi-rs-user mr-10"></i>My Account</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="page-account.html"><i
                                                            className="fi fi-rs-location-alt mr-10"></i>Order
                                                            Tracking</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="page-account.html"><i
                                                            className="fi fi-rs-label mr-10"></i>My Voucher</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="shop-wishlist.html"><i
                                                            className="fi fi-rs-heart mr-10"></i>My Wishlist</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="page-account.html"><i
                                                            className="fi fi-rs-settings-sliders mr-10"></i>Setting</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="page-login.html"><i
                                                            className="fi fi-rs-sign-out mr-10"></i>Sign out</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-bottom header-bottom-bg-color sticky-bar">
                    <div className="container">
                        <div className="header-wrap header-space-between position-relative">
                            <div className="logo logo-width-1 d-block d-lg-none">
                                <Link to="#">
                                    <img src="assets/imgs/theme/logo.svg" alt="logo"/>
                                </Link>
                            </div>
                            <div className="header-nav d-none d-lg-flex">
                                <div className="main-categori-wrap d-none d-lg-block">
                                    <Link className={getCate == 1 ? 'categories-button-active open': 'categories-button-active'} to="#" onClick={() => setCategory(getCate)}>
                                        <span className="fi-rs-apps"></span> All Categories
                                        <i className="fi-rs-angle-down"></i>
                                    </Link>
                                    <div className={getCate == 1 ? 'categories-dropdown-wrap categories-dropdown-active-large font-heading open': 'categories-dropdown-wrap categories-dropdown-active-large font-heading'}>
                                        <div className="d-flex categori-dropdown-inner">
                                            <ul>
                                                <li>
                                                    <Link to=""> <img
                                                        src={svg1} alt=""/>Milks and
                                                        Dairies</Link>
                                                </li>
                                                <li>
                                                    <Link to="shop-grid-right.html"> <img
                                                        src={svg2} alt=""/>Clothing &
                                                        beauty</Link>
                                                </li>
                                                <li>
                                                    <Link to="shop-grid-right.html"> <img
                                                        src={svg3} alt=""/>Pet Foods &
                                                        Toy</Link>
                                                </li>
                                                <li>
                                                    <Link to=""> <img
                                                        src={svg4} alt=""/>Baking
                                                        material</Link>
                                                </li>
                                                <li>
                                                    <Link to="shop-grid-right.html"> <img
                                                        src={svg5} alt=""/>Fresh Fruit</Link>
                                                </li>
                                            </ul>
                                            <ul className="end">
                                                <li>
                                                    <Link to="shop-grid-right.html"> <img
                                                        src={svg6} alt=""/>Wines &
                                                        Drinks</Link>
                                                </li>
                                                <li>
                                                    <Link to="shop-grid-right.html"> <img
                                                        src={svg7} alt=""/>Fresh
                                                        Seafood</Link>
                                                </li>
                                                <li>
                                                    <Link to="shop-grid-right.html"> <img
                                                        src={svg8} alt=""/>Fast
                                                        food</Link>
                                                </li>
                                                <li>
                                                    <Link to="shop-grid-right.html"> <img
                                                        src={svg9} alt=""/>Vegetables</Link>
                                                </li>
                                                <li>
                                                    <Link to="shop-grid-right.html"> <img
                                                        src={svg10} alt=""/>Bread and
                                                        Juice</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    {/*    <div className="more_slide_open" style="display: none">*/}
                                    {/*        <div className="d-flex categori-dropdown-inner">*/}
                                    {/*            <ul>*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="shop-grid-right.html"> <img*/}
                                    {/*                        src="assets/imgs/theme/icons/icon-1.svg" alt=""/>Milks and*/}
                                    {/*                        Dairies</a>*/}
                                    {/*                </li>*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="shop-grid-right.html"> <img*/}
                                    {/*                        src="assets/imgs/theme/icons/icon-2.svg" alt=""/>Clothing &*/}
                                    {/*                        beauty</a>*/}
                                    {/*                </li>*/}
                                    {/*            </ul>*/}
                                    {/*            <ul className="end">*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="shop-grid-right.html"> <img*/}
                                    {/*                        src="assets/imgs/theme/icons/icon-3.svg" alt=""/>Wines &*/}
                                    {/*                        Drinks</a>*/}
                                    {/*                </li>*/}
                                    {/*                <li>*/}
                                    {/*                    <a href="shop-grid-right.html"> <img*/}
                                    {/*                        src="assets/imgs/theme/icons/icon-4.svg" alt=""/>Fresh*/}
                                    {/*                        Seafood</a>*/}
                                    {/*                </li>*/}
                                    {/*            </ul>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*    <div className="more_categories"><span className="icon"></span> <span*/}
                                    {/*        className="heading-sm-1">Show more...</span></div>*/}
                                    </div>
                                </div>
                                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                                    <nav>
                                        <ul>

                                            <li>
                                                <a className="active" href="/">Home </a>

                                            </li>
                                            <li>
                                                <a href="/about-us">About</a>
                                            </li>
                                            <li>
                                                <a href="/product-categories/2/shop-grid-right.html">Shop <i className="fi-rs-angle-down"></i></a>
                                                {/*<ul className="sub-menu">*/}
                                                {/*    <li><a href="shop-grid-right.html">Shop Grid – Right Sidebar</a>*/}
                                                {/*    </li>*/}
                                                {/*    <li><a href="shop-grid-left.html">Shop Grid – Left Sidebar</a></li>*/}
                                                {/*    <li><a href="shop-list-right.html">Shop List – Right Sidebar</a>*/}
                                                {/*    </li>*/}
                                                {/*    <li><a href="shop-list-left.html">Shop List – Left Sidebar</a></li>*/}
                                                {/*    <li><a href="shop-fullwidth.html">Shop - Wide</a></li>*/}
                                                {/*    <li>*/}
                                                {/*        <a href="#">Single Product <i className="fi-rs-angle-right"></i></a>*/}
                                                {/*        <ul className="level-menu">*/}
                                                {/*            <li><a href="shop-product-right.html">Product – Right*/}
                                                {/*                Sidebar</a></li>*/}
                                                {/*            <li><a href="shop-product-left.html">Product – Left*/}
                                                {/*                Sidebar</a></li>*/}
                                                {/*            <li><a href="shop-product-full.html">Product – No*/}
                                                {/*                sidebar</a></li>*/}
                                                {/*            <li><a href="shop-product-vendor.html">Product – Vendor*/}
                                                {/*                Info</a></li>*/}
                                                {/*        </ul>*/}
                                                {/*    </li>*/}
                                                {/*    <li><a href="shop-filter.html">Shop – Filter</a></li>*/}
                                                {/*    <li><a href="shop-wishlist.html">Shop – Wishlist</a></li>*/}
                                                {/*    <li><a href="shop-cart.html">Shop – Cart</a></li>*/}
                                                {/*    <li><a href="shop-checkout.html">Shop – Checkout</a></li>*/}
                                                {/*    <li><a href="shop-compare.html">Shop – Compare</a></li>*/}
                                                {/*    <li>*/}
                                                {/*        <a href="#">Shop Invoice<i*/}
                                                {/*            className="fi-rs-angle-right"></i></a>*/}
                                                {/*        <ul className="level-menu">*/}
                                                {/*            <li><a href="shop-invoice-1.html">Shop Invoice 1</a></li>*/}
                                                {/*            <li><a href="shop-invoice-2.html">Shop Invoice 2</a></li>*/}
                                                {/*            <li><a href="shop-invoice-3.html">Shop Invoice 3</a></li>*/}
                                                {/*            <li><a href="shop-invoice-4.html">Shop Invoice 4</a></li>*/}
                                                {/*            <li><a href="shop-invoice-5.html">Shop Invoice 5</a></li>*/}
                                                {/*            <li><a href="shop-invoice-6.html">Shop Invoice 6</a></li>*/}
                                                {/*        </ul>*/}
                                                {/*    </li>*/}
                                                {/*</ul>*/}
                                            </li>
                                            <li>
                                                <a href="/services-product">Services<i className="fi-rs-angle-down"></i></a>
                                                <ul className="sub-menu">
                                                    <li><a href="vendors-grid.html">Vendors Grid</a></li>
                                                    <li><a href="/services-product">Service List</a></li>
                                                    <li><a href="vendor-details-1.html">Vendor Details 01</a></li>
                                                    <li><a href="vendor-details-2.html">Vendor Details 02</a></li>
                                                    <li><a href="vendor-dashboard.html">Vendor Dashboard</a></li>
                                                    <li><a href="vendor-guide.html">Vendor Guide</a></li>
                                                </ul>
                                            </li>
                                            <li className="position-static">
                                                <a href="#">Mega menu <i className="fi-rs-angle-down"></i></a>
                                                <ul className="mega-menu">
                                                    <li className="sub-mega-menu sub-mega-menu-width-22">
                                                        <a className="menu-title" href="#">Fruit & Vegetables</a>
                                                        <ul>
                                                            <li><a href="shop-product-right.html">Meat & Poultry</a>
                                                            </li>
                                                            <li><a href="shop-product-right.html">Fresh Vegetables</a>
                                                            </li>
                                                            <li><a href="shop-product-right.html">Herbs & Seasonings</a>
                                                            </li>
                                                            <li><a href="shop-product-right.html">Cuts & Sprouts</a>
                                                            </li>
                                                            <li><a href="shop-product-right.html">Exotic Fruits &
                                                                Veggies</a></li>
                                                            <li><a href="shop-product-right.html">Packaged Produce</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="sub-mega-menu sub-mega-menu-width-22">
                                                        <a className="menu-title" href="#">Breakfast & Dairy</a>
                                                        <ul>
                                                            <li><a href="shop-product-right.html">Milk & Flavoured
                                                                Milk</a></li>
                                                            <li><a href="shop-product-right.html">Butter and
                                                                Margarine</a></li>
                                                            <li><a href="shop-product-right.html">Eggs Substitutes</a>
                                                            </li>
                                                            <li><a href="shop-product-right.html">Marmalades</a></li>
                                                            <li><a href="shop-product-right.html">Sour Cream</a></li>
                                                            <li><a href="shop-product-right.html">Cheese</a></li>
                                                        </ul>
                                                    </li>
                                                    <li className="sub-mega-menu sub-mega-menu-width-22">
                                                        <a className="menu-title" href="#">Meat & Seafood</a>
                                                        <ul>
                                                            <li><a href="shop-product-right.html">Breakfast Sausage</a>
                                                            </li>
                                                            <li><a href="shop-product-right.html">Dinner Sausage</a>
                                                            </li>
                                                            <li><a href="shop-product-right.html">Chicken</a></li>
                                                            <li><a href="shop-product-right.html">Sliced Deli Meat</a>
                                                            </li>
                                                            <li><a href="shop-product-right.html">Wild Caught
                                                                Fillets</a></li>
                                                            <li><a href="shop-product-right.html">Crab and Shellfish</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="sub-mega-menu sub-mega-menu-width-34">
                                                        <div className="menu-banner-wrap">
                                                            <a href="shop-product-right.html"><img
                                                                src="assets/imgs/banner/banner-menu.png"
                                                                alt="Nest"/></a>
                                                            <div className="menu-banner-content">
                                                                <h4>Hot deals</h4>
                                                                <h3>
                                                                    Don't miss<br/>
                                                                    Trending
                                                                </h3>
                                                                <div className="menu-banner-price">
                                                                    <span
                                                                        className="new-price text-success">Save to 50%</span>
                                                                </div>
                                                                <div className="menu-banner-btn">
                                                                    <a href="shop-product-right.html">Shop now</a>
                                                                </div>
                                                            </div>
                                                            <div className="menu-banner-discount">
                                                                <h3>
                                                                    <span>25%</span>
                                                                    off
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <a href="blog-category-grid.html">Blog <i*/}
                                            {/*        className="fi-rs-angle-down"></i></a>*/}
                                            {/*    <ul className="sub-menu">*/}
                                            {/*        <li><a href="blog-category-grid.html">Blog Category Grid</a></li>*/}
                                            {/*        <li><a href="blog-category-list.html">Blog Category List</a></li>*/}
                                            {/*        <li><a href="blog-category-big.html">Blog Category Big</a></li>*/}
                                            {/*        <li><a href="blog-category-fullwidth.html">Blog Category Wide</a>*/}
                                            {/*        </li>*/}
                                            {/*        <li>*/}
                                            {/*            <a href="#">Single Post <i*/}
                                            {/*                className="fi-rs-angle-right"></i></a>*/}
                                            {/*            <ul className="level-menu level-menu-modify">*/}
                                            {/*                <li><a href="blog-post-left.html">Left Sidebar</a></li>*/}
                                            {/*                <li><a href="blog-post-right.html">Right Sidebar</a></li>*/}
                                            {/*                <li><a href="blog-post-fullwidth.html">No Sidebar</a></li>*/}
                                            {/*            </ul>*/}
                                            {/*        </li>*/}
                                            {/*    </ul>*/}
                                            {/*</li>*/}
                                            <li>
                                                <a href="#">Pages <i className="fi-rs-angle-down"></i></a>
                                                <ul className="sub-menu">
                                                    <li><a href="/about-us">About Us</a></li>
                                                    <li><a href="/contact-us">Contact</a></li>
                                                    <li><a href="page-account.html">My Account</a></li>
                                                    <li><a href="login">Login</a></li>
                                                    <li><a href="/registration">Register</a></li>
                                                    {/*<li><a href="page-forgot-password.html">Forgot password</a></li>*/}
                                                    {/*<li><a href="page-reset-password.html">Reset password</a></li>*/}
                                                    {/*<li><a href="page-purchase-guide.html">Purchase Guide</a></li>*/}
                                                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                                                    {/*<li><a href="page-terms.html">Terms of Service</a></li>*/}
                                                    {/*<li><a href="page-404.html">404 Page</a></li>*/}
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="/contact-us">Contact</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>


                            <div className="hotline d-none d-lg-flex">
                                <img src={iconHeadphone} alt="hotline"/>
                                <p>1900 - 888<span>24/7 Support Center</span></p>
                            </div>
                            <div className="header-action-icon-2 d-block d-lg-none">
                                <div className="burger-icon burger-icon-white">
                                    <span className="burger-icon-top"></span>
                                    <span className="burger-icon-mid"></span>
                                    <span className="burger-icon-bottom"></span>
                                </div>
                            </div>
                            {/*<div className="header-action-right d-block d-lg-none">*/}
                            {/*    <div className="header-action-2">*/}
                            {/*        <div className="header-action-icon-2">*/}
                            {/*            <Link to="shop-wishlist.html">*/}
                            {/*                /!*<img alt="Nest" src="assets/imgs/theme/icons/icon-heart.svg"/>*!/*/}
                            {/*                <span className="pro-count white">4</span>*/}
                            {/*            </Link>*/}
                            {/*        </div>*/}
                            {/*        <div className="header-action-icon-2">*/}
                            {/*            <Link className="mini-cart-icon" to="#">*/}
                            {/*                /!*<img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg"/>*!/*/}
                            {/*                <span className="pro-count white">2</span>*/}
                            {/*            </Link>*/}
                            {/*            <div className="cart-dropdown-wrap cart-dropdown-hm2">*/}
                            {/*                <ul>*/}
                            {/*                    <li>*/}
                            {/*                        <div className="shopping-cart-img">*/}
                            {/*                            <Link href="shop-product-right.html">*/}
                            {/*                                /!*<img alt="Nest"  src="assets/imgs/shop/thumbnail-3.jpg"/>*!/*/}
                            {/*                            </Link>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="shopping-cart-title">*/}
                            {/*                            <h4>*/}
                            {/*                                <Link to="shop-product-right.html">Plain Striola Shirts</Link>*/}
                            {/*                            </h4>*/}
                            {/*                            <h3><span>1 × </span>$800.00</h3>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="shopping-cart-delete">*/}
                            {/*                            <Link to="#"><i className="fi-rs-cross-small"></i></Link>*/}
                            {/*                        </div>*/}
                            {/*                    </li>*/}
                            {/*                    <li>*/}
                            {/*                        <div className="shopping-cart-img">*/}
                            {/*                            <Link to="shop-product-right.html">*/}
                            {/*                                <img alt="Nest" src="assets/imgs/shop/thumbnail-4.jpg"/>*/}
                            {/*                            </Link>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="shopping-cart-title">*/}
                            {/*                            <h4><Link to="shop-product-right.html">Macbook Pro 2022</Link></h4>*/}
                            {/*                            <h3><span>1 × </span>$3500.00</h3>*/}
                            {/*                        </div>*/}
                            {/*                        <div className="shopping-cart-delete">*/}
                            {/*                            <Link to="#"><i className="fi-rs-cross-small"></i></Link>*/}
                            {/*                        </div>*/}
                            {/*                    </li>*/}
                            {/*                </ul>*/}
                            {/*                <div className="shopping-cart-footer">*/}
                            {/*                    <div className="shopping-cart-total">*/}
                            {/*                        <h4>Total <span>$383.00</span></h4>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="shopping-cart-button">*/}
                            {/*                        <Link to="#">View cart</Link>*/}
                            {/*                        <Link to="#">Checkout</Link>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </header>
            {/*{BannerShow ? <>*/}
            {/*    <div className='top_banner_area'>*/}
            {/*        <Container>*/}
            {/*            <Row>*/}
            {/*                <Col lg={12} style={{ position: "relative" }}>*/}
            {/*                    <img src={TopBanner} alt="" />*/}
            {/*                    <RiCloseLine onClick={bannerOff} />*/}
            {/*                </Col>*/}
            {/*            </Row>*/}
            {/*        </Container>*/}
            {/*    </div>*/}
            {/*</> : <>  </>}*/}
            {/*<div className='logo_bottom_menu_area'>*/}
            {/*    <div>*/}
            {/*        <div className='logo_serach_area'>*/}
            {/*            <Container>*/}
            {/*                <Row>*/}
            {/*                    <Col xs={8} lg={4}>*/}
            {/*                        <div className='header_logo'>*/}
            {/*                            <Link to="/"> <img className='img-fit' src={Logo} alt="" /></Link>*/}
            {/*                            <Link to="/neearest-shop-locations">*/}
            {/*                                <div className='location_btn'>*/}
            {/*                                    <span className="location-description">Your Location</span>*/}
            {/*                                    <div className="location_text">Neearest Shop Locations</div>*/}
            {/*                                </div>*/}
            {/*                            </Link>*/}
            {/*                        </div>*/}
            {/*                    </Col>*/}
            {/*                    <Col xs={4} lg={8}>*/}
            {/*                        <div className='rasponsive__menu_icon'>*/}
            {/*                            <MobileCatagories />*/}
            {/*                        </div>*/}
            {/*                        <div className='rasponsive__search_icon' onClick={searchBtn}>*/}
            {/*                            <BiSearch />*/}
            {/*                        </div>*/}

            {/*                        <div className='search_all_area__full'>*/}
            {/*                            <Search />*/}
            {/*                        </div>*/}

            {/*                    </Col>*/}
            {/*                    {searchRfile ? <>*/}
            {/*                        <Col xs={12} lg={12}>*/}
            {/*                            <div className='movile_view_search'>*/}
            {/*                                <Search />*/}
            {/*                            </div>*/}
            {/*                        </Col>*/}
            {/*                    </> : <>  </>}*/}
            {/*                </Row>*/}
            {/*            </Container>*/}
            {/*        </div>*/}
            {/*        <div className='top_header_menu'>*/}
            {/*            <Container>*/}
            {/*                <ul>*/}
            {/*                    <li><Link className='color__hdr' to="/"> Home</Link></li>*/}
            {/*                    <li><Link className='color__hdr' to="/about-us">About</Link></li>*/}
            {/*                    <li><Link to="/services-product">Services</Link></li>*/}
            {/*                    <li><Link to="/">Shop</Link></li>*/}
            {/*                    <li><Link to="/contact-us">Contact Us</Link></li>*/}
            {/*                    {ActiveToken === true ? <>*/}
            {/*                        <div className='profile_menu_header'>*/}
            {/*                            <Dropdown overlay={menu}>*/}
            {/*                                <Space>*/}
            {/*                                    {cUserName}*/}
            {/*                                    <DownOutlined />*/}
            {/*                                </Space>*/}
            {/*                            </Dropdown>*/}
            {/*                        </div>*/}
            {/*                    </> : <>*/}
            {/*                        <li><Link to="/login">Login</Link></li>*/}
            {/*                        <li><Link to="/registration">Registration</Link></li>*/}
            {/*                    </>}*/}
            {/*                </ul>*/}
            {/*            </Container>*/}
            {/*        </div>*/}
            {/*        <div className='movile__bottom_fixted_menu'>*/}
            {/*            <Container>*/}
            {/*                <div className='d-flex justify-content-between align-items-center'>*/}
            {/*                    <div className='box__one'>*/}
            {/*                        <Link to="/"> <RiHome2Line /> <br></br> Home </Link>*/}
            {/*                    </div>*/}
            {/*                    <div className='box__one'>*/}
            {/*                        <Link to="/cart">*/}

            {/*                            <Cart /><br></br> Cart </Link>*/}
            {/*                    </div>*/}
            {/*                    <div className='box__one'>*/}
            {/*                        <Link to="/"> <RiUser3Line /> <br></br> Profile </Link>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </Container>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default Header
