import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCartTotal } from "../../reduxServices/actions/CartActions";

import BreadCrumb from "../../Components/common/BreadCrumb";
import CartBody from "./CartBody/CartBody";
import CartHeader from "./CartHeader";

function Cart() {
  const cartslist = useSelector((state) => state.cartItems.carts);
  // const allItem = useSelector((state) => state.cartItems.totalItem);
  const totalAmount = useSelector((state) => state.cartItems.totalAmount);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCartTotal());
  }, [cartslist]);

  console.log(cartslist, "cartslist 123");
  return (
    <main class="main">
      <BreadCrumb />

      <div class="container mb-80 mt-50">
        {/* Cart Header */}
        <CartHeader />

        {/* Cart Body */}
        <CartBody />
      </div>
    </main>
  );
}

export default Cart;
