import React from "react";
import { Link } from "react-router-dom";
import ThumbnailThree from "../../assets/imgs/shop/thumbnail-3.jpg";
import ThumbnailTwo from "../../assets/imgs/shop/thumbnail-2.jpg";

const CartDropDown = () => {
  return (
    <div className="cart-dropdown-wrap cart-dropdown-hm2">
      <ul>
        <li>
          <div className="shopping-cart-img">
            <Link to="shop-product-right.html">
              <img alt="Nest" src={ThumbnailThree} />
            </Link>
          </div>
          <div className="shopping-cart-title">
            <h4>
              <Link to="shop-product-right.html">Daisy Casual Bag</Link>
            </h4>
            <h4>
              <span>1 × </span>$800.00
            </h4>
          </div>
          <div className="shopping-cart-delete">
            <Link to="#">
              <i className="fi-rs-cross-small"></i>
            </Link>
          </div>
        </li>
        <li>
          <div className="shopping-cart-img">
            <Link to="shop-product-right.html">
              <img alt="Nest" src={ThumbnailTwo} />
            </Link>
          </div>
          <div className="shopping-cart-title">
            <h4>
              <Link to="shop-product-right.html">Corduroy Shirts</Link>
            </h4>
            <h4>
              <span>1 × </span>$3200.00
            </h4>
          </div>
          <div className="shopping-cart-delete">
            <Link to="#">
              <i className="fi-rs-cross-small"></i>
            </Link>
          </div>
        </li>
      </ul>
      <div className="shopping-cart-footer">
        <div className="shopping-cart-total">
          <h4>
            Total <span>$4000.00</span>
          </h4>
        </div>
        <div className="shopping-cart-button">
          <Link to="/cart" className="outline">
            View cart
          </Link>
          <Link to="/checkout">Checkout</Link>
        </div>
      </div>
    </div>
  );
};

export default CartDropDown;
