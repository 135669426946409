import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import ServiceDetailsModal from "../../../Components/common/modal/ServiceDetailsModal";
import ServiceItem from "./ServiceItem";

const DUMMY_DATA = [
  {
    title: "Change Tire",
    image:
      "https://plus.unsplash.com/premium_photo-1664302116671-3173d8dcd09f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    price: 2500,
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure nam tenetur blanditiis culpa cumque voluptas, labore, id illum cupiditate enim eligendi libero repudiandae quam dicta sapiente deleniti pariatur sed aut!",
  },
  {
    title: "Oil Change",
    image:
      "https://images.unsplash.com/photo-1487754180451-c456f719a1fc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
    price: 500,
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure nam tenetur blanditiis culpa cumque voluptas, labore, id illum cupiditate enim eligendi libero repudiandae quam dicta sapiente deleniti pariatur sed aut! ",
  },
  {
    title: "Car Wash",
    image:
      "https://plus.unsplash.com/premium_photo-1670002392440-0a64552ae431?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
    price: 5000,
    description:
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure nam tenetur blanditiis culpa cumque voluptas, labore, id illum cupiditate enim eligendi libero repudiandae quam dicta sapiente deleniti pariatur sed aut!",
  },
];

const ServiceList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [service, setService] = useState(null);

  const handleClick = (data) => {
    setService(data);
    setIsModalOpen(true);
  };
  return (
    <>
      <h5 class="section-title style-1 mb-30">Services</h5>

      {isModalOpen && (
        <ServiceDetailsModal
          data={service}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <Container>
        <Row class="vendor-grid">
          {DUMMY_DATA.map((service, i) => (
            <ServiceItem onClick={handleClick} key={i} service={service} />
          ))}

          {/* <div class="col-lg-4 col-md-6 col-12 col-sm-6">
                        <div class="vendor-wrap mb-40">
                            <div class="">
                                <div class="vendor-img">
                                    <a href="vendor-details-1.html">
                                        <img class="default-img" src="https://plus.unsplash.com/premium_photo-1664302116671-3173d8dcd09f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="" />
                                    </a>
                                </div>
                                
                            </div>
                            <div class="vendor-content-wrap">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 class="mb-5"><a href="vendor-details-1.html">Nature Food</a></h4>
                                    </div>
                                    <div class="">
                                        <a href="vendor-details-1.html" class="btn btn-xs">Visit Store <i class="fi-rs-arrow-small-right"></i></a>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>                    */}
        </Row>
      </Container>
    </>
  );
};

export default ServiceList;
