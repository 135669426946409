import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom";
import './App.css';
import { ToastContainer } from "react-toastify";
// import useAuth from './hooks/useAuth';
// Components
import Loader from "./Helper/Loader"
import PrivateRoute from "./Helper/PrivateRoute"
import AuthService from "./Helper/Auth.Services"
import Layout from "./Components/Layout/Layout"
// Pages
import HomePages from "./Pages/HomePages"
import Registration from "./Pages/Registration"
import GeneralUserReg from "./Pages/GeneralUser/GeneralUserReg"
import LoginPages from "./Pages/GeneralUser/LoginPages"
import ForgotPassword from "./Pages/ForgotPassword"
import ChangePassword from "./Pages/ChangePassword"
import ServicesProviderRegistration from "./Pages/ServiceProvider/ServicesProviderRegistration"
import SubscriptionPackagFrom from "./Pages/ServiceProvider/SubscriptionPackagFrom"
import NeearShopMap from "./Pages/NeearShopMap"
import AboutUs from "./Pages/AboutUs"
import PrivacyPolicy from "./Pages/PrivacyPolicy"
import ReturnsRefunds from "./Pages/ReturnsRefunds"
import ContactUs from "./Pages/ContactUs"
import FawPages from "./Pages/FawPages"
import FaqDetails from "./Pages/FaqDetails"
import ProductCategories from "./Pages/ProductCategories"
import ProductDetails from "./Pages/ProductDetails"
import VerifyPassword from "./Pages/VerifyPassword"
import ServicesProduct from "./Pages/Services/ServicesProduct"
import ServicesProductDetails from "./Pages/Services/ServicesProductDetails"
import ServicesCenterProductDetails from "./Pages/Services/ServicesCenterProductDetails"
import ServicesBookingReg from "./Pages/Services/ServicesBookingReg/ServicesBookingReg"
import Cart from "./Pages/Cart"
import Checkout from "./Pages/Checkout"
import SubCategoriesList from "./Pages/SubCategoriesList"
import SuccessOrder from "./Pages/SuccessOrder";

import PartsSellerDetails from "./Pages/PartsSeller/PartsSellerDetails"
import RentCarBooking from './Pages/Services/RentCarBooking';
import RentCarDetails from './Pages/Services/RentCarBooking/RentCarDetails';
import Driver from './Pages/Services/booking/driver';
import DriverDetails from './Pages/Services/booking/driver/DriverDetails';
import Mechanic from './Pages/Services/booking/mechanic';
import MechanicDetails from './Pages/Services/booking/mechanic/MechanicDetails';
import UserBiddingList from './Pages/Bidding/UserBiddingList';
import DriverBiddingDetails from './Pages/Bidding/BiddingDetails/DriverBiddingDetails';
import MechanicBiddingDetails from './Pages/Bidding/BiddingDetails/MechanicBiddingDetails';
import RentCarBiddingDetails from './Pages/Bidding/BiddingDetails/RentCarBiddingDetails';
import PartsBiddingDetails from './Pages/Bidding/BiddingDetails/PartsBiddingDetails';
import MyAccount from './Pages/MyAccount';
import Compare from './Pages/Compare';
import DriverProfile from './Pages/Profile/DriverProfile';
import MechanicProfile from './Pages/Profile/MechanicProfile/MechanicProfile';
import RentCarProfile from './Pages/Profile/RentCarProfile/RentCarProfile';
import UserBiddingListNew from './Pages/Bidding/UserBiddingListNew';
import BiddingDriverLanding from './Pages/Bidding/BiddingLanding/BiddingDriverLanding';

function App() {
  // const { setAuth } = useAuth();
  const [loading, setLoading] = useState(true)
  const userData = AuthService.getCurrentUser();
  const [currentUser, setCurrentUser] = useState(true)
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000)
    if (Number(userData) === 0) {
      setCurrentUser(false)
    } else {
      setCurrentUser(true)
    }
  }, [userData])
  return (
    <div className="App">

      <Layout>
      <ToastContainer limit={1} />
        <Loader
          loaderShow={loading}
          loaderHide={loading}
        />
        <Routes>
          <Route path="/" element={<HomePages />}></Route>
          <Route path="/neearest-shop-locations" element={<NeearShopMap />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/returns-refunds" element={<ReturnsRefunds />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/faq" element={<FawPages />}></Route>
          <Route path="/faq-details" element={<FaqDetails />}></Route>
          <Route path="/sub-categories-list/:slug" element={<SubCategoriesList />}></Route>
          <Route path="/product-categories/:id/:slug/" element={<ProductCategories />}></Route>
          <Route path="/product-details/:slug" element={<ProductDetails />}></Route>
          <Route path="/verify-password/:token" element={<VerifyPassword />}></Route>

          <Route path="/services-product/" element={<ServicesProduct />}></Route>
          <Route path="/services-product-details/:slug" element={<ServicesProductDetails />}></Route>
          <Route path="/services-center-product-details/:slug" element={<ServicesCenterProductDetails />}></Route>
          <Route path="/services-booking/:slug" element={<ServicesBookingReg />}></Route>
          <Route path="/cart" element={<Cart />}></Route>
          <Route path="/checkout" element={<Checkout />}></Route>
          <Route path="/success-order/:id" element={<SuccessOrder />}></Route>

          {/* bidding */}
          <Route path="/user-bidding-list" element={<UserBiddingList />}></Route>
          <Route path="/user-bidding-list-v2" element={<UserBiddingListNew />}></Route>
          {/* Bidding Landing */}
          <Route path="/bidding/driver" element={<BiddingDriverLanding />}></Route>
          {/* Bidding Details */}
          <Route path="/bidding/driver/details" element={<DriverBiddingDetails />}></Route>
          <Route path="/bidding/mechanic/details" element={<MechanicBiddingDetails />}></Route>
          <Route path="/bidding/rent-car/details" element={<RentCarBiddingDetails />}></Route>
          <Route path="/bidding/parts/details" element={<PartsBiddingDetails />}></Route>
          {/* <Route path="/user-budding-list-chart" element={<ApexChart />}></Route> */}

          {/* PartsSeller */}
          <Route path="/parts-seller-details/:slug" element={<PartsSellerDetails />}></Route>

          {/* Services */}
          {/* Rent A Car */}
          <Route path="/rent-car-booking" element={<RentCarBooking />} />
          <Route path="/rent-car-booking/details" element={<RentCarDetails />} />
          {/* <Route path="/booking/rent-car" element={<RentCarBooking />} />
          <Route path="/booking/rent-car/details" element={<RentCarDetails />} /> */}

          {/* Driver Booking */}
          <Route path="/booking/driver" element={<Driver />} />
          <Route path="/booking/driver/details" element={<DriverDetails />} />

          {/* mechanic Booking */}
          <Route path="/booking/mechanic" element={<Mechanic />} />
          <Route path="/booking/mechanic/details" element={<MechanicDetails />} />

          {/* Common page - User Profile - My Profile  */}
          <Route path="/my-account" element={<MyAccount />} />

          {/* Compare Page */}
          <Route path="/compare" element={<Compare />} />

          {/* Profile */}
          <Route path="/profile/driver" element={<DriverProfile />} />
          <Route path="/profile/mechanic" element={<MechanicProfile />} />
          <Route path="/profile/rent-car" element={<RentCarProfile />} />

          <Route element={<PrivateRoute isLogged={currentUser} />}>
            <Route path="/registration" element={<Registration />}></Route>
            <Route path="/generaluser-registration" element={<GeneralUserReg />}></Route>
            <Route path="/login" element={<LoginPages />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/change-password/:token" element={<ChangePassword />}></Route>
            <Route path="/services-provider-registration" element={<ServicesProviderRegistration />}></Route>
            <Route path="/services-provider-registration-from/:id" element={<SubscriptionPackagFrom />}></Route>
          </Route>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
