import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SliderImage from "react-zoom-slider";
import { BiMinus, BiPlus } from "react-icons/bi";
import {
  RiMapPin2Line,
  RiBikeFill,
  RiMoneyDollarCircleLine,
  RiSendPlaneLine,
  RiFocus2Line,
  RiFocusLine,
} from "react-icons/ri";
import axios from "../../Helper/Config";
import { apiImgUrl } from "../../Helper/BaseUrl";
import dataProduct from "../../data.json";
import {
  increase,
  decrease,
  remove,
  getCartTotal,
  ADD,
} from "../../reduxServices/actions/CartActions";
import { Card } from "antd";
import ProductGallery from "./ProductGallery";
import DetailInfo from "./DetailInfo";
import ProductInfo from "./ProductInfo/ProductInfo";
import RelatedProducts from "./RelatedProducts";
import ProductBreadcrumb from "./ProductBreadcrumb";
function ProductDetails() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(6);
  const [singleData, setAllData] = useState([]);
  const { slug } = useParams();
  const [dataImg] = useState([]);
  const loadmore = () => {
    setVisible((prevValue) => prevValue + 6);
  };

  console.log(singleData);
  useEffect(() => {
    axios
      .get(`/seller/product/details/${slug}`)
      .then((res) => {
        const temp = { ...res?.data?.data?.product_details[0], qnty: 1 };
        // console.log(temp, "temp temp")
        if (temp) {
          dataImg.push(
            { image: apiImgUrl + temp?.thumbnail },
            {
              image:
                "https://images.unsplash.com/photo-1503376780353-7e6692767b70?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            }
          );
          setAllData(temp);
          res?.data?.data?.product_details[0]?.all_image?.forEach((data) => {
            dataImg.push({ image: apiImgUrl + data?.image });
          });
        } else {
          dataImg.push({});
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Increnent
  const dataIncriment = (e) => {
    const cartItem = singleData;
    if ((cartItem.id === e) & (cartItem?.stock > singleData?.qnty)) {
      const data = { ...cartItem, qnty: (cartItem.qnty += 1) };
      setAllData(data);
    }
  };

  // Decriment
  const dataDecriment = (e) => {
    const cartItem = singleData;
    if ((cartItem.id === e) & (cartItem.qnty > 1)) {
      const data = { ...cartItem, qnty: cartItem.qnty - 1 };
      setAllData(data);
    }
  };

  const getDataCart = (e) => {
    dispatch(ADD(e));
  };

  return (
    <div>
      <ProductBreadcrumb />
      <Container>
        <div className="container mb-30">
          <div className="row">
            <div className="col-xl-10 col-lg-12 m-auto">
              <div className="product-detail accordion-detail">
                <div className="row mb-50 mt-30">
                  <div className="col-md-6 col-sm-12 col-xs-12 mb-md-0 mb-sm-5">
                    <ProductGallery dataImg={dataImg} />
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12">
                    <DetailInfo product={singleData} />
                  </div>
                </div>

                <ProductInfo product={singleData} />
                <RelatedProducts />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="product_details_box_area mb-4">
          <Row>
            <Col lg={5}>
              {dataImg?.length > 0 ? (
                <>
                  <SliderImage
                    data={dataImg}
                    showDescription={true}
                    direction="right"
                  />
                </>
              ) : (
                <>
                  <div className="images____area">
                    <img
                      src="https://image.shutterstock.com/image-vector/photography-prohibited-sign-not-allowed-260nw-1897944295.jpg"
                      alt="okk"
                    />
                    <div className="images__white__bottom"></div>
                  </div>
                </>
              )}
            </Col>
            <Col lg={4}>
              <div className="product_details_contant">
                <h3> {singleData?.name} </h3>
                <span style={{ marginRight: "15px" }}>
                  {" "}
                  Make: {singleData?.make?.name}{" "}
                </span>
                <span> Model: {singleData?.model?.name} </span>
                /!* <img src={Pdetails} alt="" /> *!/ /!*{" "}
                <p className="availability__">
                  {" "}
                  Availability : <b style={{ color: "green" }}>
                    {" "}
                    In Stock{" "}
                  </b>{" "}
                </p>{" "}
                *!/
                <p className="availability__">
                  {" "}
                  Availability :{" "}
                  {singleData?.stock > 0 ? (
                    <>
                      <b style={{ color: "green" }}> In Stock </b>
                    </>
                  ) : (
                    <>
                      {" "}
                      <b style={{ color: "red" }}> Out Of Stock </b>{" "}
                    </>
                  )}
                </p>
                <p className="availability__">
                  {" "}
                  Product Code : {singleData?.product_code}{" "}
                </p>
                <div className="price">
                  {singleData?.discount_price > 0 ? (
                    <>
                      ৳{singleData?.discount_price}
                      <del
                        style={{
                          fontSize: "12px",
                          color: "#d51c22",
                          marginLeft: "5px",
                        }}
                      >
                        {" "}
                        ৳{singleData?.selling_price}{" "}
                      </del>
                    </>
                  ) : (
                    <> ৳{singleData?.selling_price} </>
                  )}
                </div>
                <p className="p_d_details">
                  <div
                    className="product__descrip_list"
                    dangerouslySetInnerHTML={{
                      __html: singleData?.short_description?.substring(0, 300),
                    }}
                  />
                </p>
                <div className="size_area">
                  <p> Quantity : </p>
                  <div className="price_qantity">
                    <button onClick={() => dataDecriment(singleData?.id)}>
                      {" "}
                      <BiMinus />{" "}
                    </button>
                    /!*{" "}
                    <form>
                      {" "}
                      *!/
                      <input
                        type="text"
                        name="quantity"
                        className="col bg-transparent border-0 text-center flex-grow-1 fs-16 input-number"
                        value={singleData?.qnty}
                      />
                      /!*{" "}
                    </form>{" "}
                    *!/
                    <button onClick={() => dataIncriment(singleData?.id)}>
                      {" "}
                      <BiPlus />{" "}
                    </button>
                  </div>
                  <h6> ({singleData?.stock} available) </h6>
                </div>
                <div className="order_button">
                  <button> Buy Now </button>
                  <button
                    onClick={() => getDataCart(singleData)}
                    style={{ background: "#b61b20" }}
                  >
                    {" "}
                    Add to Cart{" "}
                  </button>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="delivery_ch_box">
                <h5> Delivery </h5>
                <div className="delivery_charg_box_list">
                  <RiMapPin2Line />
                  <p>
                    {" "}
                    Dhaka, Dhaka North, Banani Road No. 12 - 19{" "}
                    <button> Change </button>{" "}
                  </p>
                </div>
                <div className="delivery_charg_box_list delivery_charg_box_list_two">
                  <RiBikeFill />
                  <h3>
                    {" "}
                    Home Delivery <br></br>{" "}
                    <span>
                      {" "}
                      2 - 4 day(s) <b> ৳ 50 </b>{" "}
                    </span>{" "}
                  </h3>
                </div>
                <div className="delivery_charg_box_list delivery_charg_box_list_two">
                  <RiMoneyDollarCircleLine />
                  <h3 className="pt-2"> Cash on Delivery Available </h3>
                </div>
                <h5 className="mt-3"> Services </h5>
                <div className="delivery_charg_box_list delivery_charg_box_list_two">
                  <RiFocus2Line />
                  <h3>
                    {" "}
                    7 Days Returns <br></br>{" "}
                    <span> Change of mind is not applicable </span>{" "}
                  </h3>
                </div>
                <div className="delivery_charg_box_list delivery_charg_box_list_two">
                  <RiSendPlaneLine />
                  <h3 className="pt-2"> Warranty not available </h3>
                </div>
              </div>
              <div className="delivery_ch_box mt-3">
                <h6> Sold by </h6>
                <Link to=""> {singleData?.parts_seller?.shop_name} </Link>
                /!*{" "}
                <Link
                  className="woocommerce-LoopProduct-link woocommerce-loop-product__link"
                  to={`/product-categories/${item?.slug}`}
                ></Link>{" "}
                *!/
                <Link
                  className="visit_store_link"
                  to={`/parts-seller-details/${singleData?.parts_seller?.slug}`}
                >
                  {" "}
                  Visit Store{" "}
                </Link>
              </div>
            </Col>
          </Row>
        </div> */}
      </Container>
    </div>
  );
}

export default ProductDetails;
