import { ActionTypes } from "../actions/action-types";
import { toast } from "react-toastify";
const INIT_STATE = {
    carts: [],
    totalAmount: 0,
    totalItem: 0,
    totalCount: 0,
};

export const CartReducer = (state = INIT_STATE, action) => {
    let findPro;
    let index;

    switch (action.type) {
        // CARD ADD
        case ActionTypes.ADD_CART:
            const check = state.carts.find((item) =>
                item.id === action.payload.id ? true : false
            );
            if (check) {
                    toast.clearWaitingQueue();
                    toast.error("Products Already Exits...", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    return {
                        ...state
                    }
            } else {
                const temp = action.payload.qnty > 1 ? {...action.payload} : { ...action.payload, qnty: 1 }
                const totalitem = state.carts?.length + 1;
                const totalAmount = ((temp?.discount_price > 0) ? temp?.discount_price : temp?.selling_price) * temp?.qnty;
                toast.success("Product Added...", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                return {
                    ...state,
                    carts: [...state.carts, temp],
                    totalItem: totalitem,
                    totalAmount: totalAmount,
                }
            }

        // TOTRAL COUNT
        case ActionTypes.GET_TOTALS:
            // console.log(state, "state state state")
            let { totalAmount, totalCount } = state?.carts?.reduce(
                (cartTotal, cartItem) => {
                    const { selling_price, discount_price, qnty } = cartItem;
                    const itemTotal = ((discount_price > 0) ? discount_price : selling_price) * qnty;
                    cartTotal.totalAmount += itemTotal;
                    return cartTotal;
                },
                {
                    totalAmount: 0,
                    totalCount: 0,
                }
            );
            totalAmount = parseFloat(totalAmount.toFixed(2));

            return { ...state, totalAmount, totalCount };

        // ITEM INCRIMENT
        case ActionTypes.INCREASE:
            let tempCartInc = state.carts.map((cartItem) => {
                if (cartItem.id === action.payload) {
                    return { ...cartItem, qnty: cartItem.qnty + 1 };
                }
                return cartItem;
            });

            return { ...state, carts: tempCartInc };

        // ITEM DECRIMENT
        case ActionTypes.DECREASE:
            const totalitem = state.carts?.length -1
            let tempCartDec = state.carts?.map((cartItem) => {
                if (cartItem.id === action.payload) {
                    return { ...cartItem, qnty: cartItem.qnty - 1 };
                }
                return cartItem;
            })
                .filter((cartItem) => cartItem.qnty !== 0);
            return { ...state, carts: tempCartDec, totalitem: totalitem };

        // ITEM REMOVE
        case ActionTypes.REMOVE:
            findPro = state.carts.find((product) =>
                product.id === action.payload
            );
            const filtered = state.carts.filter((product) =>
                product.id !== action.payload
            );
            return {
                ...state,
                carts:filtered,
                totalItem: filtered?.length,
            };

        default:
            return state
    }

}
