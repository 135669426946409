import React from "react";

const BookingTabNav = () => {
  return (
    <ul className="nav nav-tabs text-uppercase">
      <li className="nav-item">
        <a
          className="nav-link /* active */"
          id="Description-tab"
          data-bs-toggle="tab"
          href="#Rates"
        >
          Skill
        </a>
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          id="Additional-info-tab"
          data-bs-toggle="tab"
          href="#Additional-info"
        >
          Experience
        </a>
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          id="Reviews-tab"
          data-bs-toggle="tab"
          href="#Reviews"
        >
          Reviews (3)
        </a>
      </li>
    </ul>
  );
};

export default BookingTabNav;
