import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CustomPagination from "../../../Components/common/CustomPagination";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import CustomModal from "../../../Components/common/modal/CustomModal";
import InputWithLabel from "../../../Components/common/input/InputWithLabel";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

const MyEnquiry = () => {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState(false);

  return (
    <div
      class="tab-pane fade"
      id="enquiry"
      role="tabpanel"
      aria-labelledby="enquiry-tab"
    >
      <div class="card">
        <div class="card-header d-flex justify-content-between">
          <h3 class="mb-0">My Enquiry List</h3>

          <button className="btn btn-primary" onClick={() => setMessage(true)}>
            Send Message
          </button>
        </div>

        <div class="card-body">
          <div className="row">
            <div className="col-xl-12">
              <h4 className="mb-15">Message History</h4>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Sl</th>
                      <th>Date</th>
                      <th>Enquiry For</th>
                      <th>Subject</th>
                      <th>Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>12/3/2022</td>
                      <td>Driver</td>
                      <td>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Deserunt dolorem suscipit sunt nemo dolores
                      </td>
                      <td>
                        <span
                          className="d-flex align-items-center gap-2 cursor-pointer"
                          onClick={() => setModal(true)}
                        >
                          <VisibilityIcon sx={{ fontSize: 16 }} />
                          <a href="#" class="btn-small d-block">
                            View
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>12/3/2022</td>
                      <td>Rent A Car</td>
                      <td>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Deserunt dolorem suscipit sunt nemo dolores
                      </td>
                      <td>
                        <span
                          className="d-flex align-items-center gap-2 cursor-pointer"
                          onClick={() => setModal(true)}
                        >
                          <VisibilityIcon sx={{ fontSize: 16 }} />
                          <a href="#" class="btn-small d-block">
                            View
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>12/3/2022</td>
                      <td>Parts Seller</td>
                      <td>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Deserunt dolorem suscipit sunt nemo dolores
                      </td>
                      <td>
                        <span
                          className="d-flex align-items-center gap-2 cursor-pointer"
                          onClick={() => setModal(true)}
                        >
                          <VisibilityIcon sx={{ fontSize: 16 }} />
                          <a href="#" class="btn-small d-block">
                            View
                          </a>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>1</td>
                      <td>12/3/2022</td>
                      <td>Mechanic</td>
                      <td>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Deserunt dolorem suscipit sunt nemo dolores
                      </td>
                      <td>
                        <span
                          className="d-flex align-items-center gap-2 cursor-pointer"
                          onClick={() => setModal(true)}
                        >
                          <VisibilityIcon sx={{ fontSize: 16 }} />
                          <a href="#" class="btn-small d-block">
                            View
                          </a>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <CustomPagination placement="center" />
            </div>
          </div>
        </div>
      </div>

      {/* Modal - Send Message */}
      <CustomModal
        size="lg"
        footer={false}
        onHide={() => setMessage(false)}
        show={message}
        modalTitle={"Send Message"}
      >
        <div className="row">
          <div className="col-xl-6">
            <InputWithLabel label="Your Name" placeholder="Your Name" />
          </div>
          <div className="col-xl-6">
            <InputWithLabel
              label="Email"
              type="email"
              placeholder="Your Email"
            />
          </div>
          <div className="col-xl-6">
            <InputWithLabel label="Phone" type="tel" placeholder="Your Phone" />
          </div>
          <div className="col-xl-6">
            <InputWithLabel
              label="Enquiry For"
              component="select"
              defaultOption="Please Select"
            />
          </div>

          <div className="col-xl-12">
            <InputWithLabel label="Subject" placeholder="Enter Subject" />
          </div>

          <div className="col-xl-12">
            <label for="exampleFormControlInput1" class="form-label d-block ">
              Notes
            </label>

            <div class="form-floating">
              <textarea
                class="form-control h-100 rounded-1"
                placeholder="Leave a comment here"
                id="floatingTextarea2"
              ></textarea>
              <label for="floatingTextarea2">Leave a comment here</label>
            </div>
          </div>
        </div>

        <div className="text-end mt-20">
          <button
            className="btn btn-primary "
            onClick={() => setMessage(false)}
          >
            Send Message
          </button>
        </div>
      </CustomModal>

      {/* Modal - Response */}
      <CustomModal
        size="lg"
        footer={false}
        onHide={() => setModal(false)}
        show={modal}
        modalTitle={"Response"}
      >
        <div className="border rounded-2 p-3">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis
            sunt reiciendis ad quae, a accusamus sapiente unde totam illum
            incidunt doloremque autem pariatur laborum neque quod officia amet
            vel cumque nulla dolor temporibus dolorem cum! Vero nam nostrum
            optio facilis debitis architecto voluptatem, repellendus recusandae
            tempora porro.
          </p>
          <p>
            Omnis, sint dolor officiis et quaerat dolores sunt consequuntur id
            quod! Dolorum inventore, doloremque laboriosam vel repudiandae neque
            dolor, incidunt aspernatur excepturi similique quas nulla doloribus
            maxime
          </p>
        </div>

        <div className="text-end mt-20">
          <button
            className="btn btn-secondary "
            onClick={() => setModal(false)}
          >
            Close
          </button>
        </div>
      </CustomModal>
    </div>
  );
};

export default MyEnquiry;
