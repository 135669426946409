import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom';
import axios from "../Helper/Config";
import {VscCallOutgoing} from "react-icons/vsc";
import {apiImgUrl} from "../Helper/BaseUrl";
import {Empty} from "antd";
function SuccessOrder() {
    const [orderData, order] = useState('');
    const prams = useParams().id;
    useEffect(() => {
        fetchOrder();
    }, []);
    const fetchOrder = () => {
        axios.get(`/onlineorder/details/${prams}`)
            .then((res) => {
                if(res){
                    order(res.data.data)
                }

            }).catch((err) => {
            console.log(err)
        })
    }
    console.log(orderData,'orderData')

        return (
            <div>
                <div className="cart-invoice">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 cart-cards">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="mb-3">Order Details</h3>
                                        <table className="table table-bordered">
                                            <tr>
                                                <td style={{'width':'50%'}}>
                                                    <table className="table" style={{'border-right':'1px solid #eded', 'margin-bottom':'0'}}>
                                                        <tr>
                                                            <td colSpan="2" className="text-left" style={{"border-bottom":"1px solid #ededed","padding": "10px 0"}}>
                                                                <p className="mb-0 p-0"><strong>Reference Id</strong></p>
                                                                <h3 className="mb-0 p-0"><strong>#{orderData.order?orderData.order.order_id:''}</strong></h3>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{"padding": "10px 0"}}>
                                                                <p className="mb-0 p-0"><strong>Customer Name</strong></p>
                                                                <h5 className="mb-0 p-0">{orderData.customer?orderData.customer.first_name:''} {orderData.customer?orderData.customer.last_name:''}</h5>
                                                            </td>
                                                            <td style={{"padding": "10px 0"}}>
                                                                <p className="mb-0 p-0"><strong>Contact</strong></p>
                                                                <h5 className="mb-0 p-0"><VscCallOutgoing className="text-danger" /> {orderData.customer?orderData.customer.phone_number:''}</h5>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td >
                                                    <table className="table">
                                                        <tr>
                                                            <td className="text-left">
                                                                <p className="mb-0 pb-0"><strong>Shipping Address</strong></p>
                                                                <p className="mb-0 pb-0">{orderData.order?orderData.order.billing_address:''}</p>
                                                                <p className="mb-0 pb-0"><VscCallOutgoing className="text-danger" /> {orderData.order?orderData.order.contact_no:''}</p>

                                                            </td>
                                                            <td>
                                                                <p className="mb-0 pb-0"><strong>Billing Address</strong></p>
                                                                <p className="mb-0 pb-0">{orderData.order?orderData.order.shipping_address:''}</p>
                                                                <p className="mb-0 pb-0"><VscCallOutgoing className="text-danger" /> {orderData.order?orderData.order.contact_no:''}</p>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>

                                        <div className="table-responsive">
                                            <table className="table table-bordered  mb-0">
                                                <thead>
                                                <tr>
                                                    <th colSpan="col">Image</th>
                                                    <th colSpan="col">Title</th>
                                                    <th colSpan="col" className="text-end">Price</th>
                                                    <th colSpan="col" width="100px" className="text-end">Quantity</th>
                                                    <th colSpan="col" width="150px" className="text-end">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {orderData.order_product?.length > 0 ? <>
                                                    {orderData.order_product?.map((item, i) =>
                                                        <tr key={i}>
                                                            <td>
                                                                <img  src={item.product_info?apiImgUrl + item.product_info.thumbnail:''} alt={item.product_info?item.product_info.name:''}
                                                                     className="success-img" />
                                                            </td>
                                                            <td className="title align-middle"><h5>{item.product_info?item.product_info.name:''}</h5></td>
                                                            <td className="text-end align-middle">
                                                                <strong>৳ {item?.unit_price}</strong>
                                                            </td>
                                                            <td width="100px" className="text-end align-middle">
                                                                {item?.qty}
                                                            </td>
                                                            <td className="text-end align-middle">
                                                                <strong>৳ {Number(item?.qty) * Number(item?.unit_price)}</strong>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </> : <>
                                                    <tr>
                                                        <td colSpan="100%">
                                                            <div className='dataEmtey'><Empty/></div>
                                                        </td>
                                                    </tr>
                                                </>}

                                                <tr>
                                                    <td colSpan="4" className="text-end align-middle">
                                                            <strong>Sub Total:</strong>
                                                    </td>
                                                    <td className="text-end align-middle">
                                                            <strong>৳&nbsp;{orderData.order?orderData.order.sub_total:0}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className="text-end align-middle">
                                                            <strong>Shipment Charge:</strong>
                                                    </td>
                                                    <td className="text-end align-middle">
                                                            <strong>৳&nbsp;{orderData.order?orderData.order.ship_charge:0}</strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4" className="text-end align-middle">
                                                            <strong>Grand Total:</strong>
                                                    </td>
                                                    <td className="text-end align-middle">
                                                            <strong>৳&nbsp;{orderData.order?orderData.order.grand_total:0}</strong>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 cart-cards'>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <div className='order_success mb-3'>
                                            <div className="success-style">
                                                <i className="checkmark">✓</i>
                                            </div>
                                            <h1>Success</h1>
                                            <h3 className="mb-2">Hi, {localStorage.getItem("Username")}</h3>
                                            <h5 className="mb-2">We received your purchase request;<br/> we'll be in touch shortly!. </h5>
                                            <p className="mb-4">Your reference id is 121455. All details will be sent to your email and check your booking profile.
                                                Thank you for bokking. Use the above reference id in all communication.</p>

                                            <Link className="cartbtn shop" to="/product-categories"> Go To
                                                Dashboard </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

}

export default SuccessOrder
