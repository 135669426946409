import * as Yup from 'yup';
import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    fullName,
    kilometer,
    contactNo,
    problemdetails,
    make,
    model,
    useraddres,
    // nameOnCard,
    // cardNumber,
  }
} = checkoutFormModel;

// const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

export default [
  Yup.object().shape({
    [fullName?.name]: Yup.string().required(`${fullName?.requiredErrorMsg}`),
    [kilometer?.name]: Yup.string().required(`${kilometer?.requiredErrorMsg}`),
    [contactNo?.name]: Yup.string().required(`${contactNo?.requiredErrorMsg}`),
    [problemdetails?.name]: Yup.string().required(`${problemdetails?.requiredErrorMsg}`),
    [useraddres?.name]: Yup.string().required(`${useraddres?.requiredErrorMsg}`),
    [make?.name]: Yup.string().required(`${make?.requiredErrorMsg}`),
    [model?.name]: Yup.string().required(`${model?.requiredErrorMsg}`),
  }),
];
