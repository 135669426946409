import React, { useState, useEffect } from 'react';
import ProductGallery from '../../ProductDetails/ProductGallery';
import RelatedProducts from '../../ProductDetails/RelatedProducts';
import ServiceRightSide from '../ServicesCenterProductDetails/ServicesRightSide/ServiceRightSide';

import axios from '../../../Helper/Config';
import BookingDetailsInfo from '../booking/bookingDetails/BookingDetailsInfo';
import BookingInfoTab from '../booking/bookingDetails/BookingInfoTab/BookingInfoTab';
import CustomStepper from '../../../Components/common/stepper/CustomStepper';
import BreadCrumb from '../../../Components/common/BreadCrumb';

const dataImg = [
    {
        image: 'https://images.unsplash.com/photo-1533473359331-0135ef1b58bf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
    },
    {
        image: 'https://images.unsplash.com/photo-1503376780353-7e6692767b70?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
    },
];

const singleData = {
    id: 50,
    parts_seller_id: 22,
    name: 'Toyota Axio 2017 Front Bumper',
    slug: 'toyota-axio-2017-front-bumper-1677949463',
    parts_type: 7,
    short_description:
        'Product Name:  Toyota AE100 Grill\r\n\r\nBrand : Toyota \r\n\r\nProduct Condition: New \r\n\r\nCar Model    :  Toyota AE100\r\n\r\nQuality: High Level\r\n\r\nSize: Standard\r\n\r\nColor: Standard\r\n\r\nDelivery time: 1 day',
    description:
        'Product Name:  Toyota AE100 Grill\r\n\r\nBrand : Toyota \r\n\r\nProduct Condition: New \r\n\r\nCar Model    :  Toyota AE100\r\n\r\nQuality: High Level\r\n\r\nSize: Standard\r\n\r\nColor: Standard\r\n\r\nDelivery time: 1 day',
    product_code: '00013',
    barcode: null,
    stock: 40,
    purchase_price: 6000,
    selling_price: 7000,
    discount_price: 6800,
    dv_charge: 100,
    vendor: null,
    form_date: '2023-03-04',
    to_date: '2023-06-30',
    make: {
        id: 6,
        name: 'TOYOTA',
    },
    model: {
        id: 1,
        name: 'Corolla',
    },
    year: '2005',
    status: 1,
    return_available: null,
    required_down_payment: null,
    discount_type: 0,
    discount: null,
    cod: 1,
    mobile_wallet: null,
    credit_card: 1,
    thumbnail: 'img/product/729034046.jpg',
    video: null,
    created_at: '2023-03-04T17:04:23.000000Z',
    created_by: 22,
    updated_at: '2023-03-29T13:45:09.000000Z',
    updated_by: 22,
    deleted_at: null,
    deleted_by: null,
    checker: 2,
    parts_seller: {
        id: 3,
        parts_seller_id: 22,
        shop_name: 'Gazipur Online Garage',
        slug: 'gazipur-online-garage-1676214995',
        email: 'gog@xyz.com',
        phone: '19878797619',
        shop_logo: 'img/partsseller/logo/146844824.png',
        country_id: 18,
        division_id: 6,
        district_id: 41,
        thana_id: 320,
        union_id: null,
        address: 'Joydebpur, Gazipur-1700',
        country: {
            id: 18,
            name: 'BANGLADESH',
        },
        division: {
            id: 6,
            name: 'Dhaka',
        },
        district: {
            id: 41,
            name: 'Gazipur',
        },
        thana: {
            id: 320,
            name: 'Gazipur Sadar',
        },
        union: null,
    },
    category: [
        {
            id: 85,
            product_id: 50,
            category_id: 2,
            created_at: '2023-03-29T13:45:09.000000Z',
            updated_at: '2023-03-29T13:45:09.000000Z',
        },
    ],
    sub_category: [],
    all_image: [],
    qnty: 1,
};

const RentCarDetails = () => {
    const [data, setData] = useState([]);
    const [isBooking, setIsBooking] = useState(false);

    const slug = 'uttara-test-garage-1676053320';

    useEffect(() => {
        axios
            .get(`/service/center/list`)
            .then((res) => {
                const dataFound = res?.data?.data?.services_center?.data?.filter((obj) => {
                    return obj.slug === slug;
                });
                setData(dataFound[0]);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <BreadCrumb sub='Rent' />
            <div className='container mb-30 mt-30'>
                <div className='row'>
                    <div className='col-lg-4-5 /* col-xl-10 col-lg-12 m-auto*/ '>
                        <div className='product-detail accordion-detail'>
                            <div className='row mb-50 mt-30'>
                                <div className='col-md-5 col-sm-12 col-xs-12 mb-md-0 mb-sm-5'>
                                    <ProductGallery dataImg={dataImg} />
                                </div>
                                <div className='col-md-7 col-sm-12 col-xs-12'>
                                    <BookingDetailsInfo setIsBooking={setIsBooking} product={singleData} />
                                </div>
                            </div>

                            {/* <ProductInfo product={singleData} /> */}

                            {isBooking ? <CustomStepper alterLabel /> : <BookingInfoTab product={singleData} />}

                            <RelatedProducts />
                        </div>
                    </div>

                    <div className='col-lg-1-5 primary-sidebar sticky-sidebar'>
                        <ServiceRightSide data={data} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default RentCarDetails;
