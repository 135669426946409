import React from 'react'
import { Link } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import product1 from '../../assets/imgs/shop/parts-1.jpg';
import product2 from '../../assets/imgs/shop/parts-2.jpg';
import product3 from '../../assets/imgs/shop/parts-3.jpg';
import product4 from '../../assets/imgs/shop/car-1.jpeg';
import product5 from '../../assets/imgs/shop/car-2.jpeg';
import product6 from '../../assets/imgs/shop/car-3.webp';
import product7 from '../../assets/imgs/shop/car-4.jpeg';
import product8 from '../../assets/imgs/shop/parts-4.jpg';
import {FEATURED_PRODUCTS_OPTION} from "../../Helper/constant/coreConstant";
function FeaturedProducts() {
    return (
       <div>
           <section className="section-padding pb-5">
               <div className="container">
                   <div className="section-title wow animate__animated animate__fadeIn">
                       <h4 className=""> Featured Products </h4>

                   </div>
                   <div className="row">
                       <div className="col-lg-3 d-none d-lg-flex wow animate__animated animate__fadeIn">
                           <div className="banner-img style-2">
                               <div className="banner-text">
                                   <h2 className="mb-100" style={{color : "#3BB77E"}}>Let's make life easy</h2>
                                   <Link to="shop-grid-right.html" className="btn btn-xs">Shop Now <i
                                       className="fi-rs-arrow-small-right"></i></Link>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-9 col-md-12 wow animate__animated animate__fadeIn" data-wow-delay=".4s">
                           <OwlCarousel className='owl-theme'
                                        loop
                                        margin={25}
                                        nav={true}
                                        dots={false}
                                        autoplay={false}
                                        autoplayTimeout={5000}
                                        {...FEATURED_PRODUCTS_OPTION}>
                           <div className="product-cart-wrap">
                               <div className="product-img-action-wrap">
                                   <div className="product-img product-img-zoom">
                                       <Link to="">
                                           <img className="default-img"
                                                src={product1} alt=""/>
                                           <img className="hover-img"
                                                src={product2} alt=""/>
                                       </Link>
                                   </div>
                                   <div className="product-action-1">
                                       <button aria-label="Quick view" className="action-btn small hover-up"
                                             data-bs-toggle="modal" data-bs-target="#quickViewModal"> <i
                                           className="fi-rs-eye"></i></button>
                                       <Link aria-label="Add To Wishlist"
                                             className="action-btn small hover-up"
                                             to=""><i className="fi-rs-heart"></i></Link>
                                       <Link aria-label="Compare" className="action-btn small hover-up"
                                             to=""><i className="fi-rs-shuffle"></i></Link>
                                   </div>
                                   <div
                                       className="product-badges product-badges-position product-badges-mrg">
                                       <span className="new">Save 35%</span>
                                   </div>
                               </div>
                               <div className="product-content-wrap">
                                   <div className="product-category">
                                       <Link to="">Hodo Foods</Link>
                                   </div>
                                   <h2><Link to="">New model 
                                       car engine</Link></h2>
                                   <div className="product-rate d-inline-block">
                                       <div className="product-rating"></div>
                                   </div>
                                   <div className="product-price mt-10">
                                       <span>$238.85 </span>
                                       <span className="old-price">$245.8</span>
                                   </div>
                                   <div className="sold mt-15 mb-15">
                                       <div className="progress mb-5">
                                           <div className="progress-bar" role="progressbar"
                                                 aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                       </div>
                                       <span className="font-xs text-heading"> Sold: 90/120</span>
                                   </div>
                                   <Link to="" className="btn w-100 hover-up"><i
                                       className="fi-rs-shopping-cart mr-5"></i>Add To Cart</Link>
                               </div>
                           </div>
                           <div className="product-cart-wrap">
                               <div className="product-img-action-wrap">
                                   <div className="product-img product-img-zoom">
                                       <Link to="">
                                           <img className="default-img"
                                                src={product3} alt=""/>
                                           <img className="hover-img"
                                                src={product4} alt=""/>
                                       </Link>
                                   </div>
                                   <div className="product-action-1">
                                       <button aria-label="Quick view" className="action-btn small hover-up"
                                               data-bs-toggle="modal" data-bs-target="#quickViewModal"> <i
                                           className="fi-rs-eye"></i></button>
                                       <Link aria-label="Add To Wishlist"
                                             className="action-btn small hover-up"
                                             to=""><i className="fi-rs-heart"></i></Link>
                                       <Link aria-label="Compare" className="action-btn small hover-up"
                                             to=""><i className="fi-rs-shuffle"></i></Link>
                                   </div>
                                   <div
                                       className="product-badges product-badges-position product-badges-mrg">
                                       <span className="new">Save 35%</span>
                                   </div>
                               </div>
                               <div className="product-content-wrap">
                                   <div className="product-category">
                                       <Link to="">Hodo Foods</Link>
                                   </div>
                                   <h2><Link to="">New model 
                                       car engine</Link></h2>
                                   <div className="product-rate d-inline-block">
                                       <div className="product-rating"></div>
                                   </div>
                                   <div className="product-price mt-10">
                                       <span>$238.85 </span>
                                       <span className="old-price">$245.8</span>
                                   </div>
                                   <div className="sold mt-15 mb-15">
                                       <div className="progress mb-5">
                                           <div className="progress-bar" role="progressbar"
                                                aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                       </div>
                                       <span className="font-xs text-heading"> Sold: 90/120</span>
                                   </div>
                                   <Link to="" className="btn w-100 hover-up"><i
                                       className="fi-rs-shopping-cart mr-5"></i>Add To Cart</Link>
                               </div>
                           </div>
                           <div className="product-cart-wrap">
                               <div className="product-img-action-wrap">
                                   <div className="product-img product-img-zoom">
                                       <Link to="">
                                           <img className="default-img"
                                                src={product5} alt=""/>
                                           <img className="hover-img"
                                                src={product6} alt=""/>
                                       </Link>
                                   </div>
                                   <div className="product-action-1">
                                       <button aria-label="Quick view" className="action-btn small hover-up"
                                               data-bs-toggle="modal" data-bs-target="#quickViewModal"> <i
                                           className="fi-rs-eye"></i></button>
                                       <Link aria-label="Add To Wishlist"
                                             className="action-btn small hover-up"
                                             to=""><i className="fi-rs-heart"></i></Link>
                                       <Link aria-label="Compare" className="action-btn small hover-up"
                                             to=""><i className="fi-rs-shuffle"></i></Link>
                                   </div>
                                   <div
                                       className="product-badges product-badges-position product-badges-mrg">
                                       <span className="new">Save 35%</span>
                                   </div>
                               </div>
                               <div className="product-content-wrap">
                                   <div className="product-category">
                                       <Link to="">Hodo Foods</Link>
                                   </div>
                                   <h2><Link to="">New model 
                                       car engine</Link></h2>
                                   <div className="product-rate d-inline-block">
                                       <div className="product-rating"></div>
                                   </div>
                                   <div className="product-price mt-10">
                                       <span>$238.85 </span>
                                       <span className="old-price">$245.8</span>
                                   </div>
                                   <div className="sold mt-15 mb-15">
                                       <div className="progress mb-5">
                                           <div className="progress-bar" role="progressbar"
                                                aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                       </div>
                                       <span className="font-xs text-heading"> Sold: 90/120</span>
                                   </div>
                                   <Link to="" className="btn w-100 hover-up"><i
                                       className="fi-rs-shopping-cart mr-5"></i>Add To Cart</Link>
                               </div>
                           </div>
                           <div className="product-cart-wrap">
                               <div className="product-img-action-wrap">
                                   <div className="product-img product-img-zoom">
                                       <Link to="">
                                           <img className="default-img"
                                                src={product7} alt=""/>
                                           <img className="hover-img"
                                                src={product8} alt=""/>
                                       </Link>
                                   </div>
                                   <div className="product-action-1">
                                       <button aria-label="Quick view" className="action-btn small hover-up"
                                               data-bs-toggle="modal" data-bs-target="#quickViewModal"> <i
                                           className="fi-rs-eye"></i></button>
                                       <Link aria-label="Add To Wishlist"
                                             className="action-btn small hover-up"
                                             to=""><i className="fi-rs-heart"></i></Link>
                                       <Link aria-label="Compare" className="action-btn small hover-up"
                                             to=""><i className="fi-rs-shuffle"></i></Link>
                                   </div>
                                   <div
                                       className="product-badges product-badges-position product-badges-mrg">
                                       <span className="new">Save 35%</span>
                                   </div>
                               </div>
                               <div className="product-content-wrap">
                                   <div className="product-category">
                                       <Link to="">Hodo Foods</Link>
                                   </div>
                                   <h2><Link to="">New model 
                                       car engine</Link></h2>
                                   <div className="product-rate d-inline-block">
                                       <div className="product-rating"></div>
                                   </div>
                                   <div className="product-price mt-10">
                                       <span>$238.85 </span>
                                       <span className="old-price">$245.8</span>
                                   </div>
                                   <div className="sold mt-15 mb-15">
                                       <div className="progress mb-5">
                                           <div className="progress-bar" role="progressbar"
                                                aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                       </div>
                                       <span className="font-xs text-heading"> Sold: 90/120</span>
                                   </div>
                                   <Link to="" className="btn w-100 hover-up"><i
                                       className="fi-rs-shopping-cart mr-5"></i>Add To Cart</Link>
                               </div>
                           </div>
                           </OwlCarousel>

                       </div>
                   </div>
               </div>
           </section>
       </div>
    )
}

export default FeaturedProducts
