import React from "react";
import MechanicProfileTabNav from "./MechanicProfileTabNav";
import MechanicProfileTabContent from "./MechanicProfileTabContent";

const MechanicProfileTab = ({ product }) => {
  return (
    <div className="product-info">
      <div className="tab-style3">
        <MechanicProfileTabNav />

        <MechanicProfileTabContent product={product} />
      </div>
    </div>
  );
};

export default MechanicProfileTab;
