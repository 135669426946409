import React, { useState } from "react";

const CustomRadioButton = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]); // Set the first option as the default
  console.log(selectedOption);
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  return (
    <div className="d-flex align-items-center flex-wrap gap-3">
      {options.map((option, index) => (
        <div key={index} className="form-check mr-20">
          <input
            className="form-check-input"
            type="radio"
            name="radioOptions"
            id={`radio${index}`}
            value={option}
            onChange={handleRadioChange}
            checked={option === selectedOption}
          />
          <label className="form-check-label" htmlFor={`radio${index}`}>
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};

CustomRadioButton.defaultProps = {
  options: ["One Way Trip", "Round Trip", " Monthly", "Corporate", "Weeding"],
};

export default CustomRadioButton;
