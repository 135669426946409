import { createStore,applyMiddleware, compose } from "redux";
import rootReducer from './reducers'
import thunk from 'redux-thunk';
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default initialState => {
    initialState = JSON.parse(window.localStorage.getItem('state')) || initialState;
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk))
    );
    store.subscribe(() => {
        const state = store.getState();
        window.localStorage.setItem('state', JSON.stringify(state));
    });
    return store;
}
