import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import product1 from "../../assets/imgs/shop/parts-1.jpg";
import product2 from "../../assets/imgs/shop/parts-2.jpg";
import product3 from "../../assets/imgs/shop/parts-3.jpg";
import product4 from "../../assets/imgs/shop/parts-4.jpg";
import product5 from "../../assets/imgs/shop/parts-5.jpg";
import product6 from "../../assets/imgs/shop/parts-1.jpg";
import product7 from "../../assets/imgs/shop/parts-2.jpg";
import product8 from "../../assets/imgs/shop/parts-3.jpg";

function FlashSale() {
    const options = {
        navText: ["Prev", "Next"],
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            768: {
                items:3,
            },
            1024: {
                items: 4,
            },
            1280: {
                items: 5,
            },
            1366: {
                items: 5,
            },
        }
    };
        return (
            <div>
                <section className="section-padding pb-5">
                    <div className="container">
                        <div className="section-title wow animate__animated animate__fadeIn">
                            <h4 className=""> Flash Sales </h4>

                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 wow animate__animated animate__fadeIn" data-wow-delay=".4s">
                                <OwlCarousel className='owl-theme'
                                             loop
                                             margin={25}
                                             nav={true}
                                             dots={false}
                                             autoplay={false}
                                             autoplayTimeout={5000}
                                             {...options}>
                                    <div className="product-cart-wrap">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img"
                                                         src={product1} alt=""/>
                                                    <img className="hover-img"
                                                         src={product2} alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <button aria-label="Quick view" className="action-btn small hover-up"
                                                        data-bs-toggle="modal" data-bs-target="#quickViewModal"> <i
                                                    className="fi-rs-eye"></i></button>
                                                <Link aria-label="Add To Wishlist"
                                                      className="action-btn small hover-up"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn small hover-up"
                                                      to=""><i className="fi-rs-shuffle"></i></Link>
                                            </div>
                                            <div
                                                className="product-badges product-badges-position product-badges-mrg">
                                                <span className="new">Save 35%</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Hodo Foods</Link>
                                            </div>
                                            <h2><Link to="">Car engine
                                                Toyota AXIO</Link></h2>
                                            <div className="product-rate d-inline-block">
                                                <div className="product-rating"></div>
                                            </div>
                                            <div className="product-price mt-10">
                                                <span>$238.85 </span>
                                                <span className="old-price">$245.8</span>
                                            </div>
                                            <div className="sold mt-15 mb-15">
                                                <div className="progress mb-5">
                                                    <div className="progress-bar" role="progressbar"
                                                         aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                                <span className="font-xs text-heading"> Sold: 90/120</span>
                                            </div>
                                            <Link to="" className="btn w-100 hover-up"><i
                                                className="fi-rs-shopping-cart mr-5"></i>Add To Cart</Link>
                                        </div>
                                    </div>
                                    <div className="product-cart-wrap">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img"
                                                         src={product3} alt=""/>
                                                    <img className="hover-img"
                                                         src={product4} alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <button aria-label="Quick view" className="action-btn small hover-up"
                                                        data-bs-toggle="modal" data-bs-target="#quickViewModal"> <i
                                                    className="fi-rs-eye"></i></button>
                                                <Link aria-label="Add To Wishlist"
                                                      className="action-btn small hover-up"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn small hover-up"
                                                      to=""><i className="fi-rs-shuffle"></i></Link>
                                            </div>
                                            <div
                                                className="product-badges product-badges-position product-badges-mrg">
                                                <span className="new">Save 35%</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Hodo Foods</Link>
                                            </div>
                                            <h2><Link to="">Car Parts
                                                Toyota</Link></h2>
                                            <div className="product-rate d-inline-block">
                                                <div className="product-rating"></div>
                                            </div>
                                            <div className="product-price mt-10">
                                                <span>$238.85 </span>
                                                <span className="old-price">$245.8</span>
                                            </div>
                                            <div className="sold mt-15 mb-15">
                                                <div className="progress mb-5">
                                                    <div className="progress-bar" role="progressbar"
                                                         aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                                <span className="font-xs text-heading"> Sold: 90/120</span>
                                            </div>
                                            <Link to="" className="btn w-100 hover-up"><i
                                                className="fi-rs-shopping-cart mr-5"></i>Add To Cart</Link>
                                        </div>
                                    </div>
                                    <div className="product-cart-wrap">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img"
                                                         src={product5} alt=""/>
                                                    <img className="hover-img"
                                                         src={product6} alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <button aria-label="Quick view" className="action-btn small hover-up"
                                                        data-bs-toggle="modal" data-bs-target="#quickViewModal"> <i
                                                    className="fi-rs-eye"></i></button>
                                                <Link aria-label="Add To Wishlist"
                                                      className="action-btn small hover-up"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn small hover-up"
                                                      to=""><i className="fi-rs-shuffle"></i></Link>
                                            </div>
                                            <div
                                                className="product-badges product-badges-position product-badges-mrg">
                                                <span className="new">Save 35%</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Hodo Foods</Link>
                                            </div>
                                            <h2><Link to="">Car Parts
                                                Toyota</Link></h2>
                                            <div className="product-rate d-inline-block">
                                                <div className="product-rating"></div>
                                            </div>
                                            <div className="product-price mt-10">
                                                <span>$238.85 </span>
                                                <span className="old-price">$245.8</span>
                                            </div>
                                            <div className="sold mt-15 mb-15">
                                                <div className="progress mb-5">
                                                    <div className="progress-bar" role="progressbar"
                                                         aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                                <span className="font-xs text-heading"> Sold: 90/120</span>
                                            </div>
                                            <Link to="" className="btn w-100 hover-up"><i
                                                className="fi-rs-shopping-cart mr-5"></i>Add To Cart</Link>
                                        </div>
                                    </div>
                                    <div className="product-cart-wrap">
                                        <div className="product-img-action-wrap">
                                            <div className="product-img product-img-zoom">
                                                <Link to="">
                                                    <img className="default-img"
                                                         src={product7} alt=""/>
                                                    <img className="hover-img"
                                                         src={product8} alt=""/>
                                                </Link>
                                            </div>
                                            <div className="product-action-1">
                                                <button aria-label="Quick view" className="action-btn small hover-up"
                                                        data-bs-toggle="modal" data-bs-target="#quickViewModal"> <i
                                                    className="fi-rs-eye"></i></button>
                                                <Link aria-label="Add To Wishlist"
                                                      className="action-btn small hover-up"
                                                      to=""><i className="fi-rs-heart"></i></Link>
                                                <Link aria-label="Compare" className="action-btn small hover-up"
                                                      to=""><i className="fi-rs-shuffle"></i></Link>
                                            </div>
                                            <div
                                                className="product-badges product-badges-position product-badges-mrg">
                                                <span className="new">Save 35%</span>
                                            </div>
                                        </div>
                                        <div className="product-content-wrap">
                                            <div className="product-category">
                                                <Link to="">Hodo Foods</Link>
                                            </div>
                                            <h2><Link to="">Car Parts
                                                Toyota</Link></h2>
                                            <div className="product-rate d-inline-block">
                                                <div className="product-rating"></div>
                                            </div>
                                            <div className="product-price mt-10">
                                                <span>$238.85 </span>
                                                <span className="old-price">$245.8</span>
                                            </div>
                                            <div className="sold mt-15 mb-15">
                                                <div className="progress mb-5">
                                                    <div className="progress-bar" role="progressbar"
                                                         aria-valuemin="0"
                                                         aria-valuemax="100"></div>
                                                </div>
                                                <span className="font-xs text-heading"> Sold: 90/120</span>
                                            </div>
                                            <Link to="" className="btn w-100 hover-up"><i
                                                className="fi-rs-shopping-cart mr-5"></i>Add To Cart</Link>
                                        </div>
                                    </div>
                                </OwlCarousel>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
}

export default FlashSale
