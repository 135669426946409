import React, { useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Grid } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';

import Catagori01 from "../../assets/images/product/p1.png"
import Catagori02 from "../../assets/images/product/p2.png"
import Catagori03 from "../../assets/images/product/p3.png"
import Catagori04 from "../../assets/images/product/p4.png"
import Catagori05 from "../../assets/images/product/p5.png"
import Catagori06 from "../../assets/images/product/p6.png"

function ServicesProductSpecialOffers() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const options = {
        navText: ["Prev", "Next"],
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            600: {
                items: 2,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 4,
            },
            1280: {
                items: 4,
            },
            1366: {
                items: 4,
            },
        }
    };
    const showModal = () => {
        console.log("999999999999")
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const closemodal = () => {
        setIsModalOpen(false);
    };
    return (
        <div>
            <div className='home_category_area'>
                <Container>
                    <Row>
                        <Col>
                            <div className='setion____title'>
                                <h3> Special <span> Offers </span> </h3>
                            </div>
                            <OwlCarousel className='owl-theme'
                                loop
                                margin={10}
                                nav={false}
                                dots={false}
                                autoplay={false}
                                autoplayTimeout={5000}
                                {...options}>
                                <div className='item'>
                                    <div className='card card_slider service__full_box' >
                                        <div className='qodef_e_inner'>
                                            <h3 className="productCatagori_top"> Services Name </h3>
                                            <img src={Catagori01} alt="" />
                                            <div onClick={showModal} className='product__add_tocard'>
                                                View Details
                                            </div>
                                            <Link className='woocommerce-LoopProduct-link woocommerce-loop-product__link' to="#"></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='card card_slider service__full_box'>
                                        <div className='qodef_e_inner'>
                                            <h3 className="productCatagori_top"> Services Name <span>New </span></h3>
                                            <img className='img-fit' src={Catagori02} alt="" />
                                            <div onClick={showModal} className='product__add_tocard'>
                                                View Details
                                            </div>
                                            <Link className='woocommerce-LoopProduct-link woocommerce-loop-product__link' to="#"></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='card card_slider service__full_box'>
                                        <div className='qodef_e_inner'>
                                            <h3 className="productCatagori_top"> Services Name <span>New </span></h3>
                                            <img src={Catagori03} alt="" />
                                            <div onClick={showModal} className='product__add_tocard'>
                                                View Details
                                            </div>
                                            <Link className='woocommerce-LoopProduct-link woocommerce-loop-product__link' to="#"></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='card card_slider service__full_box'>
                                        <div className='qodef_e_inner'>
                                            <h3 className="productCatagori_top"> Services Name </h3>
                                            <img src={Catagori04} alt="" />
                                            <div onClick={showModal} className='product__add_tocard'>
                                                View Details
                                            </div>
                                            <Link className='woocommerce-LoopProduct-link woocommerce-loop-product__link' to="#"></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='card card_slider service__full_box'>
                                        <div className='qodef_e_inner'>
                                            <h3 className="productCatagori_top"> Services Name <span>New </span></h3>
                                            <img src={Catagori05} alt="" />
                                            <div onClick={showModal} className='product__add_tocard'>
                                                View Details
                                            </div>
                                            <Link className='woocommerce-LoopProduct-link woocommerce-loop-product__link' to="#"></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='card card_slider service__full_box'>
                                        <div className='qodef_e_inner'>
                                            <h3 className="productCatagori_top"> Services Name </h3>
                                            <img src={Catagori06} alt="" />
                                            <div onClick={showModal} className='product__add_tocard'>
                                                View Details
                                            </div>
                                            <Link className='woocommerce-LoopProduct-link woocommerce-loop-product__link' to="#"></Link>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </Col>
                    </Row>
                </Container>
                {/* SERVICES DETAILS  */}
                <Dialog onClose={closemodal} open={isModalOpen}>
                    <form>
                        <DialogTitle > Services Name <Link className='spacial_book_link' to="/services-booking">  Book Service Schedule  </Link>   </DialogTitle>
                        <div className='services__model_area'>
                            <DialogContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={8} sm={8}>
                                        <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                        <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry.      Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                        <h5> Services Cost : 3500/- </h5>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <img className="img-fit" src={Catagori01} alt="" />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12}>
                                                <h6 onClick={closemodal} className='m_cls_div' > Cancel </h6>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </div>
                    </form >
                </Dialog>
            </div>
        </div>
    )
}

export default ServicesProductSpecialOffers