import React from "react";
import { Link } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PaidIcon from "@mui/icons-material/Paid";
import ShareIcon from "@mui/icons-material/Share";
import GoogleMap from "../../../../Components/common/GoogleMap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

const MechanicListItem = ({ imgUrl, title }) => {
  return (
    <div class="loop-grid loop-list mb-30 mt-30">
      <article class="wow fadeIn animated hover-up mb-30 animated">
        <div
          class="post-thumb"
          style={{
            backgroundImage: imgUrl,
            minHeight: 300,
          }}
        >
          <div class="entry-meta">
            <Link class="entry-meta meta-2" to="/rent-car-booking/details">
              <i class="fi-rs-play-alt"></i>
            </Link>
          </div>
        </div>
        <div class="py-2 px-4 pl-40 w-100">
          <h3 class="d-flex justify-content-between align-items-center flex-wrap post-title mb-10">
            <Link to="/booking/mechanic/details">{title}</Link>

            <div className="product-rate">
              <div className="product-rating"></div>
            </div>
          </h3>

          <div className="d-flex justify-content-between align-items-center flex-wrap gap-2 mb-3">
            <h5 className="text-brand">Years of experience: 2</h5>
            <span className="d-flex align-items-center ">
              <CallIcon
                sx={{
                  fontSize: 22,
                  color: "black",
                }}
                className=""
              />
              <h5 className=""> 0174121447</h5>
            </span>
          </div>

          <hr />

          <div className="row">
            <div className="col-xl-7">
              <span className="d-flex align-items-center gap-2 mb-2">
                <TaskAltIcon
                  sx={{
                    fontSize: 18,
                  }}
                  className="text-brand"
                />
                <h6>Jobs: 50</h6>
              </span>

              <div className="mb-2">
                <span className="d-flex align-items-center gap-2 mb-2">
                  <LocationOnIcon
                    sx={{
                      fontSize: 18,
                    }}
                    className="text-danger"
                  />
                  <h6>Distance: 0.3 km</h6>
                </span>

                <span className="d-flex align-items-center gap-2 mb-2">
                  <PaidIcon
                    sx={{
                      fontSize: 18,
                    }}
                    className="text-warning"
                  />
                  <h6>Per day: 1000</h6>
                </span>
              </div>
            </div>

            <div className="col-xl-5">
              <GoogleMap height="100px" />
            </div>
          </div>

          <div class="entry-meta meta-1 font-xs color-grey mt-10 pb-10">
            <div className="d-flex align-items-center justify-content-between flex-wrap gap-4">
              <Link
                to="shop-grid-right.html"
                className="d-flex align-items-center gap-2"
              >
                <ShareIcon
                  sx={{
                    fontSize: 14,
                  }}
                  color="primary"
                />
                <p>Share</p>
              </Link>

              <Link
                to="shop-grid-right.html"
                className="d-flex align-items-center gap-2"
              >
                <FavoriteIcon
                  sx={{
                    fontSize: 14,
                    color: "red",
                  }}
                />
                <p>Favorite</p>
              </Link>
            </div>

            <Link to="/booking/mechanic/details" className="btn btn-xs mt-3">
              View Details <i className="fi-rs-arrow-small-right"></i>
            </Link>
            {/* <a
                    href="blog-post-right.html"
                    class="text-brand font-heading font-weight-bold"
                  >
                    View Details <i class="fi-rs-arrow-right"></i>
                  </a> */}
          </div>
        </div>
      </article>
    </div>
  );
};

MechanicListItem.defaultProps = {
  title: "Rasel Ahmed",
  imgUrl:
    "url('https://images.unsplash.com/photo-1504222490345-c075b6008014?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80')",
};
export default MechanicListItem;
