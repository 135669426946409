import React from "react";
import CardWithHeader from "../../../Components/common/card/CardWithHeader";
import InputWithLabel from "../../../Components/common/input/InputWithLabel";
import { Form } from "react-bootstrap";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import GoogleMap from "../../../Components/common/GoogleMap";

const AccountDetails = () => {
  return (
    <div
      class="tab-pane fade"
      id="account-detail"
      role="tabpanel"
      aria-labelledby="account-detail-tab"
    >
      <div class="card">
        <div class="card-header">
          <h5>Account Details</h5>
        </div>
        <div class="card-body">
          <Form method="post" name="enq">
            <div class="row">
              <div className="col-md-6 mb-25">
                <CardWithHeader headerTitle="Account Credentials">
                  <InputWithLabel
                    label="User Name"
                    placeholder="Type your User Name"
                    required
                  />

                  <InputWithLabel
                    label="Password"
                    type="password"
                    placeholder="Type your Password"
                    required
                  />

                  <InputWithLabel
                    label="Confirm Password"
                    type="password"
                    placeholder="Type your Password"
                    required
                  />
                </CardWithHeader>
              </div>
              <div className="col-md-6 mb-25">
                <CardWithHeader headerTitle="Profile Picture">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="rounded-3 text-center border border-3 border-dashed cursor-pointer mb-3 py-3">
                        <CameraAltIcon sx={{ fontSize: 50, color: "black" }} />
                        <h6>Web Cam</h6>
                      </div>

                      <div className="rounded-3 text-center border border-2 cursor-pointer py-3">
                        <AddIcon sx={{ fontSize: 50, color: "black" }} />
                        <h6>From File</h6>
                      </div>
                    </div>

                    <div className="col-md-7">
                      <img
                        src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=880&q=80"
                        alt=""
                      />
                    </div>
                  </div>
                </CardWithHeader>
              </div>

              <div className="col-md-6 mb-25">
                <CardWithHeader headerTitle="User Profile">
                  <InputWithLabel
                    label="First Name"
                    placeholder="Type your First Name"
                    required
                  />

                  <InputWithLabel
                    label="Last Name"
                    placeholder="Type your Last Name"
                    required
                  />

                  <InputWithLabel
                    label="Mobile No"
                    type="tel"
                    placeholder="01XXXXXXXXX"
                    required
                  />

                  <InputWithLabel
                    label="Email"
                    type="email"
                    placeholder="example@demo.com"
                  />

                  <InputWithLabel label="DOB" placeholder="DD/MM/YYYY" />

                  <InputWithLabel label="NID No." placeholder="XXXXXXXX" />
                </CardWithHeader>
              </div>

              <div className="col-md-6 mb-25">
                <CardWithHeader headerTitle="Address">
                  <InputWithLabel
                    label="House No"
                    placeholder="Type your House No"
                  />

                  <InputWithLabel
                    label="Road No"
                    placeholder="Type your Road No"
                  />

                  <InputWithLabel
                    label="Mobile No"
                    type="tel"
                    placeholder="01XXXXXXXXX"
                  />

                  <InputWithLabel
                    label="Division"
                    component="select"
                    defaultOption="Dhaka"
                  />

                  <InputWithLabel
                    label="District"
                    component="select"
                    defaultOption="Dhaka"
                  />
                  <InputWithLabel
                    label="Upozilla / City Corporation"
                    component="select"
                    defaultOption="North City Corporation"
                  />

                  <InputWithLabel
                    label="Union / Poroshova"
                    component="select"
                    defaultOption="Gulshan 1"
                  />
                </CardWithHeader>
              </div>

              <div className="col-md-12 mb-25">
                <CardWithHeader headerTitle="Location Map">
                  <div className="row">
                    <div className="col-xl-4">
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          class="form-control form-control-sm h-auto  rounded-1"
                          placeholder="I'm Looking For.."
                          aria-label="I'm Looking For.."
                          aria-describedby="basic-addon2"
                        />
                        <span class="input-group-text" id="basic-addon2">
                          <SearchIcon />
                        </span>
                      </div>

                      <InputWithLabel
                        label="Latitude"
                        placeholder="25.4856"
                        required
                        disabled
                      />

                      <InputWithLabel
                        label="Longitude"
                        placeholder="78.2456"
                        required
                        disabled
                      />
                    </div>

                    <div className="col-xl-8 d-flex justify-content-center">
                      <GoogleMap width="80%" height="100%" />
                    </div>
                  </div>
                </CardWithHeader>
                <div className="text-end mt-20">
                  <button className="btn btn-primary">Submit</button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
