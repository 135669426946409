import React from "react";
import DriverProfileTabNav from "./DriverProfileTabNav";
import DriverProfileTabContent from "./DriverProfileTabContent";

const DriverProfileTab = ({ product }) => {
  return (
    <div className="product-info">
      <div className="tab-style3">
        <DriverProfileTabNav />

        <DriverProfileTabContent product={product} />
      </div>
    </div>
  );
};

export default DriverProfileTab;
