import React from "react";
import axios from "../../Helper/Config";
import {useState} from "react";
import {useEffect} from "react";
import {Link} from "react-router-dom";

import {Cascader, Form, Select} from "antd";

import thumbnail3 from "../../assets/imgs/shop/thumbnail-3.jpg";
import thumbnail4 from "../../assets/imgs/shop/thumbnail-4.jpg";
import thumbnail5 from "../../assets/imgs/shop/thumbnail-5.jpg";
import category1 from "../../assets/imgs/theme/icons/category-1.svg";
import category2 from "../../assets/imgs/theme/icons/category-2.svg";
import category3 from "../../assets/imgs/theme/icons/category-3.svg";
import category4 from "../../assets/imgs/theme/icons/category-4.svg";
import category5 from "../../assets/imgs/theme/icons/category-5.svg";

import banner from "../../assets/imgs/banner/banner-11.png";

const SidebarFilter = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [loactionList, setLoactionList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [modelList, setModelList] = useState([]);
    const [makeList, setMakeList] = useState([]);

    useEffect(() => {
        axios
            .get("/service/center/list")
            .then((res) => {
                setData(res.data.data.services_center.data);
                const bankAllList = res.data.data.services_center.data.map((item) => {
                    return {value: item?.country?.id, label: item?.country?.name};
                });
                console.log(...bankAllList, "bankAllList");
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
        setLoading(false);
        // Location
        locationFunction();
        // Services
        servicesFunction();
        // Make
        makeFunction();
        // Model
        modelFunction();
    }, [count]);

    // Location Area
    const locationOnChange = (value, selectedOptions) => {
        if (value !== undefined) {
            if (value[1] !== undefined) {
                axios
                    .get(`/service/center/list?district_id=${value[1]}`)
                    .then((res) => {
                        console.log(res.data.data.services_center.data, "999999999");
                        setData(res.data.data.services_center.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
            }
        } else {
            console.log("999999");
            setCount(count + 1);
        }
    };

    // Catagories Services
    const servicesonChangeFun = (value) => {
        if (value !== undefined) {
            if (value[1] !== undefined) {
                axios
                    .get(`/service/center/list?categories_id=${value[1]}`)
                    .then((res) => {
                        console.log(res.data.data.services_center.data, "999999999");
                        setData(res.data.data.services_center.data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
            }
        } else {
            console.log("999999");
            setCount(count + 1);
        }
    };

    // Make Area
    const makeFunction = () => {
        axios
            .get("/common/vehicle/make/list")
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                }));
                setMakeList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const makeListOnchangFunction = (e) => {
        if (e !== undefined) {
            axios
                .get(`/service/center/list?make_id=${e}`)
                .then((res) => {
                    console.log(res.data.data.services_center.data, "999999999");
                    setData(res.data.data.services_center.data);
                    // setCount(count + 1)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    // Location Area
    const locationFunction = () => {
        axios
            .get("/common/division-district/list ")
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                    children: category?.districts.map((districts) => ({
                        value: districts?.id,
                        label: districts?.name,
                    })),
                }));
                setLoactionList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    // Services Area
    const servicesFunction = () => {
        axios
            .get("/service/category/list ")
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                    children: category?.sub_category?.map((sub_category) => ({
                        value: sub_category.id,
                        label: sub_category.name,
                    })),
                }));
                setServicesList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    // Model Area
    const modelFunction = () => {
        axios
            .get("/common/vehicle/model/list")
            .then((res) => {
                const options = res?.data?.data.map((category) => ({
                    value: category?.id,
                    label: category?.name,
                }));
                setModelList([...options]);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const modelOnChangFunction = (e) => {
        if (e !== undefined) {
            axios
                .get(`/service/center/list?model_id=${e}`)
                .then((res) => {
                    console.log(res.data.data.services_center.data, "999999999");
                    setData(res.data.data.services_center.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <div>
            <div className="sidebar-widget widget-category-2 mb-30">
                <h5 className="section-title style-1 mb-30">Category</h5>
                <ul>
                    <li>
                        <Link to="shop-grid-right.html">
                            <img src={category1} alt=""/>
                            Milks & Dairies
                        </Link>
                        <span className="count">30</span>
                    </li>
                    <li>
                        <Link to="shop-grid-right.html">
                            <img src={category2} alt=""/>
                            Clothing
                        </Link>
                        <span className="count">35</span>
                    </li>
                    <li>
                        <Link to="shop-grid-right.html">
                            <img src={category3} alt=""/>
                            Pet Foods{" "}
                        </Link>
                        <span className="count">42</span>
                    </li>
                    <li>
                        <Link to="shop-grid-right.html">
                            <img src={category4} alt=""/>
                            Baking material
                        </Link>
                        <span className="count">68</span>
                    </li>
                    <li>
                        <Link to="shop-grid-right.html">
                            <img src={category5} alt=""/>
                            Fresh Fruit
                        </Link>
                        <span className="count">87</span>
                    </li>
                </ul>
            </div>

            <div className="sidebar-widget price_range range mb-30">
                <h5 className="section-title style-1 mb-30">Filter Product</h5>
                <Form form={form} layout="vertical">
                    <Form.Item label="Location" tooltip="Select You Location">
                        <Cascader
                            className="w-100"
                            placeholder="Location"
                            onChange={(e) => locationOnChange(e)}
                            options={loactionList}
                            changeOnSelect
                        />
                    </Form.Item>

                    <Form.Item label="Services" tooltip="Select You Services">
                        <Select
                            className="w-100"
                            onChange={(e) => servicesonChangeFun(e)}
                            placeholder="Make"
                            allowClear
                            options={servicesList}
                        />
                    </Form.Item>

                    <Form.Item label="Make" tooltip="Select Parts Make">
                        <Select
                            className="w-100"
                            onChange={(e) => makeListOnchangFunction(e)}
                            placeholder="Make"
                            allowClear
                            options={makeList}
                        />
                    </Form.Item>
                    <Form.Item label="Model" tooltip="Select Parts Model">
                        <Select
                            className="w-100"
                            onChange={(e) => modelOnChangFunction(e)}
                            placeholder="Model"
                            allowClear
                            options={modelList}
                        />
                    </Form.Item>
                </Form>

                <a
                    href="shop-grid-right.html"
                    className="btn btn-sm btn-default btn-block"
                >
                    <i className="fi-rs-filter mr-5"></i> Fillter
                </a>
            </div>

            {/*<div className="sidebar-widget product-sidebar mb-30 p-30 bg-grey border-radius-10">*/}
            {/*  <h5 className="section-title style-1 mb-30">New products</h5>*/}
            {/*  <div className="single-post clearfix">*/}
            {/*    <div className="image">*/}
            {/*      <img src={thumbnail3} alt="#" />*/}
            {/*    </div>*/}
            {/*    <div className="content pt-10">*/}
            {/*      <h5>*/}
            {/*        <a href="shop-product-detail.html">Chen Cardigan</a>*/}
            {/*      </h5>*/}
            {/*      <p className="price mb-0 mt-5">$99.50</p>*/}
            {/*      <div className="product-rate">*/}
            {/*        <div className="product-rating"></div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="single-post clearfix">*/}
            {/*    <div className="image">*/}
            {/*      <img src={thumbnail4} alt="#" />*/}
            {/*    </div>*/}
            {/*    <div className="content pt-10">*/}
            {/*      <h6>*/}
            {/*        <a href="shop-product-detail.html">Chen Sweater</a>*/}
            {/*      </h6>*/}
            {/*      <p className="price mb-0 mt-5">$89.50</p>*/}
            {/*      <div className="product-rate">*/}
            {/*        <div className="product-rating"></div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className="single-post clearfix">*/}
            {/*    <div className="image">*/}
            {/*      <img src={thumbnail5} alt="#" />*/}
            {/*    </div>*/}
            {/*    <div className="content pt-10">*/}
            {/*      <h6>*/}
            {/*        <a href="shop-product-detail.html">Colorful Jacket</a>*/}
            {/*      </h6>*/}
            {/*      <p className="price mb-0 mt-5">$25</p>*/}
            {/*      <div className="product-rate">*/}
            {/*        <div className="product-rating"></div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className="banner-img wow fadeIn mb-lg-0 animated d-lg-block d-none">*/}
            {/*  <img src={banner} alt="" />*/}
            {/*  <div className="banner-text">*/}
            {/*    <span>Oganic</span>*/}
            {/*    <h4>*/}
            {/*      Save 17% <br />*/}
            {/*      on <span className="text-brand">Oganic</span>*/}
            {/*      <br />*/}
            {/*      Juice*/}
            {/*    </h4>*/}
            {/*  </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default SidebarFilter;
