import React, { useEffect, useState } from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import axios from "../../Helper/Config";
import { apiImgUrl } from "../../Helper/BaseUrl";
import NoImg from "../../assets/images/noImg.png"
import {
    RiStackshareLine,
    RiHeartAddFill,
    RiCustomerService2Fill,
    RiEyeFill,
    RiCheckFill,
    RiShoppingCartLine
} from "react-icons/ri";
import {ADD} from "../../reduxServices/actions/CartActions";
import {useDispatch} from "react-redux";
import {Empty} from "antd";

function PartsSellerDetails() {
    const {slug} = useParams();
    const [profile, setSellerProfile] = useState([]);
    const [products, setSellerProducts] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        axios.get(`/seller/parts-seller-details/${slug}`)
        .then((res) => {
            setSellerProfile(res?.data?.data?.profile_details)
            setSellerProducts(res?.data?.data?.product)

        }).catch((err) => {
            console.log(err)
        })
    }, [])
    const getDataCart = (e) => {
        dispatch(ADD(e));
    }

    console.log(profile, 'data data alldata')
    return (
        <div>
            <div className='s_p_Details'>
                { profile.banners ? <>
                    <Carousel>

                            {profile.banners?.map((item, i) =>
                                <Carousel.Item key={i}>
                                    <img
                                        className="d-block w-100"
                                        src={apiImgUrl + item?.image_path}
                                        alt="{profile?.shop_name}"
                                    />
                                </Carousel.Item>
                            )}

                    </Carousel>
                </> : <>
                    <img className='no_img__banner' src={NoImg} alt="Images" />
                </>}
            </div>


            <div className='top_banner_area_parts' >

                <Container>
                    <Row>
                        <Col sm={12} className="d-flex align-items-center">
                            <div className="seller-logo">
                                <Link to="/"> <img className='logo' src={apiImgUrl + profile?.shop_logo}  alt="" /></Link>
                            </div>
                            <div className="seller-info">
                                <h4 className="seller-name">{profile?.shop_name} </h4>
                                <p className="seller-address">{profile?.address} </p>
                                <p className="seller-address">{profile?.union?.name}, {profile?.thana?.name}, {profile?.district?.name}</p>
                            </div>
                            <div className="ml-auto">
                                <div className="seller-contact"><RiCustomerService2Fill color="red"/> {profile?.phone}</div>
                                <div className="seller-media">
                                    <Link  to="/" className="media"><RiStackshareLine size={20} color="red">Share </RiStackshareLine> Share</Link>
                                    <Link  to="/" className="media"><RiHeartAddFill size={20} color="red">Share </RiHeartAddFill> Favourite</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>


            <div className='s_p_d_left_box_area'>
                <Container>
                    <Row>
                        <Col>
                            <div className='setion____title  mt-4'>
                                <h3> Product <span> List </span> </h3>
                            </div>
                            <div>
                                {products?.length > 0 ? <>
                                <Row>
                                    {products?.map((item, i) =>
                                        <Col key={i} className='mb-3' lg={2}>
                                            <div className='single_parts_area'>
                                                <div className='image__body'>
                                                    <Link to={`/product-details/${item?.slug}`}>
                                                        <img className='image__body img-fit' src={apiImgUrl + item?.thumbnail} alt="" />
                                                    </Link>
                                                </div>
                                                <div className='product__icon__'>
                                                    <button> <RiEyeFill /></button>
                                                    {/* <button><RiHeartFill /> </button> */}
                                                </div>
                                                <div className='product-card__info'>
                                                    <div className='product-card_sku' style={{ paddingBottom: '6px' }}>
                                                        <span>Make :</span>   {item?.make?.name}
                                                        <p style={{ width: 'auto', float: 'right' }}> <span>Model :</span>   {item?.model?.name} </p>
                                                    </div>
                                                    <div className='product-card__name'>
                                                        <Link to={`/product-details/${item?.slug}`}> {item?.name} </Link>
                                                    </div>
                                                </div>
                                                <div className='product-card__footer'>
                                                    <h3>
                                                        {item?.discount_price > 0 ? <>
                                                            ৳{item?.discount_price}
                                                            <del style={{ fontSize: '12px', color: '#222', marginLeft: '5px' }}> ৳{item?.selling_price} </del>
                                                        </> : <>  ৳{item?.selling_price} </>}
                                                    </h3>
                                                    {/* Shoping Cart  */}
                                                    {item?.qnty > 0 ? <> <RiCheckFill className='svg__hover' /> </> : <> <RiShoppingCartLine onClick={() => getDataCart(item)} className='svg__hover' />  </>}
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                                </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='s_p_d_left_box_area mb-4'>
                <Container>
                    <Row>
                        <Col>
                            <div className='setion____title  mt-4'>
                                <h3> Flash <span> Sale </span> </h3>
                            </div>
                            <div>
                                {products?.length > 0 ? <>
                                    <Row>
                                        {products?.map((item, i) =>
                                            <Col key={i} className='mb-3' lg={2}>
                                                <div className='single_parts_area'>
                                                    <div className='image__body'>
                                                        <Link to={`/product-details/${item?.slug}`}>
                                                            <img className='image__body img-fit' src={apiImgUrl + item?.thumbnail} alt="" />
                                                        </Link>
                                                    </div>
                                                    <div className='product__icon__'>
                                                        <button> <RiEyeFill /></button>
                                                        {/* <button><RiHeartFill /> </button> */}
                                                    </div>
                                                    <div className='product-card__info'>
                                                        <div className='product-card_sku' style={{ paddingBottom: '6px' }}>
                                                            <span>Make :</span>   {item?.make?.name}
                                                            <p style={{ width: 'auto', float: 'right' }}> <span>Model :</span>   {item?.model?.name} </p>
                                                        </div>
                                                        <div className='product-card__name'>
                                                            <Link to={`/product-details/${item?.slug}`}> {item?.name} </Link>
                                                        </div>
                                                    </div>
                                                    <div className='product-card__footer'>
                                                        <h3>
                                                            {item?.discount_price > 0 ? <>
                                                                ৳{item?.discount_price}
                                                                <del style={{ fontSize: '12px', color: '#222', marginLeft: '5px' }}> ৳{item?.selling_price} </del>
                                                            </> : <>  ৳{item?.selling_price} </>}
                                                        </h3>
                                                        {/* Shoping Cart  */}
                                                        {item?.qnty > 0 ? <> <RiCheckFill className='svg__hover' /> </> : <> <RiShoppingCartLine onClick={() => getDataCart(item)} className='svg__hover' />  </>}
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                </> : <> <div className='dataEmtey'> <Empty /> </div> </>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div >
    )
}

export default PartsSellerDetails
