import React from "react";
import SidebarFilter from "../common/SidebarFilter";
import RentCarFilter from "../../Pages/Services/RentCarBooking/RentCarFilter";

const BookingLanding = ({ children }) => {
  return (
    <div className="container mt-30">
      <div className="row flex-row-reverse">
        <div className="col-lg-4-5">
          <RentCarFilter />

          {children}

          <div className="pagination-area mt-20 mb-20">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="fi-rs-arrow-small-left"></i>
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    1
                  </a>
                </li>
                <li className="page-item active">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link dot" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    6
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="fi-rs-arrow-small-right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="col-lg-1-5 primary-sidebar sticky-sidebar">
          <SidebarFilter />
        </div>
      </div>
    </div>
  );
};

export default BookingLanding;
