import React from "react";

const RentCarFilter = ({ title = "items" }) => {
  return (
    <input
      type="text"
      style={{ height: "45px" }}
      height={45}
      placeholder={`Search for ${title}...`}
    />
  );
};

export default RentCarFilter;

// <div className="d-flex justify-content-between align-items-center">
//   <div className="d-flex align-items-center gap-5">
//     {/* <div class="chek-form">
//       <div class="custome-checkbox" style={{ width: "130px" }}>
//         <input
//           class="form-check-input"
//           type="checkbox"
//           name="checkbox"
//           id="exampleCheckbox1"
//           value=""
//           checked
//         />
//         <label class="form-check-label" for="exampleCheckbox1">
//           <span>Sort by Near Me</span>
//         </label>
//       </div>
//     </div> */}
//     <input
//       type="text"
//       style={{ height: "45px" }}
//       height={45}
//       placeholder="Search for items..."
//     />
//   </div>

// </div>
