import React from "react";
import BuySellCompare from "./BuySellCompare";
import CarPartsCompare from "./CarPartsCompare";
import DriverCompare from "./DriverCompare";
import MechanicCompare from "./MechanicCompare";
import RentCarCompare from "./RentCarCompare";

const Compare = () => {
  return (
    <div>
      <RentCarCompare />
      <MechanicCompare />
      <DriverCompare />
      <CarPartsCompare />
      <BuySellCompare />
    </div>
  );
};

export default Compare;
