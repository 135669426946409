import React from "react";
import { Link, useParams } from "react-router-dom";

const OtpModal = ({
  modalTitle = "Confirm Booking",
  onClose,
  onSubmit,
  data,
}) => {
  const modalStyle = {
    display: "block",
    backgroundColor: "rgba(0,0,0, .7)",
  };

  return (
    <div class="modal fade show" style={modalStyle}>
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">{modalTitle}</h3>
          </div>

          <div class="modal-body">
            <div class="container">
              <div class="position-relative">
                <div class="p-2 text-center">
                  <h6>Please enter the one time password</h6>
                  <div className="">
                    <span>A code has been sent to</span>
                    <small>*******9897</small>
                  </div>

                  <div
                    id="otp"
                    class="inputs d-flex flex-row justify-content-center mt-2"
                  >
                    <input
                      class="m-2 text-center form-control rounded"
                      type="text"
                      id="first"
                      maxlength="1"
                    />
                    <input
                      class="m-2 text-center form-control rounded"
                      type="text"
                      id="second"
                      maxlength="1"
                    />
                    <input
                      class="m-2 text-center form-control rounded"
                      type="text"
                      id="third"
                      maxlength="1"
                    />
                    <input
                      class="m-2 text-center form-control rounded"
                      type="text"
                      id="fourth"
                      maxlength="1"
                    />
                    <input
                      class="m-2 text-center form-control rounded"
                      type="text"
                      id="fifth"
                      maxlength="1"
                    />
                    <input
                      class="m-2 text-center form-control rounded"
                      type="text"
                      id="sixth"
                      maxlength="1"
                    />
                  </div>

                  <div class="content d-flex justify-content-center align-items-center gap-3">
                    <span>
                      Didn't get the code -{" "}
                      <a href="#" class="text-decoration-none">
                        Resend OTP
                      </a>
                    </span>

                    <span className="text-danger">160s Left</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onClose}
            >
              Cancel
            </button>

            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={onSubmit}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpModal;
