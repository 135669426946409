import React from 'react'
import product1 from "../../assets/imgs/shop/parts-2.jpg";
import product2 from "../../assets/imgs/shop/parts-3.jpg";
import product3 from "../../assets/imgs/shop/car-3.webp";
import product4 from "../../assets/imgs/shop/parts-1.jpg";
import product5 from "../../assets/imgs/shop/product-3-1.jpg";
import product6 from "../../assets/imgs/shop/product-3-1.jpg";
import product7 from "../../assets/imgs/shop/product-4-1.jpg";
import product8 from "../../assets/imgs/shop/product-4-1.jpg";
import {Link} from "react-router-dom";

function HotDeals() {
  return (
      <div>
          <section className="section-padding  mt-65 mb-65">
              <div className="container">
                  <div className="row">
                      <div  className="col-xl-4 col-lg-4 col-md-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp"
                          data-wow-delay="0">
                          <h4 className="section-title style-1 mb-30 animated animated"> Hot Deals </h4>
                          <div className="product-list-small animated animated">
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to="">
                                          <img src={product4} alt=""/>
                                      </Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="shop-product-right.html">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating"></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to=""><img src={product3} alt=""/></Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating" ></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to=""><img src={product4}  alt=""/></Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating" ></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                          </div>
                      </div>

                      <div  className="col-xl-4 col-lg-4 col-md-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp"
                            data-wow-delay="0">
                          <h4 className="section-title style-1 mb-30 animated animated"> Special Offer </h4>
                          <div className="product-list-small animated animated">
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to="">
                                          <img src={product1} alt=""/>
                                      </Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="shop-product-right.html">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating"></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to=""><img src={product4} alt=""/></Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating" ></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to=""><img src={product2}  alt=""/></Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating" ></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                          </div>
                      </div>

                      <div  className="col-xl-4 col-lg-4 col-md-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp"
                            data-wow-delay="0">
                          <h4 className="section-title style-1 mb-30 animated animated"> Recently added </h4>
                          <div className="product-list-small animated animated">
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to="">
                                          <img src={product4} alt=""/>
                                      </Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="shop-product-right.html">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating"></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to=""><img src={product3} alt=""/></Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating" ></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to=""><img src={product2}  alt=""/></Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating" ></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                          </div>
                      </div>

                      {/* <div  className="col-xl-3 col-lg-4 col-md-6 mb-sm-5 mb-md-0 wow animate__animated animate__fadeInUp"
                            data-wow-delay="0">
                          <h4 className="section-title style-1 mb-30 animated animated"> Special Deals </h4>
                          <div className="product-list-small animated animated">
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to="">
                                          <img src={product1} alt=""/>
                                      </Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="shop-product-right.html">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating"></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to=""><img src={product2} alt=""/></Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating" ></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                              <article className="row align-items-center hover-up">
                                  <figure className="col-md-4 mb-0">
                                      <Link to=""><img src={product2}  alt=""/></Link>
                                  </figure>
                                  <div className="col-md-8 mb-0">
                                      <h6>
                                          <Link to="">car engine parts
                                              offer</Link>
                                      </h6>
                                      <div className="product-rate-cover">
                                          <div className="product-rate d-inline-block">
                                              <div className="product-rating" ></div>
                                          </div>
                                          <span className="font-small ml-5 text-muted"> (4.0)</span>
                                      </div>
                                      <div className="product-price">
                                          <span>$32.85</span>
                                          <span className="old-price">$33.8</span>
                                      </div>
                                  </div>
                              </article>
                          </div>
                      </div> */}
                  </div>
              </div>
          </section>
      </div>
  )
}

export default HotDeals
