import React from "react";

const SummaryHeader = () => {
  return (
    <thead>
      <tr class="main-heading">
        <th class="custome-checkbox start pl-30">
          <input
            class="form-check-input"
            type="checkbox"
            name="checkbox"
            id="exampleCheckbox11"
            value=""
          />
          <label class="form-check-label" for="exampleCheckbox11"></label>
        </th>
        <th scope="col" colspan="2">
          Product
        </th>
        <th scope="col">Unit Price</th>
        <th scope="col">Quantity</th>
        <th scope="col">Subtotal</th>
        <th scope="col" class="end">
          Remove
        </th>
      </tr>
    </thead>
  );
};

export default SummaryHeader;
