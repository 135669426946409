import React from "react";

const ConfirmationStepContent = () => {
  return (
    <div className="mt-50">
      <div className="row">
        <div className="col-xl-12">
          <table class="table">
            <thead class="table-light">
              <tr>
                <th scope="col">Sl No</th>
                <th scope="col">Product Name</th>
                <th scope="col">Qty.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Back Camera</td>
                <td>1</td>
              </tr>

              <tr>
                <th scope="row">2</th>
                <td>
                  Spoiler <br />
                  <strong>Brand:</strong> Toyota <br />
                  <strong>Model:</strong> Corolla
                </td>
                <td>1</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="col-xl-6 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Notes
          </label>

          <h4>I need warranty on parts</h4>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationStepContent;
