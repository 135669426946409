import React from "react";
import BookingLanding from "../../../../Components/Booking/BookingLanding";
import MechanicListItem from "./MechanicListItem";

const Mechanic = () => {
  return (
    <div>
      <BookingLanding>
        <MechanicListItem />
        <MechanicListItem />
        <MechanicListItem />
        <MechanicListItem />
        <MechanicListItem />
      </BookingLanding>
    </div>
  );
};

export default Mechanic;
