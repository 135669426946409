import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Task", "Hours per Day"],
  ["Parts Seller", 11],
  ["Driver", 7],
  ["Mechanic", 4],
  ["Rent a Car", 8],
  //   ["Sleep", 7],
];

export const options = {
  title: "No of Bid",
};

export default function GoogleChart() {
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      // width={"100%"}
      height={"400px"}
    />
  );
}
