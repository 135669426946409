import React, {useEffect} from 'react'
import { Link, Navigate } from 'react-router-dom'
import {Input, Form, Select, Empty} from 'antd';
import { VscTrash } from "react-icons/vsc";
import {useDispatch, useSelector} from "react-redux";
import {decrease, getCartTotal, increase, remove} from "../reduxServices/actions/CartActions";
import {apiImgUrl} from "../Helper/BaseUrl";

import {COOKIE_NAME, getCookie} from '../Helper/Cookies';
import axios from "../Helper/Config";
import {toast} from "react-toastify";
import BreadCrumb from '../Components/common/BreadCrumb';

function Checkout() {
    const cartslist = useSelector((state) => state.cartItems.carts);
    // const allItem = useSelector((state) => state.cartItems.totalItem);
    const totalAmount  = useSelector((state) => state.cartItems.totalAmount);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getCartTotal());
    }, [cartslist]);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const { Option } = Select;
    const onFinish = (value) => {
        const formData = {
            uid: localStorage.getItem("uid"),
            username: `${value.username}`,
            contact_no: `${value.contact_no}`,
            email: `${value.email}`,
            address: `${value.address}`,
            payment_gateway: `${value.payment_gateway}`,
            cart_item: cartslist,
            sub_total: totalAmount
        };
        axios.interceptors.request.use(
            config => {
                config.headers['Authorization'] = `Bearer ${token}`;
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        axios.post('/onlineorder/order/add', formData)
            .then(res => {
                toast.dark(res.data.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
                localStorage.removeItem("state");
                window.location.href ='./success-order/'+ res.data.data;
            });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('onFinishFailed:', errorInfo);
    };
    const token = getCookie(COOKIE_NAME);
    let username = '';
    if (token == 0) {
        username = '';
    } else {
        username = token;
    }
    if (username) {
        return (
            <div>
                <BreadCrumb category='Page' sub='Checkout' />

                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-txt mt-20 mb-15">
                                    <h1>Checkout Page</h1>
                                </div>
                            </div>
                        </div>                        
                    </div>
                    <div className="breadcrumb-overlay"></div>
                </div>

                <div className="cart-table">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 cart-cards">
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Cart Information</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered  mb-0">
                                                <thead>
                                                <tr>
                                                    <th colSpan="col">Action</th>
                                                    <th colSpan="col">Image</th>
                                                    <th colSpan="col">Title</th>
                                                    <th colSpan="col">Price</th>
                                                    <th colSpan="col" width="100px">Quantity</th>
                                                    <th colSpan="col" width="150px">Total</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {cartslist?.length > 0 ? <>
                                                    {cartslist?.map((item, i) =>
                                                        <tr key={i}>
                                                            <td className="action">
                                                                <VscTrash style={{
                                                                    fontSize: "25px",
                                                                    cursor: "pointer",
                                                                    color: "red"
                                                                }} onClick={() => dispatch(remove(item?.id))}/>
                                                            </td>
                                                            <td>
                                                                <img src={apiImgUrl + item?.thumbnail} alt={item?.name}
                                                                     className="table-img"/>
                                                            </td>
                                                            <td className="title">{item?.name} </td>
                                                            <td className="price">
                                                                {item?.discount_price > 0 ? <>
                                                                    <strong>৳ {item?.discount_price} </strong>&nbsp;
                                                                    <del className="text-danger">
                                                                        <small>৳ {item?.selling_price}</small></del>

                                                                </> : <>
                                                                    <strong>৳ {item?.selling_price}</strong>
                                                                </>}
                                                            </td>
                                                            <td width="100px" className="text-center">
                                                                <div className="quantity__box minicart__quantity">
                                                                    <button onClick={() => dispatch(decrease(item?.id))}
                                                                            type="button"
                                                                            className="quantity__value decrease"
                                                                            aria-label="quantity value"
                                                                            value="Decrease Value">-
                                                                    </button>
                                                                    <label>
                                                                        <input type="number" className="quantity__number"
                                                                               value={item?.qnty}/>
                                                                    </label>
                                                                    {/* <button onClick={quantityInc} type="button" className="quantity__value increase" aria-label="quantity value" value="Increase Value">+</button> */}
                                                                    <button onClick={() => dispatch(increase(item?.id))}
                                                                            type="button"
                                                                            className="quantity__value increase"
                                                                            aria-label="quantity value"
                                                                            value="Increase Value">+
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td className="total">
                                                                <span>৳&nbsp;
                                                                    {item?.discount_price > 0 ? <>
                                                                        {item?.discount_price * item?.qnty}
                                                                    </> : <>
                                                                        {item?.selling_price * item?.qnty}
                                                                    </>}
                                                            </span></td>
                                                        </tr>
                                                    )}
                                                </> : <>
                                                    <tr>
                                                        <td colSpan="100%">
                                                            <div className='dataEmtey'><Empty/></div>
                                                        </td>
                                                    </tr>
                                                </>}

                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="cartbtn-container">
                                                            <Link className="cartbtn shop" to="/product-categories"> Go To
                                                                Shopping </Link>
                                                            <h4 className="mt-1"><strong>Cart Amount: ৳&nbsp;{totalAmount}</strong></h4>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 cart-cards'>
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5>ORDER SUMMARY</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className='order_summary_cntn'>
                                            <h5 className="p-0 mb-2"> Subtotal
                                                ({cartslist?.length} items) <span>  ৳ {totalAmount} </span>
                                            </h5>
                                            <h5 className="p-0"> Shipping Fee <span> ৳ 0.00 </span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header">
                                        <h5>Billing Address</h5>
                                    </div>
                                    <div className="card-body">
                                        <Form
                                            name="basic"
                                            layout="vertical"
                                            className='from_home_area'
                                            form={form}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                        >
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label="Your Name"
                                                        name="username"
                                                        className="form-group form-box"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Your Name!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder='Your Name' className="form-control"/>
                                                    </Form.Item>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label="Contact Number"
                                                        name="contact_no"
                                                        className="form-group form-box"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Contact Number!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder='Contact Number'
                                                               className="form-control"/>
                                                    </Form.Item>
                                                </div>

                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label="Email"
                                                        name="email"
                                                        className="form-group form-box"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Email Address!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder='Email' className="form-control"/>
                                                    </Form.Item>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label="Address"
                                                        name="address"
                                                        className="form-group form-box"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Address!',
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea rows={4} placeholder="Message"/>
                                                    </Form.Item>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <Form.Item
                                                        label="Payment Method"
                                                        name="payment_gateway"
                                                        className="form-group form-box"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Select a gateway!',
                                                            },
                                                        ]}
                                                    >
                                                        <Select>
                                                            <Option value="cashondelivery">Cash On Delivery</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                                <div className="process-to-checkout mt-3">
                                                    <button className="btn btn-primary cartbtn cart" > Proceed to Payment </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else {
        return <Navigate to="/login" />
    }
}

export default Checkout
