import checkoutFormModel from './checkoutFormModel';
const {
  formField: {
    fullName,
    kilometer,
    contactNo,
    problemdetails,
    useraddres,
    make,
    model,
    cardNumber,
  }
} = checkoutFormModel;

export default {
  [fullName?.name]: '',
  [kilometer?.name]: '',
  [contactNo?.name]: '',
  [problemdetails?.name]: '',
  [useraddres?.name]: '',
  [make?.name]: '',
  [model?.name]: '',
  [cardNumber.name]: '',
};
