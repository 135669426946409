import React from "react";
import { Col } from "react-bootstrap";
import { RiChatHistoryLine, RiUserLocationLine } from "react-icons/ri";
import { apiImgUrl } from "../../../../Helper/BaseUrl";
import {
  VscArrowRight,
  VscCallOutgoing,
  VscRemoteExplorer,
} from "react-icons/vsc";
import { Link, useParams } from "react-router-dom";
import Iframe from "react-iframe";

const ServiceRightSide = ({ data }) => {
  const { slug } = useParams();

  return (
    <>
      <div className="primary-sidebar">
        <div class="sidebar-widget widget-category-2 mb-30">
          <h5 class="section-title style-1 mb-30">
            Service Center Information
          </h5>

          <img src={apiImgUrl + data?.company_logo} />

          <h4 style={{ color: "#3BB77E" }} className="mb-2">
            {data?.company_name}{" "}
          </h4>
          <h6 className="mb-2">
            {data?.address}, {data?.thana?.name}, {data?.district?.name} ,{" "}
            {data?.division?.name}, {data?.country?.name}{" "}
          </h6>
          <h5> {data?.phone} </h5>
        </div>

        <div class="sidebar-widget widget-category-2 mb-30">
          <h5 class="section-title style-1 mb-30">Location</h5>

          <Iframe
            url={`https://maps.google.com/maps?q=${data?.latitude},${data?.longitude}&output=embed`}
            width="100%"
            height="150px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </div>

        <div class="sidebar-widget widget-category-2 mb-30">
          <h5 class="section-title style-1 mb-30">24hr Hotline</h5>

          <h5 className="mb-2">Owner Info: </h5>
          <h5 className="mb-2">
            {" "}
            <strong>{data?.company_owner}</strong>{" "}
          </h5>
          <h6 className="mb-2">Email: {data?.email} </h6>
          <h5 className="mb-2"> Mobile: {data?.phone} </h5>
        </div>

        <div class="sidebar-widget widget-category-2 mb-30">
          <h5 class="section-title style-1 mb-30">Book Schedule</h5>

          <p className="mb-15">
            {" "}
            Take it easy driving– the life you save may be mine.{" "}
          </p>

          <div className="button__s_p">
            <Link
              className="hover-up btn btn-sm btn-rounded"
              to={`/services-booking/${slug}`}
            >
              <span> Book Service Schedule </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceRightSide;

{
  /* <div className="">
<div className="card">
  <div className="s_p_banner__bootom_single_box">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="81"
      viewBox="0 0 70 81"
    >
      <path
        d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
        fill="#ed1d24"
      ></path>
    </svg>
    <RiChatHistoryLine className="s_p_icon" />
    <h3> Service Center Information </h3>
  </div>
  <div className="s_p_banner__bootom_single_box__details">
    <img src={apiImgUrl + data?.company_logo} />
    <h4 className="text-danger mb-2">{data?.company_name} </h4>
    <h6 className="mb-2">
      {data?.address}, {data?.thana?.name}, {data?.district?.name} ,{" "}
      {data?.division?.name}, {data?.country?.name}{" "}
    </h6>
    <h5> {data?.phone} </h5>
  </div>
</div>
</div>
<div className="d-flex align-items-stretch">
<div className="card">
  <div className="s_p_banner__bootom_single_box">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="81"
      viewBox="0 0 70 81"
    >
      <path
        d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
        fill="#ed1d24"
      ></path>
    </svg>
    <RiUserLocationLine className="s_p_icon" />
    <h3> Location </h3>
  </div>
  <div className="s_p_banner__bootom_single_box__details">
    <Iframe
      url={`https://maps.google.com/maps?q=${data?.latitude},${data?.longitude}&output=embed`}
      width="100%"
      height="150px"
      id="myId"
      className="myClassname"
      display="initial"
      position="relative"
    />
  </div>
</div>
</div>
<div className="d-flex align-items-stretch">
<div className="card">
  <div className="s_p_banner__bootom_single_box">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="81"
      viewBox="0 0 70 81"
    >
      <path
        d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
        fill="#ed1d24"
      ></path>
    </svg>
    <VscCallOutgoing className="s_p_icon" />
    <h3> 24hr Hotline </h3>
  </div>
  <div
    className="s_p_banner__bootom_single_box__details"
    style={{ textAlign: "center" }}
  >
    <h5 className="mb-2">Owner Info: </h5>
    <h5 className="mb-2">
      {" "}
      <strong>{data?.company_owner}</strong>{" "}
    </h5>
    <h6 className="mb-2">Email: {data?.email} </h6>
    <h5 className="mb-2"> Mobile: {data?.phone} </h5>
  </div>
</div>
</div>
<div className="d-flex align-items-stretch">
<div className="card">
  <div className="s_p_banner__bootom_single_box">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="81"
      viewBox="0 0 70 81"
    >
      <path
        d="M35.062 81a5.498 5.498 0 0 1-2.785-.761L2.865 62.906c-1.727-1.019-2.8-2.919-2.802-4.957L0 23.171c-.004-2.041 1.063-3.944 2.786-4.966L32.134.759C33.826-.248 36.02-.253 37.721.748l29.414 17.334c1.725 1.016 2.798 2.916 2.801 4.955L70 57.815c.003 2.041-1.065 3.943-2.786 4.966L37.865 80.227a5.482 5.482 0 0 1-2.803.773z"
        fill="#ed1d24"
      ></path>
    </svg>
    <VscRemoteExplorer className="s_p_icon" />
    <h3> Book Schedule </h3>
  </div>
  <div className="s_p_banner__bootom_single_box__details">
    <p> Take it easy driving– the life you save may be mine. </p>
    <div className="button__s_p">
      <Link
        className="bookShidul_posihion"
        to={`/services-booking/${slug}`}
      >
        <VscArrowRight />
        <span> Book Service Schedule </span>
      </Link>
    </div>
  </div>
</div>
</div> */
}
