import React from "react";
import { Link } from "react-router-dom";
import vendor1 from "../../../assets/imgs/vendor/vendor-1.png";
import svg from "../../../assets/imgs/theme/icons/icon-contact.svg";
import { useState } from "react";
import { useEffect } from "react";
import { axiosPrivate } from "../../../Helper/Config";
import VendorListHeader from "./VendorListHeader";
import VendorListItem from "./VendorListItem";

function VendorList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosPrivate
      .get("/service/center/list")
      .then((res) => {
        setData(res.data.data.services_center.data);
        const bankAllList = res.data.data.services_center.data.map((item) => {
          return { value: item?.country?.id, label: item?.country?.name };
        });
        console.log(...bankAllList, "bankAllList");
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  console.log(data);

  let content = null;

  if (data.length > 0)
    content = data.map((vendor) => <VendorListItem vendor={vendor} />);

  return (
    <div>
      <div className="container">
        <VendorListHeader />

        <div className="row vendor-grid">
          {content}

          {/* <div className="col-lg-3 col-md-6 col-12 col-sm-6 justify-content-center">
            <div className="vendor-wrap mb-40">
              <div className="vendor-img-action-wrap">
                <div className="vendor-img">
                  <Link to="vendor-details-1.html">
                    <img className="default-img" src={vendor1} alt="" />
                  </Link>
                </div>
                <div className="product-badges product-badges-position product-badges-mrg">
                  <span className="hot">Mall</span>
                </div>
              </div>
              <div className="vendor-content-wrap">
                <div className="d-flex justify-content-between align-items-end mb-30">
                  <div>
                    <div className="product-category">
                      <span className="text-muted">Since 2012</span>
                    </div>
                    <h4 className="mb-5">
                      <Link to="">Nature Food</Link>
                    </h4>
                    <div className="product-rate-cover">
                      <span className="font-small total-product">
                        380 products
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vendor-info mb-30">
                  <ul className="contact-infor text-muted">
                    <li>
                      <img src={svg} alt="" />
                      <strong>Call Us:</strong>
                      <span>(+91) - 540-025-124553</span>
                    </li>
                  </ul>
                </div>
                <Link to="" className="btn btn-xs">
                  Visit Store <i className="fi-rs-arrow-small-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12 col-sm-6 justify-content-center">
            <div className="vendor-wrap mb-40">
              <div className="vendor-img-action-wrap">
                <div className="vendor-img">
                  <Link to="">
                    <img className="default-img" src={vendor1} alt="" />
                  </Link>
                </div>
                <div className="product-badges product-badges-position product-badges-mrg">
                  <span className="hot">Mall</span>
                </div>
              </div>
              <div className="vendor-content-wrap">
                <div className="d-flex justify-content-between align-items-end mb-30">
                  <div>
                    <div className="product-category">
                      <span className="text-muted">Since 2012</span>
                    </div>
                    <h4 className="mb-5">
                      <Link to="">Nature Food</Link>
                    </h4>
                    <div className="product-rate-cover">
                      <span className="font-small total-product">
                        380 products
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vendor-info mb-30">
                  <ul className="contact-infor text-muted">
                    <li>
                      <img src={svg} alt="" />
                      <strong>Call Us:</strong>
                      <span>(+91) - 540-025-124553</span>
                    </li>
                  </ul>
                </div>
                <Link to="" className="btn btn-xs">
                  Visit Store <i className="fi-rs-arrow-small-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12 col-sm-6 justify-content-center">
            <div className="vendor-wrap mb-40">
              <div className="vendor-img-action-wrap">
                <div className="vendor-img">
                  <Link to="">
                    <img className="default-img" src={vendor1} alt="" />
                  </Link>
                </div>
                <div className="product-badges product-badges-position product-badges-mrg">
                  <span className="hot">Mall</span>
                </div>
              </div>
              <div className="vendor-content-wrap">
                <div className="d-flex justify-content-between align-items-end mb-30">
                  <div>
                    <div className="product-category">
                      <span className="text-muted">Since 2012</span>
                    </div>
                    <h4 className="mb-5">
                      <Link to="">Nature Food</Link>
                    </h4>
                    <div className="product-rate-cover">
                      <span className="font-small total-product">
                        380 products
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vendor-info mb-30">
                  <ul className="contact-infor text-muted">
                    <li>
                      <img src={svg} alt="" />
                      <strong>Call Us:</strong>
                      <span>(+91) - 540-025-124553</span>
                    </li>
                  </ul>
                </div>
                <Link to="" className="btn btn-xs">
                  Visit Store <i className="fi-rs-arrow-small-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-12 col-sm-6 justify-content-center">
            <div className="vendor-wrap mb-40">
              <div className="vendor-img-action-wrap">
                <div className="vendor-img">
                  <Link to="">
                    <img className="default-img" src={vendor1} alt="" />
                  </Link>
                </div>
                <div className="product-badges product-badges-position product-badges-mrg">
                  <span className="hot">Mall</span>
                </div>
              </div>
              <div className="vendor-content-wrap">
                <div className="d-flex justify-content-between align-items-end mb-30">
                  <div>
                    <div className="product-category">
                      <span className="text-muted">Since 2012</span>
                    </div>
                    <h4 className="mb-5">
                      <Link to="">Nature Food</Link>
                    </h4>
                    <div className="product-rate-cover">
                      <span className="font-small total-product">
                        380 products
                      </span>
                    </div>
                  </div>
                </div>
                <div className="vendor-info mb-30">
                  <ul className="contact-infor text-muted">
                    <li>
                      <img src={svg} alt="" />
                      <strong>Call Us:</strong>
                      <span>(+91) - 540-025-124553</span>
                    </li>
                  </ul>
                </div>
                <Link to="" className="btn btn-xs">
                  Visit Store <i className="fi-rs-arrow-small-right"></i>
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default VendorList;
