import React from 'react'
import HomeSlider from "../Components/Slider/HomeSlider"
import ShopCategories from "../Components/Products/ShopCategories"
import HomeProducts from "../Components/Products/HomeProducts";
import FlashSale from "../Components/Products/FlashSale"
import HotDeals from "../Components/Products/HotDeals"
import PartsInventory from "../Components/Products/PartsInventory"
import CarService from "../Components/Products/CarService"
import FeaturedProducts from "../Components/Products/FeaturedProducts"
import Brands from "../Components/Brands/Brands"
import VendorList from "../Components/Products/VendorList";

function HomePages() {
  return (
    <div>
        <HomeSlider />
        <ShopCategories />
        {/* <HomeProducts /> */}
        <FeaturedProducts />
        {/*<Brands />*/}
        {/*<HotDeals />*/}
        <FlashSale />
        <PartsInventory />
        <HotDeals />
        <VendorList />
        {/*<CarService />*/}
    </div>
  )
}

export default HomePages
