import React, { useState } from "react";
import Headline from "../../../Components/common/Headline";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import CustomPagination from "../../../Components/common/CustomPagination";
import CustomModal from "../../../Components/common/modal/CustomModal";
import RentCarBiddingSelectModal from "../modals/RentCarBiddingSelectModal";
import { Link } from "react-router-dom";

const RentCarBiddingDetails = () => {
  const [modal, setModal] = useState(false);

  return (
    <div className="container my-5">
      <Headline title="Bidding Details" />

      <div className="row">
        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Bidding For
          </label>

          <h4>Rent A Car</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Trip
          </label>

          <h4>One way trip</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Vehicle Type
          </label>

          <h4>Heavy</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Select Vehicle
          </label>

          <h4>Minibus</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Pickup Date?
          </label>

          <h4>10/05/2023</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Time
          </label>

          <h4>10:00 AM</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            For how many days?
          </label>

          <h4>5</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            For which location?
          </label>

          <h4>Dhaka-Chittagong</h4>
        </div>

        <div className="col-xl-4 mb-30">
          <label
            for="exampleFormControlInput1"
            class="form-label d-block fw-bolder mb-1"
          >
            Notes
          </label>

          <h4>Only Male Driver Allowed</h4>
        </div>
      </div>

      <Headline title="Participant List" />

      <table class="table">
        <thead class="table-light">
          <tr>
            <th scope="col">Participation Date</th>
            <th scope="col">Participant</th>
            <th scope="col">Contact No</th>
            <th scope="col">Bidding Price</th>
            <th scope="col" style={{ width: "10%", textAlign: "center" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">10/1/2022</th>
            <td><Link to={"/profile/rent-car"}>Martin Luther</Link></td>
            <td>01916549874</td>
            <td>7000</td>
            <td className="d-flex align-items-center justify-content-center gap-2">
              <EmojiEventsIcon sx={{ fontSize: 40 }} className="text-warning" />
              <h6>Winner</h6>
            </td>
          </tr>
          <tr>
            <th scope="row">15/1/2022</th>
            <td><Link to={"/profile/rent-car"}>Kalim Uddin</Link></td>
            <td>0171654987</td>
            <td>7500</td>
            <td className="d-flex justify-content-center">
              <button
                className="btn btn-secondary btn-sm ms-2"
                onClick={() => setModal(true)}
              >
                Details
              </button>
            </td>
          </tr>
          <tr>
            <th scope="row">4/1/2022</th>
            <td><Link to={"/profile/rent-car"}>Rasel Ahmed</Link></td>
            <td>0135648895</td>
            <td>7600</td>
            <td className="d-flex justify-content-center">
              <button
                className="btn btn-secondary btn-sm ms-2"
                onClick={() => setModal(true)}
              >
                Details
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <CustomPagination placement="center" />

      <CustomModal size="xl" footer onHide={() => setModal(false)} show={modal}>
        <RentCarBiddingSelectModal />
      </CustomModal>
    </div>
  );
};

export default RentCarBiddingDetails;
